// import Jwt from 'jsonwebtoken';
import {API_URL} from '../config/config';

const Tools = {
	getCookies : function(){
		let pairs = document.cookie.split(";");
	  	let cookies = {};
	  	for (let i=0; i<pairs.length; i++){
		    let pair = pairs[i].split("=");
		    cookies[(pair[0]+'').trim()] = unescape(pair.slice(1).join('='));
	  	}
		
		return cookies.User;
	},
	setRefreshToken: function(email){
		return refresh(email);
	}
}

const refresh  = function(email){
	const obj =  email //getEmails()
    return fetch(API_URL+'/refresh/token',
      	{
        method:'POST',
        headers:{
          'Content-Type':'application/json'
        },
        body:JSON.stringify({'email': obj})
      }
    )
}
/*const getEmails = function(){
	const token = localStorage.getItem('token');
	const decodedToken = Jwt.decode(token, {complete: true});
	return decodedToken.payload.email;
} */

export default Tools;