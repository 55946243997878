
import {SUBSCRIPT_FACTURE,CLEAR_SUBSCR_FACTURE,FETCH_FACTURES,CREATE_SUB,FETCH_LETTERS} from './actionTypes';
import {API_URL} from '../config/config';
import axios from 'axios';
import Jwt from 'jsonwebtoken';
import FileSaver from 'file-saver';

export function subscritiptionFacture() {
  return function(dispatch) {
    fetch(`${API_URL}/api/factures`, 
      {
        method:'GET',
        headers: { 
          'Content-Type':'application/json',
          'Authorization': 'Bearer '+localStorage.getItem('token') 
      }
    }).then(resp => {
      return resp.json();
    }).then(res => {      
        dispatch({
            type: SUBSCRIPT_FACTURE,
            res: res,
        });
    
    })
  }
}
export function fetchFacture(obj) {
  return function(dispatch) {
      const token = localStorage.getItem('token');
      const decodedToken = token ? Jwt.decode(token, {complete: true}) : '';
      //Pagnon userid=5EF077FA046F31FD5D9BCEBF5D2A152EBC4DC9D12685DAB6C1B99DE97305B2CF
      // Harmonie userid=5EF077FA046F31FD8495AFC4E043A0AE6CA338A9378164B546E57D4DC43EAF3B
      // Arnaud:userid=5EF077FA046F31FD70271B200DA611D5A1D32B6DC430C9AF1EF0286EBB19E75A
      // obj.cookie = getCookies() ? getCookies() : "userid=5EF077FA046F31FD70271B200DA611D5A1D32B6DC430C9AF1EF0286EBB19E75A";
      const uri = `${API_URL}/api/factures/month`
      obj.cookie = getCookies()
      obj.sessionId = decodedToken.payload.sessionId
      // console.log(obj)
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem('token')
      }
      axios.post(uri,
        obj,
        {headers: headers},
        { withCredentials: true })
      .then(function (response) {
        if (response.data.code === 200) {
          FileSaver.saveAs(response.data.data.content, response.data.data.name);
          dispatch({
            type: FETCH_FACTURES,
            resp: response.data.data.name,
          });
        }
    }).catch(function (error) {
      console.log(error);
    });
  }
}

export function createSubscription(data,historyPush){
	return function(dispatch) {
		fetch(API_URL+'/api/create/subscription',
        {
          method:'POST',
          headers:{
            'Content-Type':'application/json',
            'Authorization': 'Bearer '+localStorage.getItem('token')
          },
          body:JSON.stringify(data)
        }
      ).then((resp) => {
        return resp.json();
      }).then((res) => {
        dispatch({
            type: CREATE_SUB,
            res: res,
        });
        if (res.state === "sub_success") {
          historyPush('/tableau');
        } else {
          historyPush('/abonnement');
        }
      });
       
    }
}

export function fetchLettersSend(obj){
  return function(dispatch) {
      //Pagnon userid=5EF077FA046F31FD5D9BCEBF5D2A152EBC4DC9D12685DAB6C1B99DE97305B2CF
      // Harmonie userid=5EF077FA046F31FD8495AFC4E043A0AE6CA338A9378164B546E57D4DC43EAF3B
      // Arnaud userid=5EF077FA046F31FD70271B200DA611D5A1D32B6DC430C9AF1EF0286EBB19E75A
      // Antonio userid=5EF077FA046F31FD44F30BC1717606C10C9D27877321E2481E7C7F3F34440C27
      // obj.cookie = getCookies() ? getCookies() : "userid=5EF077FA046F31FD44F30BC1717606C10C9D27877321E2481E7C7F3F34440C27";
      const uri = `${API_URL}/api/get/letter/send`
      obj.cookie = getCookies()
      //console.log(obj)
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem('token')
      }
      axios.post(uri,
        obj,
        {headers: headers},
        { withCredentials: true })
        .then(function (response) {
          if (response.data.code === 200) {
            FileSaver.saveAs(response.data.data.content, response.data.data.name);
          }
          dispatch({
            type: FETCH_LETTERS,
            resp: response.data,
          });
    }).catch(function (error) {
      console.log(error);
    });
  }
}

export function fetchLettersSend2(obj){
  return function(dispatch) {
    const uri = `${API_URL}/api/get/letter/file`;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+localStorage.getItem('token')
    }
    axios.post(uri,
      obj,
      {headers: headers})
      .then(function (response) {
          if (response.data.code === 200) {
            FileSaver.saveAs(response.data.data.content, response.data.data.name);
          }
          dispatch({
            type: FETCH_LETTERS,
            resp: response.data,
            message: 'Récupération du fichier OK'
          });
      })
      .catch(function (error) {
        dispatch({
          type: FETCH_LETTERS,
          resp: error,
          messagedata: 'Erreur lors de la récupération du fichier de la commande ' + obj.orderId
        });
      });
  }
}

export function clearSubscrFacture(){
  return { type: CLEAR_SUBSCR_FACTURE };
}

export function fetchPreuvedepot(obj){
  return function(dispatch) {
    const uri = `${API_URL}/preuve/depot`
    const headers = {
        'Content-Type': 'application/json',
      }
      axios.post(uri,
        obj,
        {headers: headers})
        .then(function (response) {
          if (response.data.code === 200) {
            FileSaver.saveAs(response.data.data.contents, response.data.data.name);
          }
          dispatch({
            type: FETCH_LETTERS,
            resp: response.data,
          });
    }).catch(function (error) {
      console.log(error);
    });
  }
}

const getCookies = function(){
  let pairs = document.cookie.split(";");
  console.log(pairs);
  let cookies = {};
  for (let i=0; i<pairs.length; i++){
    let pair = pairs[i].split("=");
    console.log(pair);
    cookies[(pair[0]+'').trim()] = unescape(pair.slice(1).join('='));
  }
  return cookies.User;
}
