const uri = (window.location.origin).split('/');

let url = '';
let doc = '';
let sp = '';
let pub = '';

if (process.env.NODE_ENV === 'production' && uri[2].match('test')) {
	url = 'http://test-api.xipe.com';//'http://35.181.155.125';
	doc = 'https://test-docs.servicepostal.com/';
	sp =  'http://testsp1.servicepostal.com/envoyer-lettre-en-ligne/preparer-courrier';
	pub = 'http://testpublipostage.servicepostal.com/home';
} else if (process.env.NODE_ENV === 'production' && !uri[2].match('test')) {
	url = 	'https://core-api.servicepostal.com'; // 'beanstalk';
	doc =  	'https://docs.servicepostal.com/'
	sp = 	'https://www.servicepostal.com/envoyer-lettre-en-ligne/preparer-courrier';
	pub = 	'https://publipostage.servicepostal.com/home';
} else if(process.env.NODE_ENV === 'development') {
	url = 	'http://localhost:8000';
	doc = 	'https://test-docs.servicepostal.com/'
	sp = 	'http://testsp2.servicepostal.com/envoyer-lettre-en-ligne/preparer-courrier';
	pub = 	'http://testpublipostage.servicepostal.com/home';
} else {
	url = 	'http://test-api.xipe.com';
	doc =   'https://test-docs.servicepostal.com/'
	sp = 	'http://testsp1.servicepostal.com/envoyer-lettre-en-ligne/preparer-courrier';
	pub = 	'http://testpublipostage.servicepostal.com/home';
}

export const API_URL = url;
export const DOC_URL = doc;
export const SP_URL = sp;
export const PUB_URL = pub;



