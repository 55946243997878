import {FETCH_APIKEY,UPDATE_APIKEY} from './actionTypes';
import {API_URL} from '../config/config';

export function fetchApikey() {
  return function(dispatch) {
    fetch(`${API_URL}/api/apikey`, 
      {
        method:'GET',
        headers: { 
          'Content-Type':'application/json',
          'Authorization': 'Bearer '+localStorage.getItem('token') 
      }
    }).then(resp => {
      return resp.json();
    }).then(res => {       
        dispatch({
            type: FETCH_APIKEY,
            res: res,
        });
    })
  }
}

export function updateApikey(obj,historyPush) {
  return function(dispatch) {
    fetch(`${API_URL}/api/generate/apikey`, 
      {
        method:'PUT',
        headers: { 
          'Content-Type':'application/json',
          'Authorization': 'Bearer '+localStorage.getItem('token') 
      },
      body:JSON.stringify(obj)
    }).then(resp => {
      return resp.json();
    }).then(res => {
      // console.log(res)       
        dispatch({
            type: UPDATE_APIKEY,
            res: res,
        });

        if (res.success) {
          historyPush('/apikeys');
        } else {
          historyPush('/apikeys');
        }
    })
  }
}

