import {EDIT_USER,PASS_FORGOT,FETCH_PROFILE,AUTH,CLEAR_PROFILE,REGISTER} from './actionTypes';
import Jwt from 'jsonwebtoken';
import {API_URL} from '../config/config';
import Tools from '../tools/tools';
import {reset} from 'redux-form';

export function requestPassword(data) {
    return function(dispatch) {
    fetch(API_URL+'/request-password',
        {
          method:'POST',
          headers:{
            'Content-Type':'application/json'
          },
          body:JSON.stringify(data)
        }
      ).then((resp) => {
        return resp.json();
      }).then((res) => {
        dispatch({
            type: PASS_FORGOT,
            res: res,
        });
         dispatch(reset('email_form'))
      });
       
    }
}

export function register(data,historyPush){
  return function(dispatch) {
    fetch(API_URL+'/register',
        {
          method:'POST',
          headers:{
            'Content-Type':'application/json'
          },
          body:JSON.stringify(data)
        }
      ).then((resp) => {
        return resp.json();
      }).then((res) => { 
        if (res.code === 200) {
          historyPush('/login');
        } else {
          dispatch({
            type: REGISTER,
            res: res,
          });
          historyPush('/signup');
        }
      });
       
    }
}

export function updatePassword(obj,historyPush){
  const code = obj.token;
  const data = {
    newpassword : obj.newpassword
  }
  
  return function(dispatch) {
      fetch(API_URL+'/update/password/'+code,
          {
            method:'PUT',
            headers:{
              'Content-Type':'application/json'
            },
            body:JSON.stringify(data)
          }
        ).then((resp) => {
          return resp.json();
        }).then((res) => {        
          dispatch({
              type: EDIT_USER,
              res: res,
            });
          if (res.code === 200) {           
            historyPush('/login');
          } else {
            historyPush('/login');
          }
      });
        
  }
}

export function fetchProfile(historyPush) {

  return function(dispatch) {
    fetch(`${API_URL}/api/profile`, 
      {
        method:'GET',
        headers: { 
          'Content-Type':'application/json',
          'Authorization': 'Bearer '+localStorage.getItem('token') 
      }
    }).then(resp => {
      return resp.json();
    }).then(res => {       
        dispatch({
            type: FETCH_PROFILE,
            res: res,
        });
    })
  }
}

export function updateProfile(data) {
    return function(dispatch) {
        let obj;
        if (data.email) {
            const token = localStorage.getItem('token');
            const decodedToken = Jwt.decode(token, {complete: true});
             obj = {
              'emailOld':decodedToken.payload.email,
              'email':data.email
            }
        } else {
          obj = data
        }

        fetch(API_URL+'/api/update/profile',
            {
              method:'PUT',
              headers:{
                'Content-Type':'application/json',
                'Authorization': 'Bearer '+localStorage.getItem('token') 
              },
              body:JSON.stringify(obj)
            }
          ).then((resp) => {
            return resp.json();
        }).then((res) => {
          if (data.email) {
            refresh(data.email)
          } 
          dispatch({
              type: AUTH,
              res: res,
          });
            
            // historyPush('/profil');
        });
          
    }
}

export function clearProfile(){
     return { type: CLEAR_PROFILE };
}

export function refreshToken(email){
    // refresh(email)
    Tools.refresh(email)
    return;
}

const refresh = function(email){
    fetch(API_URL+'/refresh/token',
      {
        method:'POST',
        headers:{
          'Content-Type':'application/json'
        },
        body:JSON.stringify({'email': email})
      }
    ).then((resp) => {
      return resp.json();
  }).then((res) => {
    localStorage.setItem('token', res.data);
  })
}


//const token = localStorage.getItem('token');
/*if (token) {
  const decodedToken = Jwt.decode(token, {complete: true});

  if(decodedToken.payload.exp < Date.now() / 1000) {
      localStorage.removeItem('token');
      window.location.reload(true)
  }
  
  const res = {
    'success': true,
    'code':200,
    'message':'send profile',
    'data':{
      'name':decodedToken.payload.name,
      "password":"",
      'firstname':decodedToken.payload.firstname,
      'phone':decodedToken.payload.phone,
      'email':decodedToken.payload.email,
      'label':decodedToken.payload.label,
      'idSp':decodedToken.payload.idSp,
      'idPub':decodedToken.payload.idPub
    }
  }

  dispatch({
        type: FETCH_PROFILE,
        res: res,
    });
}*/
