import React  from 'react';
import { connect } from 'react-redux';

import HeaderNoh1 from '../header/headerNoh1';
import Footer from '../footer/footer';
import Dashboard from './dashboard';
import '../../assets/css/header.css';
import DatePicker,{registerLocale} from "react-datepicker";
import fr from 'date-fns/locale/fr';
import "react-datepicker/dist/react-datepicker.css";
import ListPub from '../utils/publipostage/listPub';
import Legende from '../utils/publipostage/legende';
import Loader from '../utils/loader';
import {fetchEnvoiesNombres,fetchPubByDate} from '../../actions/envoiesActions';
registerLocale('fr', fr);


// import fr from 'date-fns/locale/fr';
//import Spinner from 'react-bootstrap/Spinner'

class Nombre extends React.Component {
    constructor() {
      super();
      this.state = {
      	startDate: '',
      	searchVal:'',
      	isLoader:false,
      	isSearch:false,
      	isDate:false,
      	isArchived: false,		
		isChecked1: true,
		isChecked2: true,
		isChecked3: true
      }
      this.handleChange = this.handleChange.bind(this);
      this.handleSearch = this.handleSearch.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleDateSend = this.handleDateSend.bind(this);
	  this.handleCheck1 = this.handleCheck1.bind(this);
	  this.handleCheck2 = this.handleCheck2.bind(this);
	  this.handleCheck3 = this.handleCheck3.bind(this);
      this.handleChangeArchive = this.handleChangeArchive.bind(this);
      this.resetCrt = this.resetCrt.bind(this);	  
    };

    componentDidUpdate(prevProps) {
		if (this.props.initial !== prevProps.initial) {
			this.setState({isLoader: false})
		}
	}

	handleChange(date) {
	    this.setState({
	      startDate: date
	    });
	}

	handleSearch(event){
		this.setState({searchVal: event.target.value});
	}

	handleDateSend(e){
		const date = this.state.startDate;
		const day = date.getDate();
	  	const month = date.getMonth();
	  	const year = date.getFullYear();
	  	const time = `${year}-${month + 1}-${day}`;
		const obj = {
			"isArchived":this.state.isArchived,
			'date':time,
				};
		this.props.fetchPubByDate(obj);
		this.setState({isDate:true})
		this.setState({isLoader:true})
	}

	handleSubmit(event){
		const checkName = document.getElementById("nom");
		const checkAdress = document.getElementById("adresse");
		const checkRef = document.getElementById("reference");

		if (this.state.searchVal) {
			const search = {
				'nom':checkName.checked,
				'adresse':checkAdress.checked,
				'ref':checkRef.checked,
				'val':this.state.searchVal,
				"isArchived":this.state.isArchived
			}
			
			this.props.fetchEnvoiesNombres(search);
			this.setState({isLoader:true})
			this.setState({isSearch:true})
		}
			
	}

	handleChangeArchive(archived){
		this.setState({isArchived:archived})
	}

	resetCrt(){
		if (this.state.startDate || this.state.searchVal) {
			window.location.reload(true)
		}
	}
	
	handleCheck1(){
		if (this.state.isChecked1){
			this.setState({isChecked1:false})
		}
		else{
			this.setState({isChecked1:true})
		}
	}
	handleCheck2(){
		if (this.state.isChecked2){
			this.setState({isChecked2:false})
		}
		else{
			this.setState({isChecked2:true})
		}
	}
	handleCheck3(){
		if (this.state.isChecked3){
			this.setState({isChecked3:false})
		}
		else{
			this.setState({isChecked3:true})
		}
	}

	render() {	
		return(
				<div className="connected connectedPage">
					<HeaderNoh1/>
					<div className="content tabs">
						<div className="wrap">
							<Dashboard />
							<div className="bloc">
								{/*eslint-disable */}
								<div className="top">
								<h2 className="fl-l">Historique de mes envois en Nombre</h2>
								</div>
								<div className="blocArrondi plr-30">
									<div className="elt">
											<div className="fl-l radio checkbox">
												<div className="custom-control">
													<input name="nom" type="checkbox" id="nom" defaultChecked={true} className="custom-control-input" />
													<label title="" type="checkbox" htmlFor="nom" className="custom-control-label" onClick={this.handleCheck1}><i className="material-icons-outlined">{this.state.isChecked1 ? "check_box" : "check_box_outline_blank"}</i> dans son nom</label>
												</div>
												<div className="custom-control">
												<input name="adresse" type="checkbox" id="adresse" defaultChecked={true} className="custom-control-input" />
												<label title="" type="checkbox" htmlFor="adresse" className="custom-control-label" onClick={this.handleCheck2}><i className="material-icons-outlined">{this.state.isChecked2 ? "check_box" : "check_box_outline_blank"}</i> dans son adresse</label>
												</div>
												<div className="custom-control">
												<input name="reference" type="checkbox" id="reference" defaultChecked={true} className="custom-control-input" />
												<label title="" type="checkbox" htmlFor="reference" className="custom-control-label" onClick={this.handleCheck3}><i className="material-icons-outlined">{this.state.isChecked3 ? "check_box" : "check_box_outline_blank"}</i> dans sa référence</label>
												</div>
											</div>
											<Loader isLoader={this.state.isLoader} />
											<div className="fl-r">
												<div className="fieldGroup">
												<div className="form-group">
													<input className="form-control" name="search" onChange={this.handleSearch} type="text" placeholder="Rechercher ..."/>
												</div>
												<button className="btBlue btn radius search" onClick={this.handleSubmit} type="button">&nbsp;</button>
												</div>
												<div className="fieldGroup">
												<div className="form-group">
													<DatePicker
													selected={this.state.startDate}
													onChange={this.handleChange}
													placeholder="Rechercher par date"
													className="form-control"
													dateFormat="dd/MM/yyyy"
													locale="fr"												   
													/>
												</div>
												<button className="btBlue btn radius search" onClick={this.handleDateSend} type="button">&nbsp;</button>
												</div>
												<button className="bt btBlue w-100" onClick={this.resetCrt} type="button">Effacer les critères de séléction</button>
											</div>
									<ListPub isDate={this.state.isDate} isSearch={this.state.isSearch} getArchived={this.handleChangeArchive}/>
									<Legende />
								</div>
								</div>
						</div>
					</div>
				</div>
				<Footer/>
				</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	let a_status = state.envoies.list_publipostage;
	let _st = '';

	if (a_status) {
		if (a_status.code === 200) {
			_st = a_status.data.map((el) => {
				return el 
			}).filter(e => e.id != null)
		} else {
			_st = ''
		}
	}

	return {
		initial: _st ? _st : '',
	}
}

export default connect(mapStateToProps, {fetchEnvoiesNombres,fetchPubByDate})(Nombre);
