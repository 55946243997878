import React  from 'react';
import '../../assets/css/aside.css';
import "react-datepicker/dist/react-datepicker.css";
import 'rc-collapse/assets/index.css';

const Preference = () => {

  return( 
          <div className="paddAside preference">
            <form>
                <p>
                  <span>Lorsque j'envoie un courrier...</span>
                  Ajouter à mon carnet d'adresses les nouveaux destinataires :
                </p>
                <div className="custom-control custom-radio custom-control-inline">
                  <input name="dmdEnvoi" type="radio" id="confirm" className="custom-control-input" />
                  <label title="" type="checkbox" htmlFor="confirm" className="custom-control-label">Oui, toujours ajouter</label>
                </div>
                <div className="custom-control custom-radio custom-control-inline">
                  <input name="dmdEnvoi" type="radio" id="refuse" className="custom-control-input" />
                  <label title="" type="checkbox" htmlFor="refuse" className="custom-control-label">Non, ne jamais les ajouter</label>
                </div>  
                <div className="custom-control custom-radio custom-control-inline">
                  <input name="dmdEnvoi" type="radio" id="ask" className="custom-control-input" />
                  <label title="" type="checkbox" htmlFor="ask" className="custom-control-label">Me demander quoi faire, à chaque fois</label>
                </div>
              </form>
              <form>
                <p>
                  <span>Mon courrier habituel est : </span>
                </p>
                <div className="custom-control custom-radio custom-control-inline">
                  <input name="preferency" type="radio" id="noireBlanc" className="custom-control-input" />
                  <label title="" type="checkbox" htmlFor="noireBlanc" className="custom-control-label">En noir et blanc</label>
                </div>
                <div className="custom-control custom-radio custom-control-inline">
                  <input name="preferency" type="radio" id="couleur" className="custom-control-input" />
                  <label title="" type="checkbox" htmlFor="couleur" className="custom-control-label">En couleur</label>
                </div>
                <div className="custom-control custom-radio custom-control-inline">
                  <input name="preferency" type="radio" id="noPreferency" className="custom-control-input" />
                  <label title="" type="checkbox" htmlFor="noPreferency" className="custom-control-label">Pas de préférence</label>
                </div>
              </form>
              <hr/>
              <div className="form-group">
                <p><label htmlFor="destinataire">Mon enveloppe préférée</label></p>
                <select name="enveloppe" id="enveloppe" className="form-control">
                  <option value="Pour feuilles A4 piliées en deux (enveloppe...)">Pour feuilles A4 pillées en deux (enveloppe...)</option>
                </select>
              </div>
              <form>
                <div className="custom-control">
                  <div className="fl-r radio checkbox">
                    <input name="addCoord" type="checkbox" id="addCoord" className="custom-control-input" />
                    <label title="" type="checkbox" htmlFor="addCoord" className="custom-control-label">Pas de préférence</label>
                  </div>
                </div>
              </form>
          </div>
   )
}
export default Preference;