import React, {Component} from 'react';
import {connect} from 'react-redux';
import {reduxForm,Field} from 'redux-form';
import {updatePassword} from '../../actions/userAction';
import Loader from '../utils/loader';
import Constante from '../../constante/constante';
import HeaderNoh1 from '../header/headerNoh1';
import Footer from '../footer/footer';


class PasswordUpdate extends Component {
	constructor() {
      	super();
      	this.state = {
      		isLoader: false,
    	}
    }

    componentDidUpdate(prevProps) {
		if (this.props.res !== prevProps.res) {
			this.setState({isLoader: false})
		}
	}

	 handleFormSubmit({cpassword,password}){
	 	const params = new URLSearchParams(this.props.location.search);
		const code = params.get('code');
	 	//const { code } = this.props.match.params;
    	const obj = {
		    'cpassword':cpassword,
		    'newpassword':password,
		    'token' : code
		  };

		  if (password && cpassword) {
		  		this.setState({isLoader: true})
		  }

    	this.props.updatePassword(obj, (path) => {
    		this.props.params.history.push(path)
    	})
    };

	renderInput = ({input,type,placeholder,label, meta: { touched, error,pristine,invalid,submitted }}) => (
	    <div className="form-group">
	    <label htmlFor={label}>{label}</label>
	      <input
	        className={ touched && invalid ? "form-control is-invalid" : 'form-control'}
	        {...input}
	        type={type}
	        placeholder={placeholder}
	      />
	      {touched && error && <span className="error">{error}</span>}
	    </div>
    );

	render(){
		const { handleSubmit} = this.props
		return(
			<div>
				<HeaderNoh1 />
					<div className="content">
					    <div className="wrap connexion formulaire">
							    <div className="bloc noborder">
										<h1 className="text-center">Saisissez votre nouveau mot de passe</h1>
											{/*eslint-disable */}
										<form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
								          	<Field name="password" component={this.renderInput} type="password" label="Nouveau mot de passe" placeholder="Nouveau mot de passe" required={true} />
								          	<Field name="cpassword" component={this.renderInput} type="password" label="Confirme Mot de passe" placeholder="Confirmation mot de passe" required={true} />
								          	<div className="text-center bottom">
								          		<button type="submit" className="btn bt btBlue" >Valider</button><br/>
								          	</div>
								          	<Loader isLoader={this.state.isLoader} />
								        </form>
								</div>
						</div>
					</div>
				 <Footer/>
			</div>
		);
	}

}

function validate(values) {
	const errors = {};
	if (!values.password) {
    	errors.password = 'Merci de saisir votre nouveau de passe.';
  	}

  	if (!values.cpassword) {
    	errors.cpassword = 'Merci de confirmer votre nouveau de passe.';
  	}

  	if (values.cpassword !== values.password) {
  		errors.cpassword = 'Merci de confirmer votre nouveau de passe.';
  	}

  	 return errors;
}

PasswordUpdate = reduxForm({
	form: 'update_form',
	validate: validate
})(PasswordUpdate)

function mapStateToProps(state, ownProps) {
	let a_status = state.login.pwdChange;
	let _st = ''
	let message = ''

	if (a_status) {
		_st = a_status.code === 400 ? Constante.messageErr : ''
		message = a_status.message
	}

	return {
		params: ownProps,
		status: _st, 
		res:message
	}
}

export default connect(mapStateToProps,{updatePassword})(PasswordUpdate);
