import React  from 'react';
import {Link} from 'react-router-dom';

const MenuEnvois = () => {
	return(	
		<div>
			<Link to="/unitaire" title="Unitaire">Unitaire</Link>
            <Link to="/nombre" title="En nombre">En nombre</Link>
        </div>
	 )
}
export default MenuEnvois;