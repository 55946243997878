import React from 'react';
import { connect } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { reduxForm, Field } from 'redux-form';
import Popup from 'reactjs-popup';

import HeaderNoh1 from '../header/headerNoh1';
import Footer from '../footer/footer';
import Dashboard from './dashboard';
import Loader from '../utils/loader';
import '../../assets/css/header.css';
import { fetchApikey, updateApikey } from '../../actions/apikeyAction';

class ApiKeys extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			copiedTest: false,
			copied: false,
			open: false,
			send: false,
			isLoader: false,
			env: {},
			height: ""
		};
		this.closeModal = this.closeModal.bind(this);
		this.onSend = this.onSend.bind(this);
		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
	}

	componentDidMount() {
		// this.setState({ isLoader: true })
		this.props.fetchApikey();
		this.updateWindowDimensions();
		window.addEventListener("resize", this.updateWindowDimensions.bind(this));
	}

	componentDidUpdate(prevProps) {
		if (this.props.keyapiProd !== prevProps.keyapiProd) {
			this.setState({ isLoader: false })
		}
		if (this.props.keyapiTest !== prevProps.keyapiTest) {
			this.setState({ isLoader: false })
		}
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.updateWindowDimensions.bind(this));
	}

	updateWindowDimensions() {
		this.setState({ width: window.innerWidth, height: window.innerHeight - 355 + 'px' });
	}

	handleFormSubmit() {
		const obj = {
			'env': 'test'
		}
		this.setState({ env: { ...this.state.env, ...obj } })
		this.setState({ open: true })
	}

	handleFormSubmitProd() {
		const obj = {
			'env': 'prod'
		}
		this.setState({ env: { ...this.state.env, ...obj } })
		this.setState({ open: true })
	}

	closeModal() {
		this.setState({ open: false })
	}

	onSend() {
		const obj = this.state.env;
		this.setState({ isLoader: true })
		this.props.updateApikey(obj, (path) => {
			this.props.params.history.push(path)
		});
		setTimeout(() => {
			this.setState({ open: false })
		}, 1000)
		this.setState({ copiedTest: false })
		this.setState({ copied: false })

	}

	/*copyKey() {
		const cl = document.getElementsByClassName('apikey');
			document.execCommand("copy");

	}*/

	renderInput = (field) => (
		<div className="form-group">
			<input
				className="form-control apikey"
				{...field.input}
				type={field.type}
				readOnly={field.readOnly}
			/>
		</div>
	);

	render() {
		const { handleSubmit } = this.props
		return (
			<div className="connected connectedPage">
				<HeaderNoh1 />
				<div className="content tabs api" style={{ minHeight: this.state.height }}>
					<div className="wrap">
						<Dashboard />
						<div className="bloc tabTitle apikeys">
							{/*eslint-disable */}
							<Popup open={this.state.open} closeOnDocumentClick onClose={this.closeModal} position="top center">
								<div className="text-align">
									<a className="close" onClick={this.closeModal}>
										&times;
									</a>
									<p>Confirmer la génération de vos nouvelles clés API ?</p>
									<hr />
									<p className="confirm">Voulez-vous vraiment régénérer une nouvelle clé API ?</p>
									<hr />
									<div className="btn-container">
										<button className="btn bt btBlue non" onClick={this.closeModal}>Non</button>
										<button className="btn bt btBlue oui" onClick={this.onSend}>Oui</button>
									</div>
								</div>
							</Popup>

							<div className="form-group flex-container">
								<div className="flex-item">
									<h2>API key de test : </h2>
									<strong>https://sandbox-api.servicepostal.com</strong>
									<div>
										<form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
											<Field name="apikeyTest" label="Nom" component={this.renderInput} type="text" readOnly={true} />
											<div>
												<button className="bt btBlue">
													Régénérer une API key de test
												</button>
											</div>
										</form>
										<div>
											<CopyToClipboard text={this.props.keyapiTest} onCopy={() => this.setState({ copiedTest: true })}>
												<button className="bt btBlue">
													Copier l’API key de test
												</button>
											</CopyToClipboard>
											{this.state.copiedTest ? <span className="copied">Clef Copiée</span> : null}
											<Loader isLoader={this.state.isLoader} />
										</div>
										<div className="floatNone"></div>
									</div>
								</div>
								<div className="flex-item">
									<h2>API key de prod :</h2>
									<strong>https://prod-api.servicepostal.com</strong>
									<div>
										<form onSubmit={handleSubmit(this.handleFormSubmitProd.bind(this))}>
											<Field name="apikey" label="Nom" component={this.renderInput} type="text" readOnly={true} placeholder={this.props.keyapiProd} />
											<div>
												<button className="bt btBlue">
													Régénérer une API key de prod
												</button>
											</div>
										</form>
										<div>
											<CopyToClipboard text={this.props.keyapiProd} onCopy={() => this.setState({ copied: true })}>
												<button className="bt btBlue">
													Copier l’API key de prod
												</button>
											</CopyToClipboard>
											{this.state.copied ? <span className="copied">Clef Copiée</span> : null}
											<Loader isLoader={this.state.isLoader} />
										</div>
										<div className="floatNone"></div>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
				<Footer />
			</div>
		)
	}
}


ApiKeys = reduxForm({
	form: 'apikey_form'
})(ApiKeys)


function mapStateToProps(state, ownProps) {
	let _apikey = state.apikey.apikeyed;
	const editKey = state.apikey.apik_updated;
	let keyTest = '';
	let keyProd = '';

	if (_apikey) {
		if (_apikey.code === 200) {
			keyTest = _apikey.data.api_key;
			keyProd = _apikey.data.apikey_prod;
		}
	}

	if (editKey) {
		if (editKey.success) {
			keyTest = editKey.data.api_key;
			keyProd = editKey.data.apikey_prod;
		}

	}

	return {
		params: ownProps,
		keyapiProd: keyProd,
		keyapiTest: keyTest,
		initialValues: {
			apikeyTest: keyTest,
			apikey: keyProd
		},
		enableReinitialize: true
	}
}

export default connect(mapStateToProps, { fetchApikey, updateApikey })(ApiKeys);
