import React from 'react';
// import {Route, Redirect} from 'react-router-dom';
import { connect } from 'react-redux';
import Jwt from 'jsonwebtoken';
import Tools from '../../tools/tools';
// import {refreshToken} from '../../actions/userAction';



/*export const RequiredLogin = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (    	
        !localStorage.getItem('token') 
            ? <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            : <Component {...props} /> 
    )} />
)*/

export default function(ComposedComponent) {

  class Authentication extends React.Component {

    componentDidMount(){
        const token = localStorage.getItem('token');

        if (token) {
            const decodedToken = Jwt.decode(token, {complete: true});
            const date = new Date(decodedToken.payload.exp * 1000);
            const now = new Date();

            if (date.getFullYear() === now.getFullYear() && ((date.getMonth() + 1) !== (now.getMonth() + 1))) {
              localStorage.removeItem('token');
              this.props.history.replace('/login');
              window.location.reload(true); 
            }
            
            if(decodedToken.payload.exp < Date.now() / 1000) {
                let a = Tools.setRefreshToken(decodedToken.payload.email);
                a.then((resp) => {
                    return resp.json();
                }).then((res) => {
                  // console.log('refresh_t', res)
                  localStorage.setItem('token', res.data);
                  window.location.reload(true)
                })
            }
        }
    }

    componentWillMount() {
         //console.log(this.props)
      if (!this.props.authenticated) {
        this.props.history.replace('/login');
      }
    }

    componentWillUpdate(nextProps) {

        if (!nextProps.authenticated) {
            this.props.history.replace('/login');
        }
    }

    render() {
      return <ComposedComponent {...this.props} />
    }
  }

  function mapStateToProps(state) {

    return { authenticated: state.login.authenticated };
  }

  return connect(mapStateToProps)(Authentication);
}

