import React  from 'react';
import Pagination from 'rc-pagination';
import { connect } from 'react-redux';
import localeInfo from 'rc-pagination/lib/locale/fr_FR';
import Spinner from 'react-bootstrap/Spinner';

import {fetchEnvoiesNombres} from '../../../actions/envoiesActions';
import Loader from '../loader';
import Tabs from './tabs';


const textItemRender = (current, type, element) => {
  if (type === 'prev') {
    return 'Prev';
  }
  if (type === 'next') {
    return 'Next';
  }
  return element;
};

class ListPub extends React.Component {
	constructor() {
      super();
      this.state = {
      	itemPerPage:10,
      	isArchived:true,
      	isLoader:false,
      	obj:[],
      	currentPage: 1,
		isChecked: true
      }
       this.handleChange = this.handleChange.bind(this);
       this.handleArchived = this.handleArchived.bind(this);
	   this.handleCheck = this.handleCheck.bind(this);
    }

	componentDidMount() {
		const obj = {
			"isArchived":this.state.isArchived,
			"itemPerPage":this.state.itemPerPage,
			"currentPage": 0
		}
	   this.props.fetchEnvoiesNombres(obj);
	}

	componentDidUpdate(prevProps) {
		if (this.props.initial !== prevProps.initial) {
			this.setState({isLoader: false})
		}
	}

	handleArchived(){
		const check = document.getElementById("archive");
    	const obj = {
    		"isArchived":check.checked,
    		"itemPerPage":10,
			"currentPage":0
    	}
    	this.props.getArchived(check.checked)
    	this.setState({isLoader:true})
    	this.setState({isArchived:check.checked})
    	this.props.fetchEnvoiesNombres(obj);
	}

	/*componentWillUnmount() {
    	this.props.clearData(); 
  	}*/

	handleChange(current, pageSize) {
		const {itemPerPage } = this.state;
		const indexLast = current * itemPerPage;
		const indexFirst = indexLast - itemPerPage;
		const obj = {
			"isArchived":this.state.isArchived,
			"itemPerPage":itemPerPage,
			"currentPage": indexFirst
		}

		if (this.props.isSearch || this.props.isDate) {
			const newData = this.props.initial.slice(indexFirst, indexLast);
			this.setState({obj:newData})
			this.setState({currentPage:current})
		} else {
			this.props.fetchEnvoiesNombres(obj);
			this.setState({isLoader:true})
		}
	 
	   
		/*if (this.props.initial && current) {
			const indexLast = current * itemPerPage;
			const indexFirst = indexLast - itemPerPage;
			const newData = this.props.initial.slice(indexFirst, indexLast);
			this.setState({obj:newData})
			this.setState({currentPage:current})
		}*/

	}
	handleCheck(){
		if (this.state.isChecked){
			this.setState({isChecked:false})
		}
		else{
			this.setState({isChecked:true})
		}
	}

	render() {
		const currentTodos = this.props.initial? this.props.initial.slice(0, 10):'';
	    let data;
	    /*eslint-disable */
	    if ((this.props.initial && this.state.obj.length === 0) 
	    	|| this.state.currentPage === 1
	    	&& (this.props.isSearch || this.props.isDate)
	    	) {
	    	data = currentTodos
	    } else if((this.props.isSearch || this.props.isDate) && this.state.currentPage > 1) {
	    	data = this.state.obj
	    } else {
	    	data = this.props.initial
	    }

		return(
			<div>
				<Tabs initial={data} />
		   		{
		            this.props.initial && this.props.initial.length === 0 ?
		             (<div className="fullcase tbody">
		                    <p className="">Aucune donnée !</p>
		             </div>):(<div></div>)
	            }
				{!this.props.initial ?
				   	(
  			   		 	<div className="text-center">
  					     	<Spinner animation="border" variant="info" />
                		</div>
				   	) : (<div></div>)
				}
				<Loader isLoader={this.state.isLoader} />			   
			   <div className="fl-r radio checkbox">
	                <div className="custom-control">
	                  <input name="archive" type="checkbox" id="archive" defaultChecked={this.state.isArchived} className="custom-control-input" onChange={this.handleArchived} />
	                  <label title="" type="checkbox" htmlFor="archive" className="custom-control-label" onClick={this.handleCheck}><i className="material-icons-outlined">{this.state.isChecked ? "check_box" : "check_box_outline_blank"}</i> Afficher les mailings archivés</label>
	                </div>
	           </div>

	          	<div className="pagination mailing">
	          		{this.props.initial && this.props.initial.length > 0 ?
				   		(
			   			 	<div className="BlocPagination">
					        	<Pagination 
					        		total={this.props.total} 
					        		locale={localeInfo} 
					        		onChange={this.handleChange} 
					        		itemRender={textItemRender}
					        		pageSize={this.state.itemPerPage} 
					        	/>
					        </div>
				   		):(
				   			<div></div>
				   		)
					}
	          	</div>
	        </div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	let a_status = state.envoies.list_publipostage;
	let _st = '';
	let total = '';
//console.log(a_status)
	if (a_status) {
		if (a_status.code === 200) {
			_st = a_status.data.map((el) => {
				return el 
			}).filter(e => e.id != null)
		    total = a_status.data ? a_status.data[a_status.data.length -1] : ''
		} else {
			_st = ''
		}
	}

	return {
		initial: _st ? _st : '',
		total:total ? total.count :''
	}
}

export default connect(mapStateToProps, {fetchEnvoiesNombres})(ListPub);
