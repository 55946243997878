export const SUBSCRIPT_FACTURE = 'subscription_facture';
export const CLEAR_SUBSCR_FACTURE = 'clear_subscr_facture';
export const FETCH_PROFILE = 'fecth_profile';
export const EDIT_PROFILE = 'edit_profile';
export const CREATE_SUB = 'create_sub';
export const EDIT_USER = 'edit_user';
export const REMOVE_ALERT = 'remove_alert';
export const DELETE_USER = 'delete_user';
export const GET_USER = 'get_user';
export const AUTH  = 'auth';
export const UN_AUTH  = 'un_auth';
export const PASS_FORGOT  = 'pass_forgot';
export const CLEAR_PROFILE = 'clear_profile';
export const FETCH_APIKEY = 'fetch_apikey';
export const UPDATE_APIKEY = 'update_apikey';
export const ASK_CONTACT = 'ask_contact';
export const ENVOIE_UNITAIRE = 'envoie_unitaire';
export const ENVOIE_NOMBRES = 'envoie_nombres';
export const ENVOIE_UNITAIRE_DATE = 'envoie_unitaire_date';
export const CLEAR_DATA = 'clear_data';
export const ISARCHIVED = 'is_archived';
export const AUTO_CONNECT_SP = 'AUTO_connect_sp'; 
export const AUTO_CONNECT_PUB = 'AUTO_connect_PUB';
export const GRAPH_DATA = 'graphe_data'; 
export const FETCH_FACTURES = 'fetch_fatcures';
export const UPDATE_UNITAIRES = 'update_unitaires';
export const GET_FILES = 'get_files';
export const FETCH_LETTERS = 'fetch_letters';
export const REGISTER = 'register';

