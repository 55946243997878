import React  from 'react';
import Popup from 'reactjs-popup';
import Collapsible from 'react-collapsible';

import { connect } from 'react-redux';
import Affrachissement from './affrachissement';
import Type from './type';
import {Pli,RenderEtat} from './pli';
import Color from './color';
import Verso from './verso';
import Affiche from './affiche';
import {fetchDocMain,fetchCsv,fecthAnnex} from '../../../actions/envoiesActions';
import Loader from '../loader';

class Tabs extends React.Component {

	constructor() {
		super();
		this.state = {
      		isLoader:false,
      		open: false	
      	}
      	this.getDocMain = this.getDocMain.bind(this);
      	this.getCsv = this.getCsv.bind(this);
      	this.getAnnex = this.getAnnex.bind(this);
      	this.closeModal = this.closeModal.bind(this);		
    }

    componentDidUpdate(prevProps) {
		if (this.props.update !== prevProps.update) {
			this.setState({isLoader: false})
		}

		if (this.props.error !== prevProps.error && this.props.error) {
			this.setState({open:true})
			this.setState({isLoader: false})
		}

		if (this.props.message !== prevProps.message) {
			this.setState({isLoader: false})
		}
	}

	getDocMain(element) {
		const id = {'id':element.id,'uri':'GetMainDoc'}
		this.setState({isLoader: true})
		this.props.fetchDocMain(id)
	}

	getCsv(element) {
		const id = {'id':element.id,'uri':'GetCSV'}
		this.setState({isLoader: true})
		this.props.fetchCsv(id);
	}

	getAnnex(element){
		const id = {'id':element.id,'uri':'GetAnnex'}
		this.setState({isLoader: true})
		this.props.fecthAnnex(id);
	}

	closeModal () {
        this.setState({ open: false })
	}

	renderTab(element){
		let aff = '';
		if (element.type_courrier === 1) {
			aff = 'LRAR';
		} else if(element.type_courrier === 2) {
			aff = 'LR';
		} else if(element.type_courrier === 3) {
			aff = 'Prioritaire';
		} else if(element.type_courrier === 4) {
			aff = 'Verte';
		} else if(element.type_courrier === 5) {
			aff = 'Ecopli';
		} else if(element.type_courrier === 6) {
			aff = 'Suivi';
		} else if(element.type_courrier === 7) {
			aff = 'Performance';
		} else if(element.type_courrier === 8) {
			aff = 'Perf. Suivie';
		} else if(element.type_courrier === 9) {
			aff = 'Verte Suivie';
		}

		return(
			<div className="line">
				<div className="hideMob">
					<div className="case tbody" key={element.id}>
					<div className="casex case1">{element.date}<br/><i>{element.time}</i></div>
					<div className="casex case2">{aff}<br/><Affrachissement element={element} /></div>
					<div className="casex case3">
						<Popup
						trigger={
							<span>
								<RenderEtat element={element} />
							</span>
							}
						position="top center"
						on ="hover"
						closeOnDocumentClick
						>
							{element.etat.title}
						</Popup>
					</div>
					<div className="casex case4">
							<Popup
							trigger={
										<span>
											<Type element={element} /> 
											<Pli element={element} />
											<Color element={element}/>
											<Verso element={element}/>						               
										</span>
									}
							position="top center"
							on ="hover"
							closeOnDocumentClick
							>
								<Affiche element={element}/>
						</Popup>
					</div>
					<div className="casex case6">
						{/*eslint-disable */}
						<a href="javascript:void(0)" onClick={() => this.getDocMain(element)}><i className="material-icons-outlined">description</i></a>
					</div>
					<div className="casex case7">
						{/*eslint-disable */}
						<a href="javascript:void(0)" onClick={() => this.getCsv(element)}><i className="material-icons-outlined">article</i></a>			
					</div>
					<div className="casex case8">
					{
						element.fichier_annexs ? 
							(<a href="javascript:void(0)" onClick={() => this.getAnnex(element)}><i className="material-icons-outlined">file_present</i></a>) 
						: 	(<Popup
							trigger={<i className="material-icons-outlined">file_present</i>}
							position="top center"
							on ="hover"
							closeOnDocumentClick
							>
								<span>L'ENVOI N'A PAS D'ANNEXE</span>
							</Popup>)
					}
					</div>
					<div className="casex case9">{element.nombres_courriers}</div>				
					</div>
				</div>
				<div className="case tbody" key="element.id">
					<div className="mobVersion envois-nombre">
						<Collapsible trigger={<div><div className="casex case1">{element.date}<br/><i>{element.time}</i></div><div className="casex case3">{aff}<br className="hideMob"/><Affrachissement element={element} /></div><div className="casex case2"><span className="arrows"></span></div></div>}>
							<div className="full">
								<div className="mb-block options">
									<p>
										<strong className="bold">Options d'impression</strong>
										<Type element={element} /> 
										<Pli element={element} />
										<Color element={element}/>
										<Verso element={element}/>
										<span>{element.nombres_courriers} page</span>
									</p>
								</div>					
								<div className="mb-block doc">
									{/*eslint-disable */}
									<a href="javascript:void(0)" onClick={() => this.getDocMain(element)}><i className="material-icons-outlined">description</i><span>Telecharger le document</span></a>
								</div>
								<div className="mb-block doc">
									{/*eslint-disable */}
									<a href="javascript:void(0)" onClick={() => this.getCsv(element)}><i className="material-icons-outlined">article</i><span>Telecharger fichier CSV</span></a>			
								</div>
								<div className="mb-block doc">
									<a href="javascript:void(0)" onClick={() => this.getAnnex(element)}><i className="material-icons-outlined">file_present</i><span>Telecharger l'annexe</span></a>
								</div>								
							</div>
						</Collapsible>
					</div>
				</div>					
			</div>
		);
	}
	render() {
		return (
			<div>
				<div className="tableau mailing">
					<div className="case thead hideMob">
						<p className="case1">Date</p>
						<p className="case2">Type de courrier</p>
						<p className="case3">Etat</p>
						<p className="case4">Options d'impression</p>
						<p className="case6" >Document principal</p>
						<p className="case7">Fichier d'adresses</p>
						<p className="case8">Annexe(s)</p>
						<p className="case9">Nb de courriers</p>							
					</div>
					<Loader isLoader={this.state.isLoader} />
					<Popup
						open={this.state.open}
						closeOnDocumentClick
						onClose={this.closeModal}
					>
						<div className="wrap connexion formulaire">
							<div className="bloc noborder modal">						          
								<a className="close" onClick={this.closeModal}>
									&times;
								</a>
								{this.props.message}
								<button className="bt" onClick={this.closeModal}>OK</button>
							</div>
						</div>
					</Popup>
					{this.props.initial && this.props.initial.length > 0 ? (
						this.props.initial.map(el => {
							return this.renderTab(el)
					})
				): (<div></div>)
				}
				</div>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	let a_status = state.envoies.pub_file;
	let error = '';
	let message = '';
	let _st;
	// console.log('update', a_status)
	if (a_status) {		
		_st = a_status.code;
		message = a_status.message
		if (_st !== 200) {
			error = new Date().getTime() + ' ' + _st;
			message = state.envoies.messagedata
		} else {
			error = '';
			message = a_status.data.name
		}
	}

	return {
		update: _st ? _st : '',
		error: error,
		message: message 
	}
}
export default connect(mapStateToProps, {fetchDocMain,fetchCsv,fecthAnnex})(Tabs); 