import React ,{Component} from 'react';
import {connect} from 'react-redux';
import Header from '../header/header';
import Dashboard from './dashboard';
import Footer from '../footer/footer';
import Introduction from './introduction';
import Detailapi from './detailapi';
import Partner from './partner';
import HeaderNoh1 from '../header/headerNoh1';
import { verifyJwt } from '../../actions/logAction';

import '../../assets/css/style.css';
import '../../assets/css/content.css';
import '../../assets/css/content-mobile.css';
import '../../assets/css/surchargebootstrap.css';
import '../../assets/css/footer.css';

class Home extends Component {

	componentDidMount() {
		if (this.props.auth && !this.props.initial) {
			this.props.verifyJwt();
		}
    }

	render(){
		return (
		    <div className={this.props.auth && this.props.initial ? "connected" : "home" }>
		    	{this.props.auth ? (
					<HeaderNoh1/>
						):(
					<Header />
				)}
				<div className="wrap">
				{
					this.props.auth ? (<Dashboard />) : ('')
				}
				</div>
		    	<div className="content">
			    	<Introduction />
			    	<Detailapi />
			    	<Partner />
		    	</div>
		    	<Footer />
		    	{/*eslint-disable */}
		    	<a className="chat" title="Chat"></a>
		    </div>
		)
	}
}

function mapStateToProps(state, ownProps) {
	let a_status = state.login.authenticated;
	let a_profiled = state.login.connected;
    let _prof = ''

	let _auth = '';
	let init = '';

	if (typeof a_status !== 'undefined') {
		_auth = a_status
	} else {
		_auth = ''
	}
	
    if (a_profiled) {
        _prof = a_profiled.code === 200 ? a_profiled.data : ''
        init = _prof ? _prof.firstname+' '+_prof.name: _prof
    } 
    
	return {
		params: ownProps,
		auth: _auth,
		initial: init 
	}
}


export default connect(mapStateToProps,{verifyJwt})(Home);