import React  from 'react';
import Collapse, { Panel } from 'rc-collapse';

const FacturesMensuelle = () => {

  return( 
        <div>
         <Collapse defaultActiveKey="1" accordion={true}>
          <Panel header={`Factures 2013`} key="11">
            <div className="casef white">
              <p>Mai</p>
              <p>2013</p>
              <p>37.96 &euro;</p>
              <p>En cours</p>
              <p className="view"><a href="/" title="">&nbsp;</a></p>
            </div>
            <div className="casef">
              <p>Juin</p>
                  <p>2013</p>
                  <p>37.96 &euro;</p>
                  <p>En cours</p>
                  <p className="view"><a href="/" title="">&nbsp;</a></p>
            </div>
            <div className="casef">
              <p>Juillet</p>
                  <p>2013</p>
                  <p>37.96 &euro;</p>
                  <p>En cours</p>
                  <p className="view"><a href="/" title="">&nbsp;</a></p>
            </div>
            <div className="casef">
              <p>Août</p>
                  <p>2013</p>
                  <p>37.96 &euro;</p>
                  <p>En cours</p>
                  <p className="view"><a href="/" title="">&nbsp;</a></p>
            </div>
            <div className="casef">
             <p>Septembre</p>
                  <p>2013</p>
                  <p>37.96 &euro;</p>
                  <p>En cours</p>
                  <p className="view"><a href="/" title="">&nbsp;</a></p>
            </div>
          </Panel>

          <Panel header={`Factures 2014`} key="2">
            <div className="casef">
              <p>Mai</p>
              <p>2014</p>
              <p>37.96 &euro;</p>
              <p>En cours</p>
              <p className="view"><a href="/" title="">&nbsp;</a></p>
            </div>
            <div className="casef">
              <p>Juin</p>
                  <p>2014</p>
                  <p>37.96 &euro;</p>
                  <p>En cours</p>
                  <p className="view"><a href="/" title="">&nbsp;</a></p>
            </div>
            <div className="casef">
              <p>Juillet</p>
                  <p>2014</p>
                  <p>37.96 &euro;</p>
                  <p>En cours</p>
                  <p className="view"><a href="/" title="">&nbsp;</a></p>
            </div>
            <div className="casef">
              <p>Août</p>
                  <p>2014</p>
                  <p>37.96 &euro;</p>
                  <p>En cours</p>
                  <p className="view"><a href="/" title="">&nbsp;</a></p>
            </div>
            <div className="casef">
             <p>Septembre</p>
                  <p>2014</p>
                  <p>37.96 &euro;</p>
                  <p>En cours</p>
                  <p className="view"><a href="/" title="">&nbsp;</a></p>
            </div>
          </Panel>
        </Collapse>
      </div>
   )
}
export default FacturesMensuelle;