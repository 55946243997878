import React from 'react';
import { connect } from 'react-redux';
import Header from '../header/header';
import Footer from '../footer/footer';
import Accordion from './accordion';
import '../../assets/css/header.css';
import Dashboard from './dashboard';

import HeaderNoh1 from '../header/headerNoh1';

class Faq extends React.Component {
  constructor() {
    super();
    this.state = {
      addClass: false,
      currentSrc: '',
      open: false,
      fields: {},
      errors: {},
      invisible: false
    }
  }

  render() {
    return (
      <div className={this.props.auth ? "connected" : "home"}>
        {
          this.props.auth ? (<HeaderNoh1 />) : (<Header />)
        }
        <div className='wrap'>
          {
            this.props.auth ? (<Dashboard />) : ('')
          }
          <Accordion />
        </div>        
        <br />
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  let a_status = state.login.authenticated;

  return {
    auth: a_status ? a_status : ''
  }
}
export default connect(mapStateToProps, null)(Faq);
