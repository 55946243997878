import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Graphe from '../utils/graphe/graphe';
import HeaderNoh1 from '../header/headerNoh1';
import Dashboard from './dashboard';
import Footer from '../footer/footer';
import Aside from './aside';
import '../../assets/css/header.css';
import { fetchDataGraph, clearData } from '../../actions/envoiesActions';

const styles = {
    graphContainer: {
        padding: '10px 25px 25px 25px',
        background: "#303030",
        margin: '0 0 20px 0'
    }
}

class TableaudeBord extends React.Component {

    componentDidMount() {
        const date = new Date();
        const dateFin = `${date.getFullYear()
            }-${date.getMonth() + 1
            }-${date.getDate()
            }`;
        /**
       * We do this so for january, the date goes internaly to previous year
       * avoiding some pesky calc/condition from us
       */
        date.setMonth(date.getMonth() - 1);
        const dateDebut = `${date.getFullYear()
            }-${date.getMonth() + 1
            }-${date.getDate()
            }`;
        this.props.fetchDataGraph({ dateDebut, dateFin });
    }

    componentWillUnmount() {
        this.props.clearData();
    }

    render() {
        return (
            <div className="connected connectedPage">
                <HeaderNoh1 />
                <div className="content tabs">
                    <div className="wrap">
                        <Dashboard />
                        <div className="aside">
                            <Aside />
                        </div>
                        <div className="bloc tabTitle">
                            {/*eslint-disable */}
                            <h2>Tableau de bord</h2>
                            <h3>Progression de mes envois</h3>
                            <div className="bloc-tableau">
                                <p className="top">
                                    {/*<span><strong>{this.props.depenseSp} €</strong> dépense</span>
                                        <span className="text-center"><strong>{this.props.nbLettersSp}</strong>{this.props.nbLettersSp > 1 ? ' lettres envoyées' : ' lettres envoyée' }</span>
                                    <span><a href="#" title="">Détails &gt; </a></span>*/}
                                </p>
                                <div className="graph">
                                    <div className="infoChart">
                                        <span>Dernier mois</span>
                                        <a href="#" className="refresh"></a>
                                    </div>
                                    <div style={
                                        styles.graphContainer
                                    }>
                                        <Graphe objSp={
                                            this.props.objectSp
                                        }
                                            objPb={
                                                this.props.objectPb
                                            }
                                            max={
                                                this.props.max
                                            } />
                                    </div>
                                    <form className="radio">
                                        <div className="mb-3">
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input name="periode" type="radio" id="custom-inline-radio-1" className="custom-control-input" />
                                                <label title="" type="checkbox" htmlFor="custom-inline-radio-1" className="custom-control-label">par mois</label>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input name="periode" type="radio" id="custom-inline-radio-2" className="custom-control-input" />
                                                <label title="" type="checkbox" htmlFor="custom-inline-radio-2" className="custom-control-label">par an</label>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <hr className="displayNone" />
                            </div>
                            <div className="displayNone">
                                <h2>Ma dernière facture reçu</h2>
                                <p>24/05/2019 à 14:50 la facture REF 0246ml31456kh02 est reçu</p><br />
                                <div className="text-center bottom">
                                    <Link to="/details" className="btn bt btBlue">Regarder les détails</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const graph = state.envoies.graph_data;
    let object;
    let arraySp;
    let arrayPb;
    // console.log(graph)

    if (graph && graph.code === 200) {
        object = graph ? graph.data : ''
        arraySp = object[0][0].map((e) => {
            return {
                x: new Date(e.x),
                y: e.y
            }
        })

        arrayPb = object[0][1].map((e) => {
            return {
                x: new Date(e.xp),
                y: e.yp
            }
        })

    }
    // console.log(object)
    return {
        params: ownProps,
        objectSp: arraySp ? arraySp : [],
        objectPb: arrayPb ? arrayPb : [],
        depenseSp: object ? Math.round(object[1].depenseSp * 100) / 100 : '',
        nbLettersSp: object ? object[1].letterSendSp : '',
        max: object ? object[1].max : 50
    }
}

export default connect(mapStateToProps, { fetchDataGraph, clearData })(TableaudeBord);