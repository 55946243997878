import React  from 'react';
import PopupImg from '../../../assets/images/popup.png'

export const RenderAffrachissement = ({element}) => {
	if (element === 1) {
			return(
				<img alt="" border="0" width="40" src={require('../../../assets/images/lrarbck.png')}/>
			);
		} else if(element === 2) {
			return(
				<img alt="" border="0" width="40" src={require('../../../assets/images/icones/LR.png')} />
			);
		} else if(element === 3) {
			return(
				<img alt="" border="0" width="40" src={require('../../../assets/images/icones/prioritaire.png')}/>
			);
		} else if(element === 4) {
			return(
				<img alt="" border="0" width="40" src={require('../../../assets/images/icones/verte.png')}/>
			);
		} else if (element === 5) {
			return(
				<img alt="" border="0" width="47" height="auto" src={require('../../../assets/images/icones/ecopli.png')}/>
			);
		}  else if(element === 6) {
			return(
				<img alt="" border="0" width="25" height="auto" src={require('../../../assets/images/icones/suivi.png')}/>
			);
		}  else if(element === 7) {
			return(
				<img alt="" border="0" width="47" height="auto" src={require('../../../assets/images/icones/lettre-performance.png')}/>
			);
		}  else if(element === 8) {
			return(
				<img alt="" border="0" width="47" height="auto" src={require('../../../assets/images/icones/lettre-performance-suivi.png')}/>
			);
		}  else if(element === 9) {
			return(
				<img alt="" border="0" width="47" height="auto" src={require('../../../assets/images/icones/verte-suivi.png')}/>
			);
		}
}

export const RenderColor =({element}) => {
	if (element.options_impression === 1) {
			return(
				<span>					
					<i className="hideMob"><img alt="Noir et Blanc" border="0" width="11" height="20" src={require('../../../assets/images/icones/colorup.png')}/></i>
					<span className="mobVersion">Couleur</span>
				</span>
			)
		} else {
			return(
				<span>
					<i className="hideMob"><img alt="Noir et Blanc" border="0" src={require('../../../assets/images/icones/bandwup.png')}/></i>
					<span className="mobVersion">N & B</span>
				</span>
				
			)
		}
}

export const RenderPli =({element}) => {
	if (element.envelop === 0 || element.envelop === 5) {
			return(
				<span>
					<i className="hideMob"><img alt="" border="0" src={require('../../../assets/images/icones/dlone.png')}/></i>
					<span className="mobVersion">Plié en trois</span>
				</span>
			)			 
		} else if(element.envelop === 1 || element.envelop === 6) {
			return(
				<span>
					<i className="hideMob"><img alt="" border="0" src={require('../../../assets/images/icones/pli2.png')}/></i>
					<span className="mobVersion">Plié en deux</span>
				</span>				
			)	
		} else if(element.envelop === 2 || element.envelop === 7) {
			return(
				<span>
					<i className="hideMob"><img alt="" border="0" width="22" height="" src={require('../../../assets/images/icones/c4one.png')}/></i>
					<span className="mobVersion">Sans pli</span>
				</span>
			)		
		} 
}

export const RenderVerso =({element}) => {
	if (element.rect_verso === 1) {
			return(
				<span>
					<i className="hideMob"><img alt="Recto Verso" border="0" width="18" src={require('../../../assets/images/icones/contenu.png')}/></i>
					<span className="mobVersion">Recto Verso</span>
				</span>
			);
	} else {
			return(
				<span>
					<i className="hideMob"><img alt="Recto Verso" className="test" border="0" src={require('../../../assets/images/icones/rectobck.png')}/></i>
					<span className="mobVersion">Recto</span>
				</span>				
			);
	}
}

export const RenderSuivie =({element}) => {
	if(element.suivi) {
		return(
			<div>
			<p>
				<strong>Numéro de suivi</strong><br/>
				<a href={element.suivi} target="_blank" rel="noopener noreferrer" alt="Suivi La Poste"><span>
					<img src={PopupImg} alt="Suivi La Poste" height="20" width="20"></img>{element.numsuivi}</span>
				</a>
				{/* <a rel="noopener noreferrer" target="_blank" className="link-to-post" href={element.suivi}>
					<i className="material-icons-outlined">search</i>
					<span className="mobVersion">Suivre mon courrier </span>
				</a> */}
			</p>
			</div>
		);
	} else {
		return(
			<div></div>
		);
	}
}

export const RenderEtat = ({element}) => {
	if (element.is_pnd) {
		return(
			<a rel="noopener noreferrer" target="_blank" className="link-to-post" href={element.suivi}>
				<img alt="Pli Non Distribué" border="0" width="40" height="auto" src={require('../../../assets/images/icones/pnd.png')}/>
			</a>
			)
	}
	if (element.etat.id_etape === 1) {
		return(
			<i className="material-icons-outlined">print</i>
		)
	} else if (element.etat.id_etape === 2) {
		return(
			<img alt="" border="0" width="24" height="auto" src={require('../../../assets/images/icones/courrier.png')}/>
		)
	} else if (element.etat.id_etape === 3) {
		return(
			<i className="material-icons-outlined">receipt</i>
		)
	} else if (element.etat.id_etape === 4) {
		return(
			<i className="material-icons-outlined">check_circle</i>
		)
	}
}

export const RenderAddress = ({element}) => {
	if (element.address2) {
		return(
			<span>{element.address1}<br/>{element.address2}</span>
		)
	} else {
		return(
			<span>{element.address1}</span>
		)
	}
}