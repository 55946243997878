// import _ from 'lodash';
import {
	EDIT_USER,
	PASS_FORGOT,
    FETCH_PROFILE,
    CLEAR_PROFILE,
    EDIT_PROFILE,
    AUTO_CONNECT_SP,
    AUTO_CONNECT_PUB,
    REGISTER
} from '../actions/actionTypes';

export default function (state = {}, action) {
	switch (action.type) {
        case EDIT_USER:
            return { ...state,pwdChange: action.res};
        case PASS_FORGOT:
      		return { ...state, forgoted: action.res };
        case FETCH_PROFILE:
            return {...state,profiled: action.res}
         case EDIT_PROFILE:
            return { ...state,infoChange: action.res};
        case AUTO_CONNECT_SP:
            return { ...state,uri: action.res};
        case AUTO_CONNECT_PUB:
            return { ...state,uriSp: action.res};
        case REGISTER:
            return {...state,register:action.res}
        case CLEAR_PROFILE:  
            return {};
	    default:
            return state;
    }
}