import React  from 'react';
import '../../assets/css/aside.css';
import "react-datepicker/dist/react-datepicker.css";
import 'rc-collapse/assets/index.css';
import Collapse, { Panel } from 'rc-collapse';

class Serviceplugin extends React.Component {
 state = {addClass: false}
 state = {
    accordion: true,
    activeKey: ['1'],
  }

  onChange = (activeKey) => {
    this.setState({
      activeKey,
    });
  }
   toggle() {
    	this.setState({addClass: !this.state.addClass});
  	}
  getItems() {
  	let btnSlide = ["monthly"],
  		dataSlide = ["data"]
    if(this.state.addClass) {
      btnSlide.push('slideElt');
    }
    if(this.state.addClass) {
      dataSlide.push('dataSlide');
    }
    const items = [];
    items.push(
        <Panel header={`installer le plugin service postal`} key="5">
          <div className="servpost paddAside">
            <p>Pour des envois plus rapides depuis vos outils bureautiques</p>
            <hr/>
              <p><span>Qu'est ce que ce "plugin" ?</span></p>
              <p>C'est un petit logiciel qui s'installe sur votre ordinateur et qui permet d'envoyer directement
              un courrier depuis votre logiciel bureautique : par exemple depuis votre traitement de texte.
              </p>
              <p><span>Comment ça marche ?</span></p>
              <p>
                Cliquez sur <a href="/" target="_blank" rel="noopener noreferrer" title="Installer le plugin">Installer le plugin</a>, puis laissez vous guider...
              </p>
              <p>Pour l'utiliser, par exemple depuis votre traitement de texte, cliquez sur la commande
                imprimer, puis séléctionnez dans la liste des imprimantes celle qui s'appelle Service Postal.
                Valider : le document est téléchargé et vous n'avez qu'à choisir le destinataire et les options de votre courrier.
              </p>
              <a className="bt btBlue bigBt" href="https://www.servicepostal.com/lettre-en-ligne/imprimante-postale" target="_blank" rel="noopener noreferrer" title="Voir la vidéo de démonstration">voir la vidéo de démonstration</a>
              <a className="bt btBlue bigBt" href="/" target="_blank" rel="noopener noreferrer" title="Installer le plugin service postal">Installer le plugin service postal</a>
          </div>
        </Panel>
    );
    return items;
  }
 
  render() {
  	
  const accordion = this.state.accordion;
	return(	
      <div className="servplug">
      <p className="bt_left">
       <a href="/" title="Mes envois" target="_blank">mes envois <span>(7)</span></a>
       <a href="/" title="Mes adresses" rel="noopener noreferrer">mes adresses <span>(23)</span></a>
  		</p>
        <div className="pluginsta">
          <Collapse
            accordion={accordion}
            onChange={this.onChange}
          >
            {this.getItems()}
          </Collapse>
        </div>
      </div>
	 )
  }
}
export default Serviceplugin;