import React  from 'react';

 const Color = ({element}) => {
	if (element.color === 1) {
			return(
				<span>
					<i className="hideMob"><img alt="Noir et Blanc" border="0" width="11" height="20" src={require('../../../assets/images/icones/colorup.png')}/></i>
					<span className="mobVersion">Coleur</span>
				</span>				
			)
		} else {
			return(
				<span>
					<i className="hideMob"><img alt="Noir et Blanc" border="0" src={require('../../../assets/images/icones/bandwup.png')}/></i>
					<span className="mobVersion">N & B</span>
				</span>				
			)
	}
}

export default Color;