import { combineReducers } from 'redux';
import {reducer as formReducer } from 'redux-form';
import factureReducer from './factureReducer';
import loginReducer from './loginReducer';
import userReducer from './userReducer';
import apikeyReducer from './apikeyReducer';
import envoiesReducer from './envoiesReducer';
 
const rootReducer = combineReducers({
	subinfo: factureReducer,
	form: formReducer,
	login: loginReducer,
	user:userReducer,
	apikey:apikeyReducer,
	envoies:envoiesReducer
});

export default rootReducer;