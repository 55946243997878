import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { signoutUser, verifyJwt } from '../../actions/logAction';

class MenuMobile extends React.Component {

	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		if (this.props.auth && !this.props.initial) {
			this.props.verifyJwt();
		}
	}
	render() {
		return (
			<div className="btns mobile">
				<span className="connected">
					<a href="/profil" title="" className="bt login loginName"><span className="material-icons-outlined">
						account_circle
					</span>{this.props.initial}</a>
					<Link to="/" title="Déconnexion" onClick={this.props.signoutUser} className="bt login deconnexion">Déconnexion</Link>
				</span>
				<span className="notConnected">
					<Link to="/login" className="connexion" title="Connexion">Connexion</Link>
					<Link to="/signup" className="bt btBlue inscription" title="Inscription">Inscription</Link>
				</span>
				{/*<span>
					<a href="https://www.servicepostal.com/envoyer-lettre-en-ligne/preparer-courrier" className="envois" title="Envoi du courrier" target='_blank' rel="noopener noreferrer"><span className="material-icons-outlined">
						mail
					</span>Envoyer un courrier</a>
				</span>*/}
				<Link className="tarif" to="/Tarifs" title="Tarifs"><span className="material-icons-outlined">
					euro
				</span>Tarifs</Link>
				<Link className="faq" to="/faq" title="Foire aux questions"><span className="material-icons-outlined">
					help_outline
				</span>FAQ</Link>
				<span>
					<a className="documentation" href="https://docs.servicepostal.com/" title="Documentation">Documentation</a>
				</span>
				<span className="connected">
					<Link to="/tableau" className="tb" title="Tableau de bord">Tableau de bord</Link>
					<Link to="/unitaire" className="envois" title="Mes envois">Mes envois unitaire</Link>
					<Link to="/nombre" className="envois-multiple" title="Mes envois">Mes envois en nombre</Link>
					<Link to="/apikeys" className="api" title="Mes API keys">Mes API keys</Link>
					<Link to="/profil" className="profil" title="Profil">Profil</Link>
					<Link to="/factures" className="facture" title="Factures">Factures</Link>
					<Link to="/typedecompte" className="compte" title="Types de compte">Types de compte</Link>
				</span>
				{/*<span> 
					<a href="https://www.servicepostal.com/nos-services" title="Services" target='_blank' rel="noopener noreferrer">Services</a>
					<a href="https://www.servicepostal.com/lettre-en-ligne/particuliers" title="Particuliers" target='_blank' rel="noopener noreferrer">Particuliers</a>
					<a href="https://www.servicepostal.com/lettre-en-ligne/professionnels" title="Professionnels" target='_blank' rel="noopener noreferrer">Professionnels</a>
					<a href="https://www.servicepostal.com/lettre-en-ligne/entreprises" title="Entreprises" target='_blank' rel="noopener noreferrer">Entreprises</a>
					<a href="https://www.servicepostal.com/lettre-en-ligne/envoi-courrier-publipostage" title="Publipostage" target='_blank' rel="noopener noreferrer">Publipostage</a>
					<a href="https://www.servicepostal.com/lettre-en-ligne/api" title="API" target='_blank' rel="noopener noreferrer">API</a>
					<a href="https://www.servicepostal.com/nos-outils" title="Outils" target='_blank' rel="noopener noreferrer">Outils</a>
				</span>*/}				
				<Link to="/contact" className="contact" title="Contact">Contact</Link>
				<span>
					<a className="publipostage" href="https://publipostage.servicepostal.com/home" title="publipostage" target='_blank' rel="noopener noreferrer">Publipostage</a>
				</span>

			</div>

		);
	}
}

function mapStateToProps(state) {
	let a_status = state.user.profiled;
	let _status = state.login.authenticated;
	let a_connected = state.login.connected;

	let init = '';
	let _st = '';
	let _auth = '';

	if (a_status && !a_connected) {
		_st = a_status.code === 200 ? a_status.data : ''
		init = _st ? _st.firstname + ' ' + _st.name : _st
	}

	if (a_connected) {
		_st = a_connected.code === 200 ? a_connected.data : ''

		if (_st) {
			init = _st.label ? _st.label : _st.firstname + ' ' + _st.name
		}

	}

	if (typeof _status !== 'undefined') {
		_auth = _status
	} else {
		_auth = ''
	}

	return {
		initial: init,
		auth: _auth,
	}
}

export default connect(mapStateToProps, { signoutUser, verifyJwt })(MenuMobile);