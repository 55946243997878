import {UPDATE_APIKEY,FETCH_APIKEY} from '../actions/actionTypes';

export default function (state = {}, action) {
	switch (action.type) {
        case FETCH_APIKEY:
        	return { ...state, apikeyed: action.res};
    	case UPDATE_APIKEY:
      		return { ...state, apik_updated: action.res };
    default:
        return state;
    }
}