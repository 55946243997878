import React  from 'react';
import {Line} from 'react-chartjs-2';


const Graphe = ({objSp,objPb,max}) =>  {
  const date = new Date();
  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();
  let vMax;
  let step;
  const init = [
    {x:new Date(`${year}-${month}-${day}`), y:0},
    {x:new Date(`${year}-${month + 1 }-${day}`),y:0},
  ];
  const spRes = objSp ? objSp : init;
  const pbres = objPb ? objPb : init;


if (max < 5) {
      vMax = 5;
      step = 1;
  } else if (max > 5 && max <= 10) {
      vMax = 15;
      step = 5;
  } else if (max > 10 && max <= 20) {
      vMax = 25;
      step = 10;
  } else if (max > 20 && max <= 30) { 
     vMax = 45;
     step = 15;
  } else if (max > 30 && max <= 40) {
     vMax = 50;
     step = 20;
  } else if (max > 40 && max <= 50) {
     vMax = 60;
     step = 30;
  } else if (max > 50 && max <= 80) {
     vMax = 100;
     step = 35;
  } else if (max > 80 && max <= 100) {
     vMax = 200;
     step = 40;
  } else if (max > 100 && max <= 200) {
     vMax = 300;
     step = 45;
  } else if (max > 200) {
     vMax = max + 100;
     step = 100;
  } else {
    vMax = 10;
    step = 1;
  }

  const data = {
    //labels: ['2PM', '5PM', '8PM', '11PM', '2AM', '5AM', '8AM','11AM', '2PM'],
    datasets: [
      {
        label: "Letter Simple",
        borderColor: '#28ac4b',
        pointBackgroundColor: '#28ac4b',
        pointBorderWidth: 7,
        pointRadius: 1,
        fill: false,  
        data:spRes ,
        yAxesID: 'A',
      },
      {
        label: "Publipostage",
        borderColor: '#006aa1',
        pointBackgroundColor: '#006aa1',
        pointBorderWidth: 7,
        pointRadius: 1,
        fill: false,  
        data:pbres,
        yAxesID: 'B',
      }
    ]
  };
  const option = {
    legend: {
            display: false
         },
    scales: {
        xAxes: [{
          type: 'time',
          time: {
            unitStepSize: 5,
            displayFormats: {
              'day': 'MMM DD'
            }
          }
        }],            
        yAxes: [
            {      
              id: 'A',
              position: 'left',
              gridLines: {
                  display:true
              },  
              ticks : {
                max: vMax,
                min: 0,
                stepSize: step
              },
            },
            {
              id: 'B',
              position: 'right',
              gridLines: {
                  display:true
              },
              ticks : {
                max: vMax,
                min: 0,
                stepSize: step
              }
            },
        ]
    }, 
    tooltips: {
      callbacks: {
        title: function(tooltipItem, data) {
            const d = new Date(tooltipItem[0].xLabel)
            let month = String(d.getMonth() + 1);
            let day = String(d.getDate());
            const year = String(d.getFullYear());
            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;
            return `${day}/${month}/${year}`;          
        },
        
        },
    },
    maintainAspectRatio: false
  };
    return ( 
    <div>   
        <Line
          data={data}
          width={535}
          height={200}
          options={option}
        />
      </div>
        
  )
}


export default Graphe;