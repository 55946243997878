import React  from 'react';

const Type = ({element}) => {
	if (element.envelop < 3) {
			return(
				<span>
					<i className="hideMob"><img alt="" border="0" width="22" height="18" src={require('../../../assets/images/icones/enveloppe_fenetre.png')}/></i>
					<span className="mobVersion">Enveloppe fenêtre C5</span>
				</span>				
			)
		} else {
			return(
				<span>
					<i className="hideMob"><img alt="" border="0" width="22" height="18" src={require('../../../assets/images/icones/enveloppe_c6.png')}/></i>
					<span className="mobVersion">Enveloppe fenêtre C6</span>
				</span>
			)
		}
}

export default Type;