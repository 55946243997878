import React  from 'react';
import '../../assets/css/header.css';
import {NavLink} from 'react-router-dom';

class Footer extends React.Component {
  
  constructor() {
      super();
      this.state = {};
    }

    render() {
      return (
        <div className ="footer">
        <div className="top">
          <div className="wrap">
            {/*eslint-disable */}
            <div className="liens_footer">
              <div>
                    <strong>Nos services</strong>                    
                    <a href="https://www.servicepostal.com/envoyer-lettre-en-ligne/preparer-courrier" title="Envoi unitaire" target="_blank">Envoi unitaire</a>
                    <a href="https://publipostage.servicepostal.com/" title="Envoi en nombre" target="_blank">Envoi en nombre</a>
                    <a href="https://www.servicepostal.com/modeles-de-lettres/" title="Modèles de courrier" target="_blank">Modèles de courrier</a>
              </div>
                {/*<div>
                    <a href="https://www.servicepostal.com/lettre-en-ligne/particuliers/" title=""><strong>Particuliers</strong></a>
                    <a href="https://www.servicepostal.com/lettre-en-ligne/particuliers" title="">Nos services aux particuliers</a>
                    <a href="https://www.servicepostal.com/lettre-en-ligne/tarifs-calculer" title="">Calculez le coût d’envoi d'une lettre</a>
                    <a href="https://www.servicepostal.com/lettre-en-ligne/professionnels/" title="" className="mt-7"><strong>Professionnels</strong></a>
                    <a href="https://www.servicepostal.com/lettre-en-ligne/professionnels/" title="">Nos offres aux professionnels</a>
                </div>
                <div>
                      <a href="https://www.servicepostal.com/lettre-en-ligne/entreprises/" title=""><strong>Entreprises</strong></a>
                      <a href="https://www.servicepostal.com/lettre-en-ligne/entreprises/" title="">Nos offres d’externalisation</a>
                      <a href="https://www.servicepostal.com/lettre-en-ligne/partenaires/" title="" className="mt-7"><strong>Partenaires</strong></a>
                      <a href="https://www.servicepostal.com/lettre-en-ligne/partenaires/" title="">Nos offres d’intégration</a>
                      <a href="https://www.servicepostal.com/lettre-en-ligne/comparatif-des-offres/" title="">Comparatif des offres</a>
                </div>
                <div>
                      <a href="https://www.servicepostal.com/nos-outils" title=""><strong>Nos outils</strong></a>
                      <a href="https://www.servicepostal.com/lettre-en-ligne/archivage" title="">Archivage</a>
                      <a href="https://www.servicepostal.com/lettre-en-ligne/imprimante-postale" title="">Imprimante postale</a>
                      <a href="https://www.servicepostal.com/lettre-en-ligne/google-drive" title="">Google drive</a>
                      <a href="https://www.servicepostal.com/lettre-en-ligne/api" title="">API</a>
                </div>*/}
                <div>
                  <strong>Nos offres</strong>
                  <a href="https://www.servicepostal.com/lettre-en-ligne/abonnements/" title="Abonnements" target="_blank">Abonnements</a>
                  <a href=" https://www.servicepostal.com/lettre-en-ligne/tarifs-calculer" title="calcul de tarifs" target="_blank">Calcul de tarifs</a>                  
                </div>
                <div>
                  <strong>Service Postal</strong>
                  <NavLink to="/faq" title="Questions fréquentes">Questions fréquentes</NavLink>
                  <a href="https://www.servicepostal.com/societe/qui-sommes-nous/" title="Qui sommes nous" target="_blank">Qui sommes nous</a>
                  <a href="https://www.servicepostal.com/societe/conditions-generales/" title="Conditions générales" target="_blank">Conditions générales</a>
                  <a href="https://www.servicepostal.com/societe/confidentialite/" title="Confidentialité" target="_blank">Confidentialité</a>
                  <a href="https://www.servicepostal.com/politique-de-confidentialite/" title="Politique de confidentialité" target="_blank">Politique de confidentialité</a>
                </div>
                <div>
                  <p className="sociaux">
                    <span>Retrouvez-nous sur</span>                    
                    <span className="lien-sociaux">
                      <a href="https://twitter.com/Service_Postal" title="twitter" target="_blank" className="tw" rel="noopener noreferrer"></a>
                      <a href="https://www.facebook.com/ServicePostal" title="facebook" target="_blank" className="fb" rel="noopener noreferrer"></a>
                    </span>
                  </p>
                </div>
                <div className="status">
                  <a href="https://api-status.servicepostal.com/" title="Etat des services" target="_blank" rel="noopener noreferrer">Etat des services : <span> OK</span></a>
                </div>                
            </div>            
          </div>
        </div>
        <div className="bottom">
          <div className="wrap">
            <p className="copyright">©{(new Date()).getFullYear()} <a title="" href="#">XIPE</a> All Rights Reserved</p>
          </div>
        </div>
        </div>
        );
    }
}



export default Footer;





