import {
    ENVOIE_UNITAIRE,
    ENVOIE_NOMBRES,
    ENVOIE_UNITAIRE_DATE,
    GRAPH_DATA,
    UPDATE_UNITAIRES,
    GET_FILES,
    FETCH_LETTERS,
    CLEAR_DATA} from '../actions/actionTypes';

export default function (state = {}, action) {
    switch (action.type) {
        case ENVOIE_UNITAIRE:
        	return { ...state, list_unitaire: action.res};
        case ENVOIE_UNITAIRE_DATE:
        	return { ...state, list_by_date: action.res};
    	case GRAPH_DATA:
      		return { ...state, graph_data: action.res };
        case ENVOIE_NOMBRES:
            return { ...state, list_publipostage: action.res };
        case UPDATE_UNITAIRES:
            return {...state, update_unitiares:action.res};
        case GET_FILES:
            return {...state, pub_file:action.res, messagedata:action.messagedata};
         case FETCH_LETTERS:
            return {...state, fetch_letters:action.resp, messagedata:action.messagedata}
        case CLEAR_DATA:
            return {}
    default:
        return state;
    }
}