import React  from 'react';

export const Pli = ({element}) => {
	if (element.envelop === 0 || element.envelop === 5) {
			return(
				<span>
					<i className="hideMob"><img alt="" border="0" src={require('../../../assets/images/icones/dlone.png')}/></i>
					<span className="mobVersion">Plié en trois</span>
				</span>
			)			 
		} else if(element.envelop === 1 || element.envelop === 6) {
			return(
				<span>
					<i className="hideMob"><img alt="" border="0" src={require('../../../assets/images/icones/pli2.png')}/></i>
					<span className="mobVersion">Plié en deux</span>
				</span>				
			)	
		} else if(element.envelop === 2 || element.envelop === 7) {
			return(
				<span>
					<i className="hideMob"><img alt="" border="0" width="22" height="" src={require('../../../assets/images/icones/c4one.png')}/></i>
					<span className="mobVersion">Sans pli</span>
				</span>				
			)		
	} 
}

export const RenderEtat = ({element}) => {
	if (element.etat.id_etape === 8) {
		return(
			<i className="material-icons-outlined">payment</i>
		)
	} else if (element.etat.id_etape === 9) {
		return(
			<i className="material-icons-outlined">payment</i>
		)
	} else if (element.etat.id_etape === 10) {
		return(
			<i className="material-icons-outlined">payment</i>
		)
	} else if (element.etat.id_etape === 11) {
		return(
			<i className="material-icons-outlined">payment</i>
		)
	} else if (element.etat.id_etape === 12) {
		return(
			<i className="material-icons-outlined">print</i>
		)
	} else if (element.etat.id_etape === 14) {
		return(
			<i className="material-icons-outlined">access_time</i>
		)
	} else if (element.etat.id_etape === 15) {
		return(
			<i className="material-icons-outlined">check_circle</i>
		)
	} else if (element.etat.id_etape === 16) {
		return(
			<i className="material-icons-outlined">cancel</i>
		)
	}
}

// export default Pli;