import React from 'react';
import { connect } from 'react-redux';
import Header from '../header/header';
import HeaderNoh1 from '../header/headerNoh1';
import Footer from '../footer/footer';
import Dashboard from './dashboard';
import ContactForm from './contactform';
import '../../assets/css/header.css';

class Contact extends React.Component {
  constructor() {
    super();
    this.state = {
      fields: {},
      errors: {}
    }

    this.handleChange = this.handleChange.bind(this);
    this.saveForm = this.saveForm.bind(this);

  };

  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({ fields });
    console.log("ii");
  }

  saveForm(e) {
    e.preventDefault();
    if (this.validateForm()) {
      let fields = {};
      fields["nom"] = "";
      fields["prenom"] = "";
      fields["raison"] = "";
      fields["telephone"] = "";
      fields["rue"] = "";
      fields["codepostal"] = "";
      this.setState({ fields: fields });
      alert("Form submitted");
    }

  }

  validateForm(value) {

    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["nom"]) {
      formIsValid = false;
      errors["nom"] = "Merci de saisir votre nom.";
    }

    if (typeof fields["nom"] !== "undefined") {
      if (!fields["nom"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["nom"] = "Merci de saisir des caractères alphabet.";
      }
    }

    if (!fields["telephone"]) {
      formIsValid = false;
      errors["telephone"] = "Merci de saisir votre numero téléphone.";
    }

    if (typeof fields["telephone"] !== "undefined") {
      if (!fields["telephone"].match(/^[0-9]{10}$/)) {
        formIsValid = false;
        errors["telephone"] = "*Veuillez entrer un numero téléphone valide.";
      }
    }
    if (!fields["raison"]) {
      formIsValid = false;
      errors["raison"] = "Merci de remplir le champ raison sociale.";
    }
    if (!fields["rue"]) {
      formIsValid = false;
      errors["rue"] = "Merci de remplir le champ.";
    }

    this.setState({ errors: errors });
    return formIsValid;


  }

  render() {
    return (
      <div className={this.props.auth ? "connected" : "contact-page"}>
        {
          this.props.auth ? (<HeaderNoh1 />) : (<Header />)
        }
        <div className="content tabs">
          <div className="wrap">
            {
              this.props.auth ? (<Dashboard />) : ('')
            }
            <div className="bloc">
              {/*eslint-disable */}
              <div className="top">
                <h2>Contact</h2>
                <strong>Pour nous contacter, renseigner les champs suivants et cliquer sur envoyer.</strong>
              </div>
              <div className="blocArrondi blocArrondiParent">
                <div className="elt contactForm">
                  <ContactForm />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>

    );
  }

}
function mapStateToProps(state, ownProps) {
  let a_status = state.login.authenticated;

  return {
    auth: a_status ? a_status : ''
  }
}

export default connect(mapStateToProps, null)(Contact);
