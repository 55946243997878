import React  from 'react';
import {connect} from 'react-redux';
import {reduxForm,Field} from 'redux-form';
import Popup from 'reactjs-popup';

import Envoi from '../../assets/images/envoi.png';
import EnvoiM from '../../assets/images/envoi_medium.png';
import EnvoiL from '../../assets/images/envoi_large.png';
import EnvoiX from '../../assets/images/envoi_xlarge.png';
import {contactSend} from '../../actions/contactAction';
import SendLetter from '../../assets/images/lettergo.png';

import '../../assets/css/header.css';

class Introduction extends React.Component {
	
	constructor(props) {
    	super(props);
    	this.state = { 
    		currentSrc: '',
    		open: false,
    		fields: {},
            errors: {},
            invisible: false
    	};
    	

    	this.openModal = this.openModal.bind(this)
        this.closeModal = this.closeModal.bind(this)
  	}

    handleFormSubmit({nom,societe,tel}){
    	const obj = {
            'nom': nom,
            'societe':societe,
            'tel':tel
        }
     	if(obj.nom & obj.tel){
     		this.toggle(this.setState({addClass: !this.state.addClass}));
     	}
	    this.props.contactSend(obj);
	    this.setState({invisible: true })
	    setTimeout(() => {
			this.setState({open: false })
			this.setState({invisible: false })
		}, 2000)
    }

    toggle(params){
	   	return params
	}

  	openModal (){
    	this.setState({ open: true })
    }

    closeModal () {
        this.setState({ open: false })
    }

    renderInput = ({ input, label, type,placeholder, meta: { touched, error,pristine,invalid,submitted }}) => (
      <div className="form-group">
      <label htmlFor={label}>{label}</label>
        <input
          className={ touched && invalid ? "form-control is-invalid" : 'form-control'}
          {...input}
          type={type}
          placeholder={placeholder}
        />
        { touched && error && <span className="error">{error}</span> }
      </div>
    );

  	render() {
  		const {handleSubmit} = this.props
  		let showSuccess = ["hideSuccess"];            
        if(this.state.addClass) {
          showSuccess.push('showSuccess');
    	}
	    return (	    	
			    <div className="wrap intro">
			    	{/*eslint-disable */}
			      <div className="w55 fl-l">
			        	<h2>API d'envoi de courrier postal&nbsp;<span className="icon-letter"><img src={SendLetter} alt="" /></span></h2>
						<h4>
							Intégrez l'envoi de courrier par API dans vos process.
							Inscription gratuite et premiers tests en <b>quelques minutes.</b></h4>
					  	<br/>
			          <div className="al-c">
				        <a href="/signup" className="bt btBlue" title="Obtenir une clé API Courrier">Obtenir une clé API Courrier</a>
				        <a href="#" className="bt" title="" onClick={this.openModal}>Faites vous rappeler</a><br/>
				        
				        <Popup
				          open={this.state.open}
				          closeOnDocumentClick
				          onClose={this.closeModal}
				        >
				            <div className="wrap connexion formulaire">
					            <div className="bloc noborder modal">						          
						            <a className="close" onClick={this.closeModal}>
						              &times;
						            </a>
						            <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
							            <div className="form-group">
							            	<legend>On vous rappelle</legend>
							            	<Field name="nom" label="Nom" component={this.renderInput} type="text" placeholder="Nom" required={true} />
									      
								        </div>	
								        <div className="form-group">
								        	<Field name="societe" label="Société" component={this.renderInput} type="text" placeholder="Société" required={true} />
								        </div>	
								        <div className="form-group">
								        	<Field name="tel" label="Téléphone" component={this.renderInput} type="text" placeholder="Téléphone" required={true} />					                    
								        </div>
								        {this.state.invisible ? (
								        	<span className="hideSuccess showSuccess">Vos données ont été envoyées avec succès</span>
								        	) : (
								        	<span></span>
								        )}
								        	
								        <div className="text-center bottom">
									    <button 
									    type='submit'
									    className="btn bt btBlue" onClick = {this.toggle.bind(this)}
									    >
									    Valider
									    </button>
									    <span className="or">OU</span>
									    <span className="call">Appelez-nous au</span>
									    <a href="tel:+3309500950" className="bt btBlue" target="_blank" rel="noopener noreferrer">09 50 09 50 00</a>
								    </div>
						          </form>
						        </div>
					        </div>
				        </Popup>
						<span className="note">Paiement en fin de mois en fonction de vos consommations</span>						
				      </div>
			      </div>
			      <div className="w45 fl-r al-r">
			       <img alt="" border="0" width="403" height="185" src={Envoi} srcSet={`${Envoi} 300w, ${EnvoiM} 768w, ${EnvoiL} 1280w, ${EnvoiX} 3200w`} onLoad={this.onLoad} />
			      </div>
			      <div className="clear"></div>
			    </div>
			);
  	}
}

function validate(values) {
	const errors = {};
	if (!values.nom) {
      errors.nom = 'Merci de saisir votre nom.';
    }
    if (typeof values.nom !== "undefined") {
        if (!values.nom.match(/^[a-zA-Z\u00C0-\u00ff ]*$/)) {
          errors.nom = "Merci de saisir des caractères alphabet.";
        }
    }

	if (!values.tel) {
		errors.tel = "Merci de saisir votre numero téléphone.";
	}

	if (typeof values.tel !== "undefined") {
		if (!values.tel.match(/^[0-9]{10}$/)) {
	  		errors.tel = "*Veuillez entrer un numero téléphone valide.";
		}
	}
	return errors;
}

Introduction = reduxForm({
  form: 'call_form',
  validate: validate,
})(Introduction)

export default connect(null,{contactSend})(Introduction);