import React  from 'react';
import { connect } from 'react-redux';

import HeaderNoh1 from '../header/headerNoh1';
import Footer from '../footer/footer';
import Dashboard from './dashboard';
import '../../assets/css/header.css';
import {subscritiptionFacture,clearSubscrFacture,fetchFacture} from '../../actions/factureAction';
import Loader from '../utils/loader';
import RenderMonth from '../utils/facture/renderMonth';
import ListMonth from '../utils/facture/listMonth';

class Factures extends React.Component {
    constructor() {
      super();
      this.state = {
        fields: {},
        errors: {},
        isLoader: false,
        yearSelect: (new Date()).getFullYear()
      }

      this.handleChange = this.handleChange.bind(this);
      this.saveForm = this.saveForm.bind(this);
      this.selectMonth = this.selectMonth.bind(this);
      this.getFacture = this.getFacture.bind(this);

	};
		
		selectMonth(event) {
			const yearChange = parseInt(event.target.value);
			this.setState({yearSelect:yearChange})
		}

		componentDidMount() {
			this.props.subscritiptionFacture();
		}

		componentDidUpdate(prevProps) {
			if (this.props.factures !== prevProps.factures) {
			this.setState({isLoader: false})
			}
		}

		componentWillUnmount() {
			this.props.clearSubscrFacture();					
		}

		renderMonth() {			
			let yActiv;
			let mAct;
			let subActivation =  this.props.subscriptionStartActivation;
			const yearSelected = this.state.yearSelect;
			subActivation = subActivation.split("-");
			yActiv = parseInt(subActivation[0], 0);
			mAct = parseInt(subActivation[1], 10);
			const list = ListMonth.arrayM(yActiv,yearSelected,mAct);

			return(
			    list.map((e,i) => {
			        return (
			        /*eslint-disable */
			          <a href="javascript:void(0);" onClick={() => this.getFacture(e)} key={i} attr={i}>{e}</a>
			        )
			      })
			    )
			
		}

		getFacture(el){
			// console.log('ss',RenderMonth.list(el))
			const obj = {
				'id':this.props.id,
				'year':this.state.yearSelect,
				'month': RenderMonth.list(el)
			}

			this.props.fetchFacture(obj);
			this.setState({isLoader:true})
		}

	    handleChange(e) {
	      let fields = this.state.fields;
	      fields[e.target.name] = e.target.value;
	      this.setState({
	        fields
	      });

	    }

	    saveForm(e) {
	      e.preventDefault();
	      if (this.validateForm()) {
	          let fields = {};
	          fields["nom"] = "";
	          fields["prenom"] = "";
	          fields["raison"] = "";
	          fields["telephone"] = "";
	          fields["rue"] = "";
	          fields["codepostal"] = "";
	          this.setState({fields:fields});
	          alert("Form submitted");
	      }

	    }

	    renderYear(year){
			return(
				<option key={year} value={year}>{year}</option>
			)				
	    }

  render() {
	return(
			<div className="connected connectedPage">
	          	<HeaderNoh1/>
	          	<div className="content tabs">
	          		<div className="wrap">
	          			<Dashboard />
	    				<div className="bloc">
	    		        	{/*eslint-disable */}
							<div className="top">
                               <h2 className="fl-l">Bienvenue <strong>{this.props.initial}</strong></h2>
                               <p className="fl-r mt20">ABONNEMENT {this.props.subscriptionType}</p>
							</div>
							<div className="blocArrondi">
                                <div className="elt factures">
                                  <p className="title">MES FACTURES MENSUELLES</p>
                                   <div className="childBloc">
                                     <div className="form-group">
							            <label htmlFor="annee" className="labelShow">Sélectionner une année pour voir les factures correspondantes:</label>
							            <select name="annee" className="select-50" onChange= {this.selectMonth.bind(this)}>
											{this.props.subscriptionYears ? (
								        		this.props.subscriptionYears.map(el => {			        		
										      	  return this.renderYear(el)
										    	})
								        	): (<option></option>)
								        	}
							            </select>
							            <Loader isLoader={this.state.isLoader} />
							        </div>
                                  </div>
                                  <div className="childBloc">
                                    <p className="title" id="yearFacture">FACTURES {this.state.yearSelect}</p>
                                    {this.props.subscriptionStartActivation ? (
						        		<div className="mois" id="mois">
						        			{/*eslint-disable */}
						        			{this.renderMonth()}
						        		</div>
							        	): (<div></div>)
								    }
                                    
                                  </div>
							    </div>
							    {/*<div className="elt">
                                  <p className="title">ADRESSE DE FACTURATION</p>
                                   <form method="post"  name="saveForm"  onSubmit= {this.saveForm} >
										<div className="childBloc">
									         <div className="form-group">
									            <label htmlFor="nom">Nom</label>
									            <input type="text" 
									            name="nom" 
									            value={this.state.fields.nom} 
									            className={`form-control ${this.state.errors.nom ? 'is-invalid' : ''}`} 
									            onChange={this.handleChange}
									            placeholder="Nom**" /> 
									            <span className="error">{this.state.errors.nom}</span>
									        </div>
									        <div className="form-group">
									            <label htmlFor="prenom">Prenom</label>
									            <input type="text" 
									            name="prenom" 
									            className={`form-control ${this.state.errors.prenom ? 'is-invalid' : ''}`} 
									            onChange={this.handleChange}
									            placeholder="Prenom**" /> 
									            <span className="error">{this.state.errors.prenom}</span>
									        </div>
									        <div className="form-group">
									            <label htmlFor="telephone">Téléphone</label>
									            <input type="text" 
									            name="telephone" 
									            value={this.state.fields.telephone} 
									            className={`form-control ${this.state.errors.telephone ? 'is-invalid' : ''}`} 
									            onChange={this.handleChange}
									            placeholder="Téléphone**" /> 
									            <span className="error">{this.state.errors.telephone}</span>
									        </div>
									    </div>
										<div className="childBloc">
									         <div className="form-group">
									            <label htmlFor="raison">Raison sociale</label>
									            <input type="text" 
									            name="raison" 
									            value={this.state.fields.raison} 
									            className={`form-control ${this.state.errors.raison ? 'is-invalid' : ''}`} 
									            onChange={this.handleChange}
									            placeholder="Raison sociale**" /> 
									            <span className="error">{this.state.errors.raison}</span>
									        </div>
									        <div className="form-group">
									            <label htmlFor="rue">N° rue</label>
									            <input type="text" 
									            name="rue" 
									            className="form-control" 
									            onChange={this.handleChange}
									            placeholder="N° rue" /> 
									        </div>
									        <div className="form-group">
									            <label htmlFor="complement">Complement d'adresse</label>
									            <input type="text" 
									            name="complement" 
									            value={this.state.fields.telephone} 
									            className="form-control" 
									            onChange={this.handleChange}
									            placeholder="Complement d'adresse" /> 
									        </div>
									        <div className="form-group w-33">
									            <label htmlFor="codepostal">Code Postal</label>
									            <input type="text" 
									            name="codepostal" 
									            value={this.state.fields.codepostal} 
									            className="form-control" 
									            onChange={this.handleChange}
									            placeholder="Code Postal" /> 
									        </div>
									        <div className="form-group w-61">
									            <label htmlFor="codepostal">Ville</label>
									            <select name="ville">
                                                   <option value="" disabled>Ville</option>
                                                   <option>Paris</option>
                                                   <option>Londres</option>
									            </select>
									        </div>
									        <div className="text-right bottom">
							                    <button type='submit'
							                    className="btn bt btBlue" 
							                    >
							                    Sauvegarder
							                    </button>
							                  </div>
									    </div>
									</form>
							    </div>*/}
							</div>
		        	</div>
		        </div>
			</div>
			<Footer/>
			</div>

      );
  }
}

function mapStateToProps(state, ownProps) {
  	let a_profiled = state.login.connected;
  	let sub = state.subinfo.sub_info;
  	let factures = state.subinfo.fecth_factures;
	let _prof = '';
	let subscriptionType = '';
	let subscriptionStartActivation = '';
	let subscriptionYears = '';
	let id;	
	// console.log('fact', sub)
	if (sub){
		id = sub.id;
		subscriptionType = sub.subscription_type;
		subscriptionStartActivation = sub.subscription_activation;
		subscriptionYears = sub.subscription_years;
	}
  
  if (a_profiled) {
        _prof = a_profiled.code === 200 ? a_profiled.data : ''
  } 
    
  return {
    	params: ownProps,
		initial: _prof ? _prof.firstname+' '+_prof.name: _prof,
		id: id ? id : '',
		subscriptionType: subscriptionType,
		subscriptionStartActivation: subscriptionStartActivation,
		subscriptionYears: subscriptionYears,
		factures:factures ? factures : '' 
  }
}

export default connect(mapStateToProps,{subscritiptionFacture,clearSubscrFacture,fetchFacture})(Factures);