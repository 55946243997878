import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import NavHeader from '../header/navHeader';
import '../../assets/css/header.css';
import Logo from '../../assets/images/logo.png';
import LogoM from '../../assets/images/logo_medium.png';
import LogoL from '../../assets/images/logo_large.png';
import LogoX from '../../assets/images/logo_xlarge.png';
import { signoutUser, verifyJwt } from '../../actions/logAction';
import Popup from 'reactjs-popup'


// start part element fixed
const debounce = (func, wait) => {
  let timeout
  return (...args) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => func.apply(this, args), wait)
  }
}
// end part element fixed
class HeaderNoh1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSrc: '',
      addClass: false,
      scrollPositionY: 0
    }
  }

  // start part element load image
  onLoad = (event) => {
    this.setState({
      currentSrc: event.target.currentSrc
    });
  }
  // end part element load image

  toggle() {
    this.setState({ addClass: !this.state.addClass });
  }

  // start part element fixed

  componentDidMount() {
    if (this.props.auth && !this.props.initial) {
      this.props.verifyJwt();
    }
    return window.addEventListener('scroll', debounce(this.handleScroll, 32))
  }

  componentWillUnmount() {
    return window.removeEventListener('scroll', debounce(this.handleScroll, 32))
  }

  handleScroll = () => {
    const scrollPositionY = +window.scrollY
    return this.setState({ scrollPositionY })
  }


  // end part element fixed

  render() {

    const isscrolling = !!this.state.scrollPositionY  //fixed

    let showMenu = ["fl-r"];
    if (this.state.addClass) {
      showMenu.push('show');
    }
    let slideMenu = ["bt user"];
    if (this.state.addClass) {
      slideMenu.push('slide');
    }

    return (
      <div className={(isscrolling) ? 'header isScrolling' : 'header'} >
        {/*eslint-disable */}
        <div className="wrap">
          <div>
            <h1>
            <a href="/" className="Logo" title="Service Postal API">
              <img alt="Service Postal API" border="0" src={Logo} srcSet={`${Logo} 300w, ${LogoM} 768w, ${LogoL} 1280w, ${LogoX} 3200w`} onLoad={this.onLoad} />
              Service Postal
              <span className="Logo2">API</span>
            </a>
            </h1>
          </div>
          <div id="mask"
            className={showMenu.join(' ')}
            onClick={this.toggle.bind(this)}
          >
          </div>
          <span id="togMenu"
            onClick={this.toggle.bind(this)}
            className={showMenu.join(' ')}
            href="#" title="">
          </span>
          <div id="menu" className={showMenu.join(' ')}>
            <NavHeader />
            <div className="btns">
              <span>
                <Link to="/signup" className="bt btBlue inscription" title="Inscription">Inscription</Link>
              </span>
              <Popup
                trigger={<a href="#" title="" className="bt user">{this.props.initial}</a>}
                position="top center"
                closeOnDocumentClick
              >
                <ul id="toggleLogout">
                  <li className="notHome tb"><Link to="/tableau" title="Tableau de bord">Tableau de bord</Link></li>
                  <li className="notHome envoi"><Link to="/tableau" title="Mes envois">Mes envois</Link></li>
                  <li className="notHome api"><Link to="/apikeys" title="Mes API keys">Mes API keys</Link></li>
                  <li className="profil"><Link to="/profil" title="Informations personnelles">Informations personnelles</Link></li>
                  {/*<li className="facture"><Link to="/factures" title="Factures">Factures</Link></li>*/}
                  {/*<li><Link to="/typedecompte" title="Types de compte">Types de compte</Link></li>*/}
                  <li><Link to="/" onClick={this.props.signoutUser} className="bt btBlue">Déconnexion</Link></li>
                </ul>
              </Popup>
              <Link to="/login" className="bt login connexion" title="Connexion">Connexion</Link>
            </div>
            <a href="#" className="panier">Panier<span>(0)</span></a>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  let a_status = state.user.profiled;
  let _status = state.login.authenticated;
  let a_connected = state.login.connected;
  let init = '';
  let _st = '';
  let _auth = '';

  if (a_status && !a_connected) {
    _st = a_status.code === 200 ? a_status.data : ''
    init = _st ? _st.firstname + ' ' + _st.name : _st
  }

  if (a_connected) {
    _st = a_connected.code === 200 ? a_connected.data : ''

    if (_st) {
      init = _st.label ? _st.label : _st.firstname + ' ' + _st.name
    }

  }

  if (typeof _status !== 'undefined') {
    _auth = _status
  } else {
    _auth = ''
  }

  return {
    initial: init ? init : 'Bonjour',
    auth: _auth,
  }
}

export default connect(mapStateToProps, { signoutUser, verifyJwt })(HeaderNoh1);





