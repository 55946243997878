import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import reduxThunk from 'redux-thunk';

import 'bootstrap/dist/css/bootstrap.min.css';
import reducer from './reducer';
import {AUTH} from './actions/actionTypes';
import Login from './components/auth/login';
import Mdpoublie from './components/auth/mot-de-passe-oublie';
import Inscription from './components/auth/inscription';

import TableaudeBord from './components/content/tableau';
import Profil from './components/content/profil';
import ApiKeys from './components/content/apikeys';
import RequiredLogin from './components/auth/requiredLogin';
import PasswordUpdate from './components/auth/passwordUpdate';
import Home from './components/content/home';
import Factures from './components/content/factures';
import Contact from './components/content/contact';
import Tarifs from './components/content/tarifs';
import Faq from './components/content/faq';
import Unitaire from './components/content/unitaire';
import Nombre from './components/content/nombre';
import TypeCompte from './components/content/typedecompte';
import Abonnement from './components/content/abonnement';
import {API_URL} from './config/config';
import Tools from './tools/tools';
import 'material-icons/iconfont/material-icons.css';

// import CheckJwt from './components/auth/checkJwt';

const currentUrl = window.location.href;
let gtmID = '';

if (currentUrl.includes('test')) {
  gtmID = 'GTM-TJJP3NJ';
} else if (!currentUrl.includes('localhost')) {	
	gtmID = 'GTM-TMS5SQW8';
}

if (gtmID) {
  const script = document.createElement('script');  
  script.innerHTML = `
	(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
	new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
	j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
	'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
	})(window,document,'script','dataLayer','${gtmID}');
  `;
  document.head.appendChild(script);
}

const createStoreWithMiddleware = applyMiddleware(reduxThunk)(createStore);
const store = createStoreWithMiddleware(reducer);

const token = localStorage.getItem('token');

/*const TRACKING_ID = "G-N9PEDKVDQY";
ReactGA.initialize(TRACKING_ID);*/


if (!token && Tools.getCookies()) {
	const data = {'cookie':Tools.getCookies()}
	fetch(API_URL+'/connect/inversed',
        {
          method:'POST',
          headers:{
            'Content-Type':'application/json',
          },
          body:JSON.stringify(data)
        }
      ).then((resp) => {
        return resp.json();
    }).then((res) => {
    	if (res.code === 200) {
    		localStorage.setItem('token', res.data);
        	store.dispatch({type: AUTH});
    	} 
       // window.location.reload() = true 
    });
}

if (token) {
	store.dispatch({type: AUTH});
 }

ReactDOM.render(
		<Provider store = {store}>
			<Router>
				<Switch>
					<Route exact path="/" component={Home}/>
					<Route  path="/login" component={Login}/>
					<Route  path="/mot-de-passe-oublie" component={Mdpoublie}/>
					<Route  path="/signup" component={Inscription}/>
					<Route  path='/motdepasse' component={PasswordUpdate} />
					<Route path='/tableau' component={RequiredLogin(TableaudeBord)} />
					<Route path='/profil' component={RequiredLogin(Profil)} />
					<Route path='/factures' component={RequiredLogin(Factures)} />
					<Route path='/apikeys' component={RequiredLogin(ApiKeys)} />
					<Route path='/contact' component={Contact} />
					<Route path='/tarifs' component={Tarifs} />
					<Route path='/faq' component={Faq} />
					<Route path='/unitaire' component={RequiredLogin(Unitaire)} />
					<Route path='/nombre' component={RequiredLogin(Nombre)} />
					<Route path='/typedecompte' component={RequiredLogin(TypeCompte)} />
					<Route path='/abonnement' component={RequiredLogin(Abonnement)} />
				</Switch>
			</Router>
		</Provider>

, document.getElementById('root'));
