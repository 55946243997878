import React  from 'react';
import {Link} from 'react-router-dom';
import Popup from 'reactjs-popup'
import MenuEnvois from './menuenvois';


const Dashboard = () => {
	return(	
		<div className="bloc">
			<nav>
				<ul className="dashboard">
					<li className="disabled"><Link to="/tableau" className="nav-link" title="">tableau de bord</Link></li>
					<li className="menuEnvois disabled">
						  <Popup
	                      trigger={<Link to = "#" title="" className="nav-link">Mes envois</Link>}
	                      position="top center"
	                      on = "click"
	                      closeOnDocumentClick
	                    >
                     		<MenuEnvois/>
                     		
	                  </Popup>
					</li>
					<li className="disabled"><Link to="/apikeys" className="nav-link" title="Mes api keys">mes api key</Link></li>
					{/*<li><a href={DOC_URL} target="_blank" rel="noopener noreferrer" className="nav-link" title="Documentation">Documentation</a></li>*/}
				</ul>
				{/*<ul className="dashboard undermenu">
					<li>
	                    <Popup
	                      trigger={<Link to = "#" title="" className="nav-link">Solutions</Link>}
	                      position="top center"
	                      on = "hover"
	                      closeOnDocumentClick
	                    >
                  	  		<SousMenu />
	                  </Popup>
					</li>
					<li>
					    <Link to = "/tarifs" title="" className="nav-link">tarifs</Link>
	                    {/*<Popup
	                      trigger={<Link to = "/tarifs" title="tarifs" className="nav-link">tarifs</Link>}
	                      position="top center"
	                      on = "hover"
	                      closeOnDocumentClick
	                    >
                    		//<Tarifs/>
	                  </Popup>
					</li>
					<li>
	                    <Popup
	                      trigger={<Link to = "#" title="" className="nav-link">contact</Link>  }
	                      position="top center"
	                      on = "click"
	                      closeOnDocumentClick
	                    >
                     		<ContactForm/>
	                  </Popup>

					</li>
				</ul>*/}
			</nav>
        </div>
	 )
}
export default Dashboard;