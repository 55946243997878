import React  from 'react';
import { connect } from 'react-redux';
import HeaderNoh1 from '../header/headerNoh1';
import Footer from '../footer/footer';
import Dashboard from './dashboard';
import '../../assets/css/header.css';
import Loader from '../utils/loader';
import {
  clearSubscrFacture,
  createSubscription} from '../../actions/factureAction';



class Abonnement extends React.Component {
    constructor() {
      super();
      this.state = {
        fields: {},
        errors: {},
        isLoader: false,
      }

      this.handleChange = this.handleChange.bind(this);
      this.saveForm = this.saveForm.bind(this);

		};

    handleChange(e) {
      let fields = this.state.fields;
      fields[e.target.name] = e.target.value;
      this.setState({
        fields
      });

		}

    saveForm(e) {
      e.preventDefault();
      if (this.validateForm()) {
          let fields = {};
          fields["nom"] = this.state.fields.nom;
          fields["prenom"] = this.state.fields.prenom;
					fields["raison"] = this.state.fields.raison;
					fields["fonction"] = this.state.fields.fonction ? this.state.fields.fonction : "";
					fields["telephone"] = this.state.fields.telephone ? this.state.fields.telephone : "";
					fields["lieudit"] = this.state.fields.lieudit ? this.state.fields.lieudit : "";
          fields["ville"] = this.state.fields.ville;
          fields["siret"] = this.state.fields.siret;
          fields["codepostal"] = this.state.fields.codepostal;
          fields["iban"] = this.state.fields.iban;
          fields["bic"] = this.state.fields.bic;
          fields["tva"] = this.state.fields.tva;
          fields["numerovoie"] = this.state.fields.numerovoie;
					
					this.setState({fields:fields,isLoader:true});
					
					this.props.createSubscription(fields, (path) => {
						this.props.params.history.push(path)
					})
      }

    }

    validateForm(value) {

      let fields = this.state.fields;
      let errors = {};
      let formIsValid = true;

      if (!fields["nom"]) {
        formIsValid = false;
        errors["nom"] = "Merci de saisir votre nom.";
      }

      if (typeof fields["nom"] !== "undefined") {
        if (!fields["nom"].match(/^[a-zA-Z ]*$/)) {
          formIsValid = false;
          errors["nom"] = "Merci de saisir des caractères alphabet.";
        }
      }
			
      if (!fields["prenom"]) {
        formIsValid = false;
        errors["prenom"] = "Merci de saisir votre prenom.";
      }

      if (typeof fields["prenom"] !== "undefined") {
        if (!fields["prenom"].match(/^[a-zA-Z ]*$/)) {
          formIsValid = false;
          errors["prenom"] = "Merci de saisir des caractères alphabet.";
				}
			}

      if (fields["telephone"]) {
        if (!fields["telephone"].match(/^((\+?)[0-9]{10}$)/)) {
          formIsValid = false;
          errors["telephone"] = "Merci de saisir des caractères numeriques.";
				}
			}
			
       if (!fields["ville"]) {
        formIsValid = false;
        errors["ville"] = "Merci de saisir votre ville.";
      }

      if (typeof fields["ville"] !== "undefined") {
        if (!fields["ville"].match(/^[a-zA-Z ]*$/)) {
          formIsValid = false;
          errors["ville"] = "Merci de saisir des caractères alphabet.";
				}
      }
			
      if (!fields["codepostal"]) {
        formIsValid = false;
        errors["codepostal"] = "Merci de saisir votre code postal.";
      }

      if (typeof fields["codepostal"] !== "undefined") {
        if (!fields["codepostal"].match(/^[0-9]{5}$/)) {
          formIsValid = false;
          errors["codepostal"] = "*Veuillez entrer un code postal valide.";
				}
      }

      if (!fields["siret"]) {
        formIsValid = false;
        errors["siret"] = "Merci de saisir votre numero SIRET.";
      }
			
      if (typeof fields["siret"] !== "undefined") {
        if (!fields["siret"].match(/^[0-9]{14}$/)) {
          formIsValid = false;
          errors["siret"] = "*Veuillez entrer un numero SIRET valide.";
				}
      }

       if (!fields["raison"]) {
        formIsValid = false;
        errors["raison"] = "Merci de remplir le champ raison sociale.";
			}
			
      /*if (!fields["rue"]) {
        formIsValid = false;
        errors["rue"] = "Merci de remplir le champ.";
			}*/
			
      if (!fields["iban"]) {
        formIsValid = false;
        errors["iban"] = "Merci de saisir votre IBAN.";
      }
			
      if (typeof fields["iban"] !== "undefined") {
        if (!fields["iban"].match(/^(?:(?:IT|SM)\d{2}[A-Z]\d{22}|CY\d{2}[A-Z]\d{23}|NL\d{2}[A-Z]{4}\d{10}|LV\d{2}[A-Z]{4}\d{13}|(?:BG|BH|GB|IE)\d{2}[A-Z]{4}\d{14}|GI\d{2}[A-Z]{4}\d{15}|RO\d{2}[A-Z]{4}\d{16}|KW\d{2}[A-Z]{4}\d{22}|MT\d{2}[A-Z]{4}\d{23}|NO\d{13}|(?:DK|FI|GL|FO)\d{16}|MK\d{17}|(?:AT|EE|KZ|LU|XK)\d{18}|(?:BA|HR|LI|CH|CR)\d{19}|(?:GE|DE|LT|ME|RS|CS)\d{20}|IL\d{21}|(?:AD|CZ|ES|MD|SA)\d{22}|PT\d{23}|(?:BE|IS)\d{24}|(?:FR|MR|MC|MG)\d{25}|(?:AL|DO|LB|PL)\d{26}|(?:AZ|HU)\d{27}|(?:GR|MU)\d{28})$/i)) {
          formIsValid = false;
          errors["iban"] = "*Veuillez entrer un IBAN valide.";
				}
			}
     
      if (!fields["bic"]) {
        formIsValid = false;
        errors["bic"] = "Merci de saisir votre BIC.";
      }
			
      if (typeof fields["bic"] !== "undefined") {
        if (!fields["bic"].match(/^[a-zA-Z]{4}[a-zA-Z]{2}[a-zA-Z0-9]{2}([a-zA-Z0-9]{3})?/)) {
          formIsValid = false;
          errors["bic"] = "*Veuillez entrer un BIC valide.";
				}
      }
			
      if (!fields["tva"]) {
        formIsValid = false;
        errors["tva"] = "Merci de saisir votre N° TVA intra­communautaire.";
      }

      if (typeof fields["tva"] !== "undefined") {
        if (!fields["tva"].match(/^[A-Z]{2}(?:[ ]?[0-9]){11}$/)) {
          formIsValid = false;
          errors["tva"] = "*Veuillez entrer un N° TVA intra­communautaire valide.";
				}
      }

      if (!fields["numerovoie"]) {
        formIsValid = false;
        errors["numerovoie"] = "Merci de saisir le N° et libellé de la voie.";
      }
			
      if (typeof fields["numerovoie"] !== "undefined") {
        if (!fields["numerovoie"].match(/^[0-9]{1,5}([ ])?([, -])?(?:(?:[. ]){1}[a-zA-Zàâäéèêëïîôöùûüç]+)+/)) {
          formIsValid = false;
          errors["numerovoie"] = "*Veuillez entrer un N° et libellé de la voie valide.";
				}
			}
			
			if (fields["lieudit"]) {
        if (!fields["lieudit"].match(/^[0-9]{1,5}([ , - ])?/)) {
          formIsValid = false;
          errors["lieudit"] = "*Veuillez entrer un BP, lieudit, commune valide.";
				}
      }

      this.setState({
        errors: errors
			});
			
      return formIsValid;

    }

  render() {
	return(
			<div className="connected connectedPage">
	          	<HeaderNoh1/>
	          	<div className="content tabs">
	          		<div className="wrap">
	          			<Dashboard />
	    				<div className="bloc">
	    		        	{/*eslint-disable */}
							<div className="top">
                               <h1 className="fl-l">CONTRAT D’ABONNEMENT SERVICE POSTAL</h1>
							</div>
							<div className="blocArrondi w-65 loadParent">
                                <form method="post"  name="saveForm"  onSubmit= {this.saveForm} >
							    <div className="elt">
                                  <p className="title identite">IDENTITÉ SOCIÉTÉ</p>
                                  
										<div className="childBloc">
										     <div className="form-group">
									            <label htmlFor="raison">Raison sociale</label>
									            <input type="text" 
									            name="raison" 
									            className={`form-control ${this.state.errors.raison ? 'is-invalid' : ''}`} 
									            onChange={this.handleChange}
									            placeholder="Raison sociale**" /> 
									            <span className="error">{this.state.errors.raison}</span>
									        </div>
									        <div className="form-group">
									            <label htmlFor="numerovoie">N° et libellé de la voie*</label>
									            <input type="text" 
									            name="numerovoie" 
									            className={`form-control ${this.state.errors.numerovoie ? 'is-invalid' : ''}`} 
									            onChange={this.handleChange}
									            placeholder="N° et libellé de la voie*" /> 
									            <span className="error">{this.state.errors.numerovoie}</span>
									        </div>
									        <div className="form-group">
									            <label htmlFor="commune">BP, lieu­dit, commune</label>
									            <input type="text" 
															name="lieudit"
															className={`form-control ${this.state.errors.lieudit ? 'is-invalid' : ''}`}
									            onChange={this.handleChange}
									            placeholder="BP, lieu­dit, commune" /> 
															<span className="error">{this.state.errors.lieudit}</span>
									        </div>
									        <div className="form-group">
									            <label htmlFor="codepostal">Code Postal</label>
									            <input type="text" 
									            name="codepostal"  
									            className={`form-control ${this.state.errors.codepostal ? 'is-invalid' : ''}`} 
									            onChange={this.handleChange}
									            placeholder="Code Postal*" /> 
									            <span className="error">{this.state.errors.codepostal}</span>
									        </div>
									    </div>
										<div className="childBloc">
									        <div className="form-group">
									            <label htmlFor="ville">Ville</label>
									            <input type="text" 
									            name="ville"  
									            className={`form-control ${this.state.errors.ville ? 'is-invalid' : ''}`} 
									            onChange={this.handleChange}
									            placeholder="Ville*" /> 
									            <span className="error">{this.state.errors.ville}</span>
									        </div>
									        <div className="form-group">
									            <label htmlFor="siret">SIRET*</label>
									            <input type="text" 
									            name="siret"  
									            className={`form-control ${this.state.errors.siret ? 'is-invalid' : ''}`} 
									            onChange={this.handleChange}
									            placeholder="SIRET*" /> 
									            <span className="error">{this.state.errors.siret}</span>
									        </div>
									        <div className="form-group">
									            <label htmlFor="tva">N° TVA intra­communautaire*</label>
									            <input type="text" 
									            name="tva" 
									            className={`form-control ${this.state.errors.tva ? 'is-invalid' : ''}`} 
									            onChange={this.handleChange}
									            placeholder="N° TVA intra­communautaire*" /> 
									            <span className="error">{this.state.errors.tva}</span>
									        </div>
									    </div>
									    <div className="clear">&nbsp;</div>
									    <p className="pl-15">* Les champs marqués d’un astérisque doivent obligatoirement être renseignés.</p>
							     </div>


							        <div className="elt mb-14">
                                        <p className="title signataire">SIGNATAIRE DU CONTRAT</p>
										<div className="childBloc">
									         <div className="form-group">
									            <label htmlFor="nom">Nom</label>
									            <input type="text" 
									            name="nom"  
									            className={`form-control ${this.state.errors.nom ? 'is-invalid' : ''}`} 
									            onChange={this.handleChange}
									            placeholder="Nom*" /> 
									            <span className="error">{this.state.errors.nom}</span>
									        </div>
									        <div className="form-group">
									            <label htmlFor="fonction">Fonction</label>
									            <input type="text" 
									            name="fonction"  
									            className="form-control"  
									            onChange={this.handleChange}
									            placeholder="Fonction" /> 
									        </div>
									    </div>
										<div className="childBloc">
										     <div className="form-group">
									            <label htmlFor="prenom">Prénom</label>
									            <input type="text" 
															name="prenom" 
									            className={`form-control ${this.state.errors.prenom ? 'is-invalid' : ''}`} 
									            onChange={this.handleChange}
									            placeholder="Prénom*" /> 
									            <span className="error">{this.state.errors.prenom}</span>
									        </div>
									        <div className="form-group">
									            <label htmlFor="telephone">Téléphone</label>
									            <input type="text" 
									            name="telephone"  
									            className="form-control" 
									            onChange={this.handleChange}
									            placeholder="Téléphone" 
															className={`form-control ${this.state.errors.telephone ? 'is-invalid' : ''}`}
															/>
															<span className="error">{this.state.errors.telephone}</span> 
									        </div>
									    </div>
							        </div>

							        <div className="elt">
                                        <p className="title information">INFORMATION DE PAIEMENT</p>
										<div className="childBloc center">
									         <div className="form-group">
									            <label htmlFor="iban">Numéro d'identification international du compte bancaire (IBAN)*</label>
									            <input type="text" 
															name="iban"  
									            className={`form-control ${this.state.errors.iban ? 'is-invalid' : ''}`} 
									            onChange={this.handleChange}
									            placeholder="Numéro d'identification international du compte bancaire (IBAN)*" /> 
									            <span className="error">{this.state.errors.iban}</span>
									        </div>
									        <div className="form-group">
									            <label htmlFor="bic">Code international d'identification de votre banque (BIC)*</label>
									            <input type="text" 
															name="bic" 
									            className={`form-control ${this.state.errors.bic ? 'is-invalid' : ''}`} 
									            onChange={this.handleChange}
									            placeholder="Code international d'identification de votre banque (BIC)*" /> 
									            <span className="error">{this.state.errors.bic}</span>
									        </div>
									        <div className="text-center bottom">
							                    <button type='submit'
							                    className="btn bt btBlue btYellow" 
							                    >
							                    Valider votre abonnement
							                    </button>
							                </div>
									    </div>
							        </div>
							    </form>
							    <Loader isLoader={this.state.isLoader} />
							</div>
		        	</div>
		        </div>
			</div>
			<Footer/>
			</div>

      );
  }
}

function mapStateToProps(state, ownProps) {
  let a_status = state.user.profiled;
  let auto_c = state.user.uriSp;
  let auto_pub = state.user.uri;

  let subscriptionType = '';
  let subscriptionState = '';
  let sub = state.subinfo.sub_info;
  if (sub){
    subscriptionType = sub.subscription_type;
    subscriptionState = sub.subscription_state;
  }

  return {
		params: ownProps,
    subscriptionType: subscriptionType,
    subscriptionState: subscriptionState,
  }
}

export default connect(mapStateToProps,{
  clearSubscrFacture,
createSubscription})(Abonnement);