const ListMonth = {
	arrayM: function(yActiv,yearSelected,mAct) {
		let today = new Date();
		let todayY = parseInt(today.getFullYear(), 10);
		let todayM = parseInt(today.getMonth() + 1, 10);
		let month = []; 
		let k = 0;

		if (yearSelected === yActiv &&  yearSelected !== todayY) {
				for (let i = 1; i <= 12; i++) {
					if(i >= mAct) {
						if (mAct + k === 1) {
							month.push('Janv')
						} else if (mAct + k === 2) {
							month.push('Fevrier')
						} else if (mAct + k === 3) {
							month.push('Mars')
						} else if (mAct + k === 4) {
							month.push('Avril')
						} else if (mAct + k === 5) {
							month.push('Mai')
						} else if (mAct + k === 6) {
							month.push('Juin')
						} else if (mAct + k === 7) {
							month.push('Juillet')
						} else if (mAct + k === 8) {
							month.push('Août')
						} else if (mAct + k === 9) {
							month.push('Septembre')
						} else if (mAct + k === 10) {
							month.push('Octobre')
						} else if (mAct + k === 11) {
							month.push('Novembre')
						} else if (mAct + k === 12) {
							month.push('Decembre')
						}
			            
			            k++;
					}
				}     
		} else if(yearSelected !== yActiv && yearSelected === todayY) {
			for (let i = 1; i < todayM; i++) {
					if (i === 1) {
						month.push('Janv')
					} else if (i === 2) {
						month.push('Fevrier')
					} else if (i === 3) {
						month.push('Mars')
					} else if (i === 4) {
						month.push('Avril')
					} else if (i === 5) {
						month.push('Mai')
					} else if (i === 6) {
						month.push('Juin')
					} else if (i === 7) {
						month.push('Juillet')
					} else if (i === 8) {
						month.push('Août')
					} else if (i === 9) {
						month.push('Septembre')
					} else if (i === 10) {
						month.push('Octobre')
					} else if (i === 11) {
						month.push('Novembre')
					} else if (i === 12) {
						month.push('Decembre')
					}
		            
		            k++;
			}
			
		} else if(yearSelected === yActiv && yearSelected === todayY){
		    for (let i = 1; i < todayM; i++) {
			    if(i >= mAct) {
			        if (mAct + k === 1) {
			          month.push('Janv')
			        } else if (mAct + k === 2) {
			          month.push('Fevrier')
			        } else if (mAct + k === 3) {
			          month.push('Mars')
			        } else if (mAct + k === 4) {
			          month.push('Avril')
			        } else if (mAct + k === 5) {
			          month.push('Mai')
			        } else if (mAct + k === 6) {
			          month.push('Juin')
			        } else if (mAct + k === 7) {
			          month.push('Juillet')
			        } else if (mAct + k === 8) {
			          month.push('Août')
			        } else if (mAct + k === 9) {
			          month.push('Septembre')
			        } else if (mAct + k === 10) {
			          month.push('Octobre')
			        } else if (mAct + k === 11) {
			          month.push('Novembre')
			        } else if (mAct + k === 12) {
			          month.push('Decembre')
			        }
			              
			        k++;
				}
			}

		} else {
			month = ['Janv','Fevrier','Mars','Avril','Mai','Juin','Juillet','Août','Septembre','Octobre','Novembre','Decembre'];
		}

		return month;
	}
}

export default ListMonth;