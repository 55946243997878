import React from 'react';
import MasterCard from '../../assets/images/partner/master-card.png';
import Babyliss from '../../assets/images/partner/babyliss.png';
import Orange from '../../assets/images/partner/orange.png';
import Sncf from '../../assets/images/partner/sncf.png';
import EulerHermes from '../../assets/images/partner/euler-hermes.png';
import Generali from '../../assets/images/partner/generali.png';
import Yoplait from '../../assets/images/partner/yoplait.png';
import Apple from '../../assets/images/partner/apple.png';
import Assurance from '../../assets/images/partner/maladie.png';
import Cmg from '../../assets/images/partner/cmg.png';
import BirdOffice from '../../assets/images/partner/birdoffice.png';
import Solocal from '../../assets/images/partner/solocal.png';


class Partner extends React.Component {

	render(){
		return (
		    	<div className= "container center partner">
		    		<h2 className= "h2">Ces équipes nous font confiance</h2>
		    			<a href= "https://www.pcsmastercard.com/" title="Master card"  target="_blank" rel="noopener noreferrer">
		    				<img alt="Master card" border="0" src={MasterCard} onLoad={this.onLoad} />
		    			</a>
			    		<a href= "https://www.babyliss.fr/" title="Babyliss" target="_blank" rel="noopener noreferrer">
			    			<img alt="Babyliss" border="0" src={Babyliss} onLoad={this.onLoad} />
			    		</a>
		    			<a href = "https://www.orange.fr/" title="Orange" target="_blank" rel="noopener noreferrer">
		    				<img alt="Orange" border="0" src={Orange} onLoad={this.onLoad} />
		    			</a>
		    			<a href = "https://www.sncf.com/" title="Sncf" target="_blank" rel="noopener noreferrer">
		    				<img alt="Sncf" border="0" src={Sncf} onLoad={this.onLoad} /></a>
		    			<a href="https://www.eulerhermes.fr/" title="Euler Hermes" target="_blank" rel="noopener noreferrer">
		    				<img alt="Euler Hermes" border="0" src={EulerHermes} onLoad={this.onLoad} />
		    			</a>
		    			<a href="https://www.generali.fr/" title="Generali" target="_blank" rel="noopener noreferrer">
		    				<img alt="Generali" border="0" src={Generali} onLoad={this.onLoad} />
		    			</a>
		    			<a href="https://www.yoplait.fr/" title="Yoplait" target="_blank" rel="noopener noreferrer">
		    				<img alt="Yoplait" border="0" src={Yoplait} onLoad={this.onLoad} />
		    			</a>
		    			<a href="https://www.apple.com/fr/" title="Apple" target="_blank" rel="noopener noreferrer">
		    				<img alt="Apple" border="0" src={Apple} onLoad={this.onLoad} />
		    			</a>
		    			<a href="https://www.ameli.fr/" title="L'Assurance Maladie" target="_blank" rel="noopener noreferrer">
		    				<img alt="L'Assurance Maladie" border="0" src={Assurance} onLoad={this.onLoad} />
		    			</a>
		    			<a href="https://www.cmgsportsclub.com/" title="CMG sports club" target="_blank" rel="noopener noreferrer">
		    				<img alt="CMG sports club" border="0" src={Cmg} onLoad={this.onLoad} />
		    			</a>
		    			<a href="https://www.bird-office.com/" title="Bird Office" target="_blank" rel="noopener noreferrer">
		    				<img alt="Bird Office" border="0" src={BirdOffice} onLoad={this.onLoad} />
		    			</a>
		    			<a href="https://www.pagesjaunes.fr/" title="Solocal" target="_blank" rel="noopener noreferrer">
		    			<img alt="Solocal" border="0" src={Solocal} onLoad={this.onLoad} />
		    			</a>
		    	</div>
		)
	}
}
export default Partner;