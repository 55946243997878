
import {SUBSCRIPT_FACTURE,CLEAR_SUBSCR_FACTURE,FETCH_FACTURES,CREATE_SUB} from '../actions/actionTypes';

export default function (state = {}, action) {
	switch (action.type) {
        case CREATE_SUB:
      		return { ...state, sub_creation: action.res };
        case SUBSCRIPT_FACTURE:
            return { ...state, sub_info: action.res};
        case FETCH_FACTURES:
        	return {...state, fecth_factures:action.resp};  
        case CLEAR_SUBSCR_FACTURE:  
            return {};    
    default:
        return state;
    }
}