import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {reduxForm,Field} from 'redux-form';
import Spinner from 'react-bootstrap/Spinner';
// import WithEnchancer from '../../enchancers';
import HeaderNoh1 from '../header/headerNoh1';
import Footer from '../footer/footer';
import {getUserConnected} from '../../actions/logAction';

class Login extends Component {
	constructor() {
      super();
      	this.state = {
      		isLoader: false,
    	}
	}	

    componentDidUpdate(prevProps) {
		if (this.props.checkStatus !== prevProps.checkStatus) {
			this.setState({isLoader: false})
		}
	}
    
    handleFormSubmit({email,password}){
    	const obj = {
		    'username':email,
		    'password':password
		  };
		  this.setState({isLoader:true})
    	this.props.getUserConnected(obj, (path) => {
    		this.props.params.history.push(path)
    	})
    };

      renderInput = ({ input, label, type,placeholder, meta: { touched, error,pristine,invalid,submitted }}) => (
      <div className="form-group">
      <label htmlFor={label}>{label}</label>
        <input
          className={ touched && invalid ? "form-control is-invalid" : 'form-control'}
          {...input}
          type={type}
          placeholder={placeholder}
        />
        { touched && error && <span className="error">{error}</span> }
      </div>
    );

	render(){
		const { handleSubmit} = this.props
		return(
			<div>					     					    	
				<HeaderNoh1 />
				{/*eslint-disable */}
				<div className="content">
				    <div className="wrap connexion formulaire">
					    <div className="bloc noborder">
							<h2 className="text-center">Connexion</h2>
							{this.props.status ? (
								<div className="text-danger text-center" role="alert">
  									{this.props.status}
								</div>								
							):(
								<div></div>
							)}
							<form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
					          	<Field name="email" component={this.renderInput} type="text" placeholder="Email" />
					          	<Field name="password" component={this.renderInput} type="password" label="Mot de passe" placeholder="Mot de passe" />
					          	<div className="text-center bottom">
					          		<button type="submit" className="btn bt btBlue" >Se connecter</button><br/>
					          		Vous n'avez pas encore un compte?<br/>
	                                 <Link to="/signup">Inscrivez vous!</Link><br/>
	                                 <Link to="/mot-de-passe-oublie">Mot de passe oublié?</Link>
					          	</div>
					        </form>
					        { this.state.isLoader ? (
					        	<div className="text-center isLoader">
  					     			<Spinner animation="border" variant="info" />
                				</div>
					        ) : 
					        (<div></div>)
					    	}
					        
					    </div>														        					   
		    		</div>
		    	</div>
			    <Footer/>
		    </div>
	  	)
	}
}

function validate(values) {
  const errors = {};

    if (!values.email) {
      errors.email = 'Merci de saisir votre Email.';
    }
    if (typeof values.email !== "undefined") {
        const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(values.email)) {
           errors.email = "Merci de saisir une adresse email valide.";
        }
    }
    if (!values.password) {
      errors.password = 'Merci de saisir votre Mot de passe.';
    }
    return errors;
}

Login = reduxForm({
	form: 'login_form',
	validate: validate
})(Login)

function mapStateToProps(state, ownProps) {
	let a_status = state.login.connected;

	let _st = '';
	let check = '';

	if (a_status) {
		_st = a_status.code === 400 ? a_status.message : '';
		check = a_status.check_status
	}

	return {
		params: ownProps,
		status: _st,
		checkStatus:check 
	}
}

export default connect(mapStateToProps,{getUserConnected})(Login);