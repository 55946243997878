import React  from 'react';

const Legende = () => {
	return(
		<div className="legende mailing">
	   		<span className="legend">Légende de l'état:</span>
	   		<p>
	   		    <span href="#" className="attente"><i className="material-icons-outlined">payment</i> En attente règlement</span>
		   		<span href="#" className="impression"><i className="material-icons-outlined">print</i> Impression en cours</span>
		   		<span href="#" className="affranchissement"><i className="material-icons-outlined">access_time</i> Affranchissement en cours</span>
		   		<span href="#" className="courrier"><i className="material-icons-outlined">check_circle</i> Courrier remis en poste</span>
		   		<span href="#" className="annule"><i className="material-icons-outlined">cancel</i> Annulé</span>
	   		</p>
   		</div>
	);
}

export default Legende;