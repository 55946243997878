import React  from 'react';
//import SousMenu from './sousMenu';
import MenuMobile from './menuMobile';
import {Link} from 'react-router-dom';
import Logo from '../../assets/images/logo.png';
import LogoM from '../../assets/images/logo_medium.png';
import LogoL from '../../assets/images/logo_large.png';
import LogoX from '../../assets/images/logo_xlarge.png';
import {DOC_URL} from '../../config/config';

class NavHeader extends React.Component {
	
	constructor(props) {
    	super(props);
    	this.state = { isHovered: false };
    	this.handleHover = this.handleHover.bind(this);
  	}

    handleHover(){
	    this.setState(prevState => ({ isHovered: !prevState.isHovered }));
	}

  	render() {
  		const btnClass = this.state.isHovered ? "subMenu hovMenu" : "subMenu";
	    return (
	    	    <nav className="nav">
	    	    	<a href="/" className="Logo" title="Service Postal API">
                      <img alt="Service Postal API" border="0" src={Logo} srcSet={`${Logo} 300w, ${LogoM} 768w, ${LogoL} 1280w, ${LogoX} 3200w`} onLoad={this.onLoad} />
                        Service Postal
                        <span className="Logo2">API</span>
                    </a>
				    <MenuMobile/>
			        <ul className="hideMobile">
						<li><Link className="tarif" to="/tarifs" title="Tarifs">Tarifs</Link></li>
						<li><Link to="/faq" title="Foire aux questions">FAQ</Link></li>
		                <li><a href={DOC_URL} title="Documentation" target="_blank" rel="noopener noreferrer">Documentation</a></li>
		                <li className={btnClass} onMouseEnter={this.handleHover} onMouseLeave={this.handleHover}></li>
			        </ul>
		        </nav>
	    	);
  	}
}

export default NavHeader;