import React,{Component}  from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {reduxForm,Field} from 'redux-form';

import HeaderNoh1 from '../header/headerNoh1';
import Footer from '../footer/footer';
import {register} from '../../actions/userAction';
import Constante from '../../constante/constante';
import Loader from '../utils/loader';
import {clearProfile} from '../../actions/userAction';



class Inscription extends Component {
	  constructor(props) {
        super(props);
        this.state = { height: "",isLoader: false};
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidUpdate(prevProps) {
		if (this.props.res !== prevProps.res) {
			this.setState({isLoader: false})
		}
	}

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions.bind(this));
    }

    componentWillUnmount() {
    	this.props.clearProfile();
        window.removeEventListener("resize", this.updateWindowDimensions.bind(this));
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight - 355 + 'px' });
    }

	handleFormSubmit({nom,tel,email,password}){
    	const obj = {
    		'name':nom,
    		'phone':tel,
		    'email':email,
		    'password':password
		};
		 if (password && email && nom && tel) {
		  		this.setState({isLoader: true})
		  }

    	this.props.register(obj, (path) => {
    		this.props.params.history.push(path)
    	})
    };
	// submitted,valid,invalid,touched,pristine
	renderInput = ({ input, label, type,placeholder, meta: { touched, error,pristine,invalid,submitted }}) => (
	    <div className="form-group">
	    <label htmlFor={label}>{label}</label>
	      <input
	        className={ touched && invalid ? "form-control is-invalid" : 'form-control'}
	        {...input}
	        type={type}
	        placeholder={placeholder}
	      />
	      { touched && error && <span className="error">{error}</span> }
	    </div>
    );

	render() {
		const {handleSubmit} = this.props
		return(
			<div>
				<HeaderNoh1 />
				<div className="content relativeLoader">
				{/*eslint-disable */}
				    <div className="wrap connexion formulaire">
					    <div className="bloc noborder">
								<h2 className="text-center">Inscription</h2>
								{this.props.status ? (
									<div className="text-danger text-center" role="alert">
	  									{this.props.status}
									</div>								
								):(
									<div></div>
								)}
								<form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
									<Field name="nom" label="Nom" component={this.renderInput} type="text" placeholder="Nom" required={true} />
									<Field name="tel" label="Téléphone" component={this.renderInput} type="text" placeholder="Téléphone" required={true} />
									<Field name="email" label="Email" component={this.renderInput} type="text" placeholder="Email" required={true} />
									<Field name="password" label="Mot de passe" component={this.renderInput} type="password" placeholder="Mot de passe" required={true} />
								    <div className="text-center bottom">
									    <button 
									    className="btn bt btBlue" 									   
									    >
									    Inscription
									    </button><br/>
		                                 Vous avez déjà un compte ?<br/>
		                                 <Link to="/login">Connectez-vous !</Link><br/><br/>
								    </div>
								    <Loader isLoader={this.state.isLoader} />
								</form>						
					    </div>
					</div>
			    </div>
			    <Footer/>
		</div>
	  	)
	}
}

function validate(values) {
	const errors = {};

	if (!values.nom) {
    	errors.nom = 'Merci de saisir votre nom.';
  	}

  	if (typeof values.nom !== "undefined") {
        if (!values.nom.match(/^[a-zA-Z\u00C0-\u00ff ]*$/)) {

          errors.nom = "Merci de saisir des caractères alphabet.";
        }
      }

  	if (!values.tel) {
    	errors.tel = 'Merci de saisir votre numero téléphone.';
  	}

  	if (typeof values.tel !== "undefined") {
        if (!values.tel.match(/^[0-9]{10}$/)) {
          errors.tel = "*Veuillez entrer un numero téléphone valide.";
        }
    }

  	if (!values.email) {
    	errors.email = 'Merci de saisir votre Email.';
  	}

  	if (typeof values.email !== "undefined") {
        const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        

        if (!pattern.test(values.email)) {
           errors.email = "Merci de saisir une adresse email valide.";
        }
    }

  	if (!values.password) {
    	errors.password = 'Merci de saisir votre Mot de passe.';
  	}

    return errors;
}

Inscription = reduxForm({
	form: 'register_form',
	validate: validate
})(Inscription)

function mapStateToProps(state, ownProps) {
	let a_status = state.user.register;
	let _st = ''
	let message = ''

	if (a_status) {
		_st = a_status.code === 400 ? Constante.userExiste : ''
		message = a_status.message
	}

	return {
		params: ownProps,
		status: _st,
		res:message 
	}
}

export default connect(mapStateToProps,{register,clearProfile})(Inscription);