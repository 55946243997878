import React  from 'react';
import Spinner from 'react-bootstrap/Spinner';

const Loader = ({isLoader}) => {
	if (isLoader) {
		return(
			<div className="loader text-center">
			     	<Spinner animation="border" variant="info" />
    		</div>
		);
	} else {
		return(<div></div>)
	}
}

export default Loader;