import React  from 'react';
import '../../assets/css/surchargemirror.css';
import {connect} from 'react-redux';
import {reduxForm /*Field*/} from 'redux-form';
//import Popup from 'reactjs-popup';
import {contactSend} from '../../actions/contactAction';

import LogoApi from '../../assets/images/logo_large.png';
import LogoSP from '../../assets/images/logo-sp.png';
import LogoPP from '../../assets/images/logo-publipostage.svg';
import LogoOrange from '../../assets/images/orange.png';

//import YouAreHere from '../../assets/images/YouAreHere.png';

class Detailapi extends React.Component {
	
	constructor(props) {
    	super(props);
    	this.state = { 
    		currentSrc: '',
    		open: false,
    		fields: {},
            errors: {},
            invisible: false
    	};
    	

    	this.openModal = this.openModal.bind(this)
        this.closeModal = this.closeModal.bind(this)
  	}

    handleFormSubmit({nom,societe,tel,errors,toggle}){
    	const obj = {
            'nom': nom,
            'societe':societe,
            'tel':tel,
            'errors' : errors
        }
     	if(obj.nom!=='' & obj.tel!=='' & obj.errors !=='undefined'){
     		this.toggle(this.setState({addClass: !this.state.addClass}));
     	}
	    this.props.contactSend(obj);
	    this.setState({invisible: true })
	    setTimeout(() => {
			this.setState({open: false })
			this.setState({invisible: false })
		}, 2000)
    }
    toggle(params){
	   	return params
	}	
  	openModal (){
    	this.setState({ open: true })
    }
    closeModal () {
        this.setState({ open: false })
    }
    renderInput = ({ input, label, type,placeholder, meta: { touched, error,pristine,invalid,submitted }}) => (
      <div className="form-group">
      <label htmlFor={label}>{label}</label>
        <input
          className={ touched && invalid ? "form-control is-invalid" : 'form-control'}
          {...input}
          type={type}
          placeholder={placeholder}
        />
        { touched && error && <span className="error">{error}</span> }
      </div>
    );

  	render() {
  		//const {handleSubmit} = this.props
  		let showSuccess = ["hideSuccess"];            
        if(this.state.addClass) {
          showSuccess.push('showSuccess');
    	}
	    return (	    	
		<div className="wrap intro">
	      {/*eslint-disable */}
			<div className="bloc bg">
				<h3 className="api">Principales fonctionnalités</h3>
				<h4>Envoi de courriers <b>unitaires</b> ou <b>en nombre</b></h4>
				<h4>Large choix d'options et d'affranchissements</h4>
				<h4>Suivi des envois en temps réel - API événementielle (<b>webhook</b>)</h4>
				<h4>Récupération des <b>preuves de dépôt</b> et de <b>distribution</b></h4>
				<h4>Accusés de réception <b>physiques</b> ou <b>dématérialisés</b></h4>
				<hr className='hr2' />
				<h3 className="api">Nos atouts</h3>
				<h4>Meilleurs tarifs, exemple pour un <b>EcoPli N&B, 1 page</b> : <span className="eco-price">0.89 €</span></h4>
				{/* <h4>Inutile de s'abonner pour essayer notre API, votre clé d'API de sandbox est transmise <b>dès l'inscription</b></h4> */}
				<h4>Architecture cloud &#9729; <b>scalable</b> et <a style={{fontSize:"18px", fontFamily:"Arial", fontWeight:"bold"}} href='https://api-status.servicepostal.com/' target='_blank'><u>haute disponibilité</u></a> (24h/24, 7j/7)</h4>
				<h4>Tous les courriers soumis avant <b>17h30</b> sont déposés <b>le soir même</b>&#9200;dans le circuit de distribution de <b>La Poste</b></h4>
				{/* <h4><a style={{fontSize:"18px", fontFamily:"Arial", fontWeight:"bold"}} href='https://docs.servicepostal.com/' target='_blank'>Documentation</a> détaillée et à jour, avec de nombreux exemples de code</h4> */}
				<h4>Support technique <b>à votre écoute</b>, prêt à vous assister dans vos intégrations</h4>
				<hr className='hr2' />
				<h3 className="api">Ecosystème Service Postal</h3>
				<div className='tableEcosysteme'>					
					<div className="grid-elt">
						<div className="left">
							<img src={LogoApi} width="65" height="46"/>
						</div>
						<div className="right">
							<h4>API d'envoi et de suivi de courrier matérialisé, égrené et en nombre</h4>
							<a className='a2' href="#" target="_blank">https://<strong>api</strong>.servicepostal.com</a>
							{/* <img src={YouAreHere} width="50" /> (vous êtes ici) */}
						</div>
					</div>
					<div className="grid-elt">
					
						<div className="left">
							<img src={LogoSP} width="50" />
						</div >
						<div className="right">
							<h4>Envoi de courrier unitaire</h4>
							<a className='a2' href="https://www.servicepostal.com" target="_blank" >https://<strong>www</strong>.servicepostal.com</a>
						</div>
					</div>
					<div className="grid-elt">
						<div className="left">
							<img src={LogoPP} width="50" />
						</div>						
						<div className="right">
							<h4>Envoi de courrier en nombre</h4>
							<a className='a2' href="https://publipostage.servicepostal.com" target="_blank">https://<strong>publipostage</strong>.servicepostal.com</a>
						</div>
						
					</div>
					<div className="grid-elt">
						<div className="left">
							<img src={LogoOrange} width="50" />							
						</div>
						<div className="noborder right">
							<h4>SaaS dédié aux clients Orange
							( partenariat <a href="https://assistancepro.orange.fr/internet_livebox/le_cloud_pro/applications_du_cloud/service_postal__envoyer_un_courrier_-394475" target="_blank"><strong><span>Cloud Pro</span></strong></a> )</h4>
							<a className='a2' href="https://orange.servicepostal.com" target="_blank">https://<strong>orange</strong>.servicepostal.com</a>
						</div>						
					</div>
					{/* https://assistancepro.orange.fr/internet_livebox/le_cloud_pro/applications_du_cloud/service_postal__envoyer_un_courrier_-394475 */}					
					
				</div>
				<hr className='hr2' />
				<h4>Acteur depuis <b>2014</b> dans le domaine du courrier en ligne - Des <strong>millions</strong> de courriers émis chaque année</h4>
			</div>	
		</div>
			);
  	}
}

function validate(values) {
	const errors = {};
	if (!values.nom) {
      errors.nom = 'Merci de saisir votre nom.';
    }
    if (typeof values.nom !== "undefined") {
        if (!values.nom.match(/^[a-zA-Z ]*$/)) {
          errors.nom = "Merci de saisir des caractères alphabet.";
        }
    }

	if (!values.tel) {
		errors.tel = "Merci de saisir votre numero téléphone.";
	}

	if (typeof values.tel !== "undefined") {
		if (!values.tel.match(/^[0-9]{10}$/)) {
	  		errors.tel = "*Veuillez entrer un numero téléphone valide.";
		}
	}
	return errors;
}

Detailapi = reduxForm({
  form: 'call_form',
  validate: validate,
})(Detailapi)

export default connect(null,{contactSend})(Detailapi);