import React, { Component }  from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';

import HeaderNoh1 from '../header/headerNoh1';
import Footer from '../footer/footer';
import Dashboard from './dashboard';
import '../../assets/css/header.css';
import {updateProfile } from '../../actions/userAction';
import {subscritiptionFacture,clearSubscrFacture} from '../../actions/factureAction';


class Profil extends Component {
	constructor(props) {
		super();
		this.state = {
      		isLabel: false,
      		isEmail:false,
      		isPwd:false,
      		isPhone:false,
      	}
	}

	componentDidMount() {
	  this.props.subscritiptionFacture();
	}

	componentWillUnmount() {
		this.props.clearSubscrFacture();
	}
	
	handleFormSubmit({nom}){
    	const obj = {
    		'label':nom,
    		'firstname':'',
    		'phone':'',
		    'email':'',
		    'password':''
		  };
		this.setState({isLabel:true});
    	this.props.updateProfile(obj)
    };

    handleFormSubmitEmail({emailnew}){
    	const obj = {
    		'label':'',
    		'firstname':'',
    		'phone':'',
		    'email':emailnew,
		    'password':''
		  };
		this.setState({isEmail:true});
    	this.props.updateProfile(obj);
    };

    handleFormSubmitTel({tel}){
    	const obj = {
    		'label':'',
    		'firstname':'',
    		'phone':tel,
		    'email':'',
		    'password':''
		  };
		this.setState({isPhone:true});
    	this.props.updateProfile(obj)
    };

    handleFormSubmitPwd({passwordnew}){
    	const obj = {
    		'label':'',
    		'firstname':'',
    		'phone':'',
		    'email':'',
		    'password':passwordnew
		  };
		this.setState({isPwd:true});
    	this.props.updateProfile(obj, (path) => {
    		this.props.params.history.push(path)
    	})
    };

	renderInput = (field) => (
	    <div className="form-group">
	      <input
	        className="form-control"
	        {...field.input}
	        type={field.type}
	        placeholder={field.placeholder}
	        readOnly={field.readOnly}
	        required={field.required? 'required' : ''}
	        disabled={field.disabled? 'disabled' : ''}
	      />
	      <span className="icon"></span>
	    </div>
    );

	render(){
		const { handleSubmit} = this.props
		let subscriptionType = this.props.subscriptionType;
		// let subscriptionName = this.props.subscriptionName;
		return(
			<div className="connected connectedPage">
	          	<HeaderNoh1/>
	          	<div className="content tabs">
	          		<div className="wrap">
	          			<Dashboard />
	    				<div className="bloc parametre">
	    		        	{/*eslint-disable */}
	    		        	<div className="top">
								<h2 className ="fl-l">Bienvenue <strong>{this.props.initial}</strong></h2>
								<p className ="fl-r mt20">
					
								ABONNEMENT {subscriptionType}
								{/*<a href="#">Acheter un pack et profitez de réductions jusqu'à -10% !</a>*/}
								</p>
							</div>
							<div className="blocArrondi">
								<div className="elt">
								    <p className="title">INFORMATIONS D'IDENTIFICATION</p>
									<div className="childBloc">
									  <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
										    <label htmlFor="nom" className="nom"><i className="material-icons-outlined">label</i>LABEL DU COMPTE</label>
											<Field name="nom" label="Nom" component={this.renderInput} type="text" required={true} placeholder="Label du compte"/>
											{this.state.isLabel ? <div style={{color: '#12b47d', fontSize: '12px', fontFamily: 'Montserrat, Arial, Helvetica, Sans-Serif', marginTop: '-9px', marginBottom: '7px'}}>Le label du compte a été changé</div> : null}
										    <button 
										    className="btn bt btBlue" 
										    >
										    Sauvegarder
										    </button>
										</form>
										<form onSubmit={handleSubmit(this.handleFormSubmitEmail.bind(this))}>
										    <label htmlFor="email" className="email"><i className="material-icons-outlined">email</i> ADRESSE EMAIL</label>
												<Field name="email" label="Email" component={this.renderInput} type="text" readOnly={true} placeholder="Adresse Email" />
												<Field name="emailnew" label="Email" component={this.renderInput} type="text" placeholder="Nouvelle adresse Email" required={true} />
										    {this.state.isEmail ? <div style={{color: '#12b47d', fontSize: '12px', fontFamily: 'Montserrat, Arial, Helvetica, Sans-Serif', marginTop: '-9px', marginBottom: '7px'}}>L'email du compte a été changé</div> : null}
										    <button 
										    className="btn bt btBlue" 
										    >
										    Sauvegarder
										    </button>
										</form>
									</div>
									<div className="childBloc">
										<form onSubmit={handleSubmit(this.handleFormSubmitTel.bind(this))}>
										    <label htmlFor="tel" className="tel"><i className="material-icons-outlined">phone_iphone</i>TÉLÉPHONE</label>
											<Field name="tel" label="Téléphone" component={this.renderInput} type="text" required={true} placeholder="Numéro de téléphone" />
										    {this.state.isPhone ? <div style={{color: '#12b47d', fontSize: '12px', fontFamily: 'Montserrat, Arial, Helvetica, Sans-Serif', marginTop: '-9px', marginBottom: '7px'}}>Le téléphone du compte a été changé</div> : null}
										    <button 
										    className="btn bt btBlue" 
										    >
										    Sauvegarder
										    </button>
										</form>
										
										<form onSubmit={handleSubmit(this.handleFormSubmitPwd.bind(this))}>
										    <label htmlFor="password" className="password"><i className="material-icons-outlined">lock</i>Mot de passe</label>
											<Field name="password" label="Mot de passe" component={this.renderInput} type="password" placeholder="Mot de passe actuel" />
											<Field name="passwordnew" label="Nouveau mot de passe" component={this.renderInput} type="password" placeholder="Nouveau mot de passe" required={true} />
											{this.state.isPwd ? <div style={{color: '#12b47d', fontSize: '12px', fontFamily: 'Montserrat, Arial, Helvetica, Sans-Serif', marginTop: '-9px', marginBottom: '7px'}}>Le mot de passe du compte a été changé</div> : null}
											<button 
										    className="btn bt btBlue" 
										    >
										    Sauvegarder
											</button>
										</form>
									</div>
								</div>
							</div>							    										   
		        	</div>
		        </div>
			</div>
			<Footer/>
			</div>
		)
	}
}

Profil = reduxForm({
  form: 'profil'
})(Profil);

function mapStateToProps(state, ownProps) {
	let a_status = state.login.connected;
	let subscriptionType = '';
	let subscriptionName = '';
	let sub = state.subinfo.sub_info;
	let _st = '';
	let obj = '';
	// let isEdit = false;
// console.log('conn',a_status)
	if (a_status) {
		if (a_status.code !== 200) {
			_st = a_status.message
		} else {
			_st = a_status.data.firstname ? a_status.data.firstname +' '+a_status.data.name : ''
			obj = a_status.data
			// isEdit = a_status.data.is_edit
		}
	}
	
	if (sub){
		subscriptionType = sub.subscription_type;
		subscriptionName = sub.subscription_name;
	}

	return {
		params: ownProps,
		initial: _st,
		// isEdit:isEdit,
		initialValues: {
			nom:obj.label,
			email:obj.email,
			tel:obj.phone,
		},
		enableReinitialize: true,
		subscriptionType: subscriptionType,
		subscriptionName:subscriptionName,
	}
}

export default connect(mapStateToProps, {updateProfile,subscritiptionFacture,clearSubscrFacture })(Profil);
