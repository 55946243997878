import React  from 'react';
import Popup from 'reactjs-popup';

class TarifColors extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {addClass: false}
    this.state = { 
        currentSrc: '',
        open: false,
        fields: {},
            errors: {}
    }
    
  }
  handleChange(e) {
      let fields = this.state.fields;
      fields[e.target.name] = e.target.value;
      this.setState({
        fields
      });

  }
  submituservalidationForm(e) {
    e.preventDefault();
    if (this.validateForm()) {
        let fields = {};
        fields["nom"] = "";
        fields["telephone"] = "";
        this.setState({fields:fields});
    }
  }
  validateForm(value) {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["nom"]) {
      formIsValid = false;
      errors["nom"] = "Merci de saisir votre nom.";
    }
    if (typeof fields["nom"] !== "undefined") {
      if (!fields["nom"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["nom"] = "Merci de saisir des caractères alphabet.";
      }
    }
    if (!fields["telephone"]) {
      formIsValid = false;
      errors["telephone"] = "Merci de saisir votre numero téléphone.";
    }
    if (typeof fields["telephone"] !== "undefined") {
      if (!fields["telephone"].match(/^[0-9]{10}$/)) {
        formIsValid = false;
        errors["telephone"] = "*Veuillez entrer un numero téléphone valide.";
      }
    }
    this.setState({
      errors: errors
    });
      return formIsValid;
  }
  openModal (){
      this.setState({ open: true })
  }
  closeModal () {
        this.setState({ open: false })
  }
  toggle() {
    this.setState({addClass: !this.state.addClass});
  }
  render() {
    let btnBlack = ["border"],
        btnYellow = ["border"]
    if(this.state.addClass) {
      btnBlack.push('black');
    }
    if(this.state.addClass) {
      btnYellow.push('yellow');
    }
   
    return (        
           <div>
            <div className="overflowX">
                <div className="overflow">
                    <div className="consumption bgBlue">
                        <div className="nbCourrier month">
                            <p>
                                Consommation mensuelle<br/>
                                Abonnement <b>API Volume</b>
                            </p>
                        </div>
                        <div className="nbCourrier">
                            <p>de 0 à 100 courriers
                                <br/>
                                <span className="titl">&nbsp;</span></p>
                        </div>
                        <div className="nbCourrier">
                            <p>+ de 100 courriers
                                <br/>
                                <span className="titl">(remise 5%)</span></p>
                        </div>
                        <div className="nbCourrier">
                            <p>+ de 1000 courriers
                                <br/>
                                <span className="titl">(remise 10%)</span>
                            </p>
                        </div>
                    </div>
                    <div className="consumption priority">
                        <div className="nbCourrier month">
                            <p>EcoPli J+4 <span>(Temp Post G4 France)</span></p>
                        </div>
                        <div className="nbCourrier">
                            <Popup trigger={<p>1,302 € / courrier</p>} position="right top" on="hover" closeOnDocumentClick mouseLeaveDelay={300} mouseEnterDelay={0} contentStyle={{ padding: "0px", border: "none" }} arrow={false} >
                                <div className="detail">
                                    <p>Affranchissement : 0,662 €</p>
                                    <p>Service : 0,64 €</p>
                                    <p>Total : 1,302 €</p>
                                </div>
                            </Popup>
                        </div>
                        <div className="nbCourrier">
                            <Popup trigger={<p>1,272 € / courrier</p>} position="right top" on="hover" closeOnDocumentClick mouseLeaveDelay={300} mouseEnterDelay={0} contentStyle={{ padding: "0px", border: "none" }} arrow={false} >
                                <div className="detail">
                                    <p>Affranchissement : 0,662 €</p>
                                    <p>Service : 0,61 €</p>
                                    <p>Total : 1,272 €</p>
                                </div>
                            </Popup>
                        </div>
                        <div className="nbCourrier">
                           <Popup trigger={<p>1,242 € / courrier</p>} position="right top" on="hover" closeOnDocumentClick mouseLeaveDelay={300} mouseEnterDelay={0} contentStyle={{ padding: "0px", border: "none" }} arrow={false} >
                                <div className="detail">
                                    <p>Affranchissement : 0,662 €</p>
                                    <p>Service : 0,58 €</p>
                                    <p>Total : 1,242 €</p>
                                </div>
                            </Popup>
                        </div>
                        <div className="nbCourrier month marg">
                            <p>Recommandé avec AR</p>
                        </div>
                        <div className="nbCourrier">
                             <Popup trigger={<p>8,24 € / courrier</p>} position="right top" on="hover" closeOnDocumentClick mouseLeaveDelay={300} mouseEnterDelay={0} contentStyle={{ padding: "0px", border: "none" }} arrow={false} >
                                <div className="detail">
                                    <p>Affranchissement : 6,09 €</p>
                                    <p>Service 2,15 €</p>
                                    <p>Total : 8,24 €</p>
                                </div>
                            </Popup>
                        </div>
                        <div className="nbCourrier">
                            <Popup trigger={<p>8,13 € / courrier</p>} position="right top" on="hover" closeOnDocumentClick mouseLeaveDelay={300} mouseEnterDelay={0} contentStyle={{ padding: "0px", border: "none" }} arrow={false} >
                                <div className="detail">
                                    <p>Affranchissement : 6,09 €</p>
                                    <p>Service : 2,04 €</p>
                                    <p>Total : 8,13 €</p>
                                </div>
                            </Popup>
                        </div>
                        <div className="nbCourrier">
                           <Popup trigger={<p>8,025 € / courrier</p>} position="right top" on="hover" closeOnDocumentClick mouseLeaveDelay={300} mouseEnterDelay={0} contentStyle={{ padding: "0px", border: "none" }} arrow={false} >
                                <div className="detail">
                                    <p>Affranchissement : 6,09 €</p>
                                    <p>Service : 1,935 €</p>
                                    <p>Total : 8,025 €</p>
                                </div>
                            </Popup>
                        </div>
                    </div>
                    <div className="consumption option">
                        <strong>Options</strong>
                        <div className="nbCourrier">
                            <p>Page supplémentaire</p>
                        </div>
                        <div className="nbCourrier">
                            <p>0,46 € / courrier</p>
                        </div>
                        <div className="nbCourrier">
                            <p>0,44 € / courrier</p>
                        </div>
                        <div className="nbCourrier noborder">
                            <p>0,41 € / courrier</p>
                        </div>
                    </div>
                    <div className="consumption option noMarge">
                        <div className="nbCourrier">
                            <p>Gestion éléctronique des AR</p>
                        </div>
                        <div className="nbCourrier">
                            <p>0,66 € / courrier</p>
                        </div>
                        <div className="nbCourrier">
                            <p>0,63 € / courrier</p>
                        </div>
                        <div className="nbCourrier noborder">
                            <p>0,59 € / courrier</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      );
    }
}
export default TarifColors;