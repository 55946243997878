import React  from 'react';
import { connect } from 'react-redux';
import DatePicker,{registerLocale} from "react-datepicker";
import 'rc-pagination/assets/index.css';
import localeInfo from 'rc-pagination/lib/locale/fr_FR';
import Pagination from 'rc-pagination';
import Spinner from 'react-bootstrap/Spinner';
import "react-datepicker/dist/react-datepicker.css";
import fr from 'date-fns/locale/fr';

import List from './list';
import Loader from './loader';
import {fetchEnvoiesUnitaires,fetchByDate} from '../../actions/envoiesActions';
registerLocale('fr', fr);

/*const itemRender = (current, type, element) => {
  if (type === 'page') {
    return <a href={`#${current}`}>{current}</a>;
  }
  return element;
};*/

const textItemRender = (current, type, element) => {
  if (type === 'prev') {
    return 'Prev';
  }
  if (type === 'next') {
    return 'Next';
  }
  return element;
};


class Historik extends React.Component {
	constructor() {
      super();
      this.state = {
      	startDate: '',
      	obj:[],
      	currentPage: 1,
      	rowsByPage: 10,
      	totalPages: 1,
      	firstIndex: 1,
      	lastIndex: 10,
      	searchVal: '',
      	type:false,
      	ref:false,
      	adresse:false,
      	isLoader: false,
      	isArchived: true,
      	isSpinner:false,
		isChecked1: true,
		isChecked2: true,
		isChecked3: true,
		isChecked4: true
      }
      this.handleChange = this.handleChange.bind(this);
      this.handleChangeDate = this.handleChangeDate.bind(this);
      this.resetCrt = this.resetCrt.bind(this);
      // this.handleInputChange = this.handleInputChange.bind(this);
      this.handleSearch = this.handleSearch.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleInputChangeName = this.handleInputChangeName.bind(this);
      this.handleInputChangeAdress = this.handleInputChangeAdress.bind(this);
      this.handleInputChangeRef = this.handleInputChangeRef.bind(this);
      this.handleDateSend = this.handleDateSend.bind(this);
      this.handleList = this.handleList.bind(this);
      this.handleArchived = this.handleArchived.bind(this);
	  this.handleCheck1 = this.handleCheck1.bind(this);
	  this.handleCheck2 = this.handleCheck2.bind(this);
	  this.handleCheck3 = this.handleCheck3.bind(this);
	  this.handleCheck4 = this.handleCheck4.bind(this);
    };

    componentDidMount() {
	   this.props.fetchEnvoiesUnitaires();
   }

	componentDidUpdate(prevProps) {
		if (this.props.initial !== prevProps.initial) {
			this.setState({isSpinner: false})
			this.setState({isLoader: false})
		}

		if (this.props.listByDate !== prevProps.listByDate) {
			this.setState({isSpinner: false})
			this.setState({isLoader: false})
		}
	}

	/*componentWillUnmount() {
    	this.props.clearData(); 
  	}*/

	handleChange(current, pageSize) {
		const {rowsByPage } = this.state;

		if (this.props.initial && current) {
			const pageLast = current * rowsByPage;
			const pageFirst = pageLast - rowsByPage;

			var indexFirst = 1;
			var indexLast = current * rowsByPage;
			// const numberrows = this.state.obj.length;
			if(current > 1) {
				indexFirst = ((current - 1) * rowsByPage) + 1;
			}
			if(indexLast > this.props.initial.length) {
				indexLast = this.props.initial.length;
			}

			const newData = this.props.initial.slice(pageFirst, pageLast);
			this.setState({obj:newData})
			this.setState({firstIndex:indexFirst})
			this.setState({lastIndex:indexLast})
			this.setState({currentPage:current})
		}
	}

	handleChangeDate(date){
		this.setState({
      		startDate: date
    	});	
	}

	handleDateSend(e){
		const date = this.state.startDate;
		const day = date.getDate();
      	const month = date.getMonth();
      	const year = date.getFullYear();
      	const time = `${year}-${month + 1}-${day}`;
		const obj = {'date':time};
		this.props.fetchByDate(obj);
		this.setState({isLoader:true})
	}

	resetCrt(){
		if (this.state.startDate || this.state.searchVal) {
			window.location.reload(true)
		}
		// this.props.fetchEnvoiesUnitaires();
	}

	handleInputChangeName(event) {
    	if (!this.state.type) {
    		this.setState({type: true});
    	} else {
    		this.setState({type: false});
    	}
	}

	handleInputChangeAdress(event) {
    	if (!this.state.adresse) {
    		this.setState({adresse: true});
    	} else {
    		this.setState({adresse: false});
    	}
    	
	}

	handleInputChangeRef(event) {
    	if (!this.state.ref) {
    		this.setState({ref: true});
    	} else {
    		this.setState({ref: false});
    	}
    	
	}

	handleSubmit(event){

		if (this.state.searchVal) {
			const search = {
				'nom':this.state.type,
				'adresse':this.state.adresse,
				'ref':this.state.ref,
				'val':this.state.searchVal
			}
			this.props.fetchByDate(search);
			this.setState({isLoader:true})
		}
		
	}

	handleSearch(event){
		this.setState({searchVal: event.target.value});
	}

	handleList(event) {
		const page = parseInt(event.target.value);
		this.setState({rowsByPage:page});

		var {current } = this.state;
		var indexFirst = 1;
		var indexLast = 10;

		if(current) {
			indexLast = current * page;
		}
		else{
			indexLast = this.props.initial.length
			current = 1;
			this.setState({current:current});
		}
		if(indexLast > this.props.initial.length) {
			indexLast = this.props.initial.length;
		}

		if(current > 1) {
			indexFirst = ((current - 1) * page) + 1;
		}
		else {
			indexFirst = 1;
		}

		this.setState({firstIndex:indexFirst})
		this.setState({lastIndex:indexLast})
		this.setState({currentPage:current})
	}

	handleArchived(e){
		const checkArch = document.getElementById("archive");
		this.setState({isArchived: checkArch.checked});
    	const obj = {
    		"isArchived":checkArch.checked
    	}

    	this.setState({isSpinner:true})
    	this.props.fetchByDate(obj);
	}
	handleCheck1(){
		if (this.state.isChecked1){
			this.setState({isChecked1:false})
		}
		else{
			this.setState({isChecked1:true})
		}
	}
	handleCheck2(){
		if (this.state.isChecked2){
			this.setState({isChecked2:false})
		}
		else{
			this.setState({isChecked2:true})
		}
	}
	handleCheck3(){
		if (this.state.isChecked3){
			this.setState({isChecked3:false})
		}
		else{
			this.setState({isChecked3:true})
		}
	}
	handleCheck4(){
		if (this.state.isChecked4){
			this.setState({isChecked4:false})
		}
		else{
			this.setState({isChecked4:true})
		}
	}
	render() {
	    const currentTodos = this.props.initial? this.props.initial.slice(0, this.state.rowsByPage):'';
	    let data;

	    if ((this.props.initial && this.state.obj.length === 0)) {
		// || this.state.currentPage === 1) {
	    	data = currentTodos
	    } else {
	    	data = this.state.obj
	    }

		return(
			<div className="blocArrondi plr-30 unitaire">
			{/*eslint-disable */}
			    <div className="elt">
				        <div className="fl-l radio checkbox">
                            <div className="custom-control">
                              <input name="nom" type="checkbox" id="nom" defaultChecked={!this.state.type} className="custom-control-input" onChange={this.handleInputChangeName} />
                              <label title="" type="checkbox" htmlFor="nom" className="custom-control-label" onClick={this.handleCheck1}><i className="material-icons-outlined">{this.state.isChecked1 ? "check_box" : "check_box_outline_blank"}</i> dans son nom</label>
                            </div>
                            <div className="custom-control">
                              <input name="adresse" type="checkbox" id="adresse" defaultChecked={!this.state.adresse} className="custom-control-input" onChange={this.handleInputChangeAdress} />
							  <label title="" type="checkbox" htmlFor="adresse" className="custom-control-label" onClick={this.handleCheck2}><i className="material-icons-outlined">{this.state.isChecked2 ? "check_box" : "check_box_outline_blank"}</i> dans son adresse</label>
                            </div>
                            <div className="custom-control">
                              <input name="reference" type="checkbox" id="reference" defaultChecked={!this.state.ref} className="custom-control-input" onChange={this.handleInputChangeRef} />
							  <label title="" type="checkbox" htmlFor="reference" className="custom-control-label" onClick={this.handleCheck3}><i className="material-icons-outlined">{this.state.isChecked3 ? "check_box" : "check_box_outline_blank"}</i> dans sa référence</label>
                            </div>
                      	</div>
                      	<Loader isLoader={this.state.isLoader} />
                      	<div className="fl-r">                      	
					        <div className="fieldGroup">
							   <div className="form-group">
							     <input className="form-control" name="search" type="text" placeholder="Rechercher ..." onChange={this.handleSearch} />
							   </div>
							   <button className="btBlue btn radius search" onClick={this.handleSubmit} type="button">&nbsp;</button>
							</div>							
							<div className="fieldGroup">
							   <div className="form-group">
							     <DatePicker
							     	selected={this.state.startDate}
							     	dateFormat="dd/MM/yyyy"
								   	onChange={this.handleChangeDate}
								   	placeholder="Rechercher par date"
								   	className="form-control"
								   	locale="fr"
			                     />
							   </div>
							   <button className="btBlue btn radius search" onClick={this.handleDateSend} type="button">&nbsp;</button>
							</div>
							<button className="bt btBlue" onClick={this.resetCrt} type="button">Effacer les critères de séléction</button>
				   		</div>
				   <List data={data} archive={this.state.isArchived} />
		           {
		             this.props.initial && this.props.initial.length === 0 ?
		             (<div className="fullcase tbody">
		                    <p className="">Aucune donnée !</p>
		             </div>):(<div></div>)
		           }
				   {!this.props.initial || this.state.isSpinner ?
				   	(
  			   		 	<div className="text-center">
  					     	<Spinner animation="border" variant="info" />
                		</div>
				   	) : (<div></div>)
				   }

				   <div className="fl-r radio checkbox">
                        <div className="custom-control">
                          <input name="archive" type="checkbox" id="archive" className="custom-control-input" defaultChecked={this.state.isArchived} onChange={this.handleArchived} />
                          <label title="" type="checkbox" htmlFor="archive" className="custom-control-label" onClick={this.handleCheck4}><i className="material-icons-outlined">{this.state.isChecked4 ? "check_box" : "check_box_outline_blank"}</i> Afficher les envois archivés</label>
                        </div>
                  	</div>
                  	{this.props.initial && this.props.initial.length > 0 ?
				   		(
		                  	<div className="childBloc fl-r">
		                  	    {/* <p>firstIndex:{this.state.firstIndex} - lastIndex:{this.state.lastIndex} - rowsByPage:{this.state.rowsByPage} - currentpage:{this.state.currentPage} - totalPages:{this.state.totalPages}</p> */}
		                  	    <p>Affiche {this.state.firstIndex} à {this.state.lastIndex}</p>
		                  		<div className="form-group">
		                  			<label htmlFor="annee" className="labelShow">Nombre d'enregistrement: </label>
		                  			<select name="annee" className="select-w70" onChange={this.handleList}>
		                  				<option value="10">10</option>
		                  				<option value="25">25</option>
		                  				<option value="50">50</option>
		                  				<option value="100">100</option>
		                  				<option value="250">250</option>
		                  				<option value="500">500</option>
		                  			</select>
		                  		</div>
		                  	</div>
	                 ):(
				   			<div></div>
				   		)
				   	}
                  	{this.props.initial && this.props.initial.length > 0 ?
				   		(
			   			 	<div className="BlocPagination">
					        	<Pagination 
					        		total={this.props.initial.length} 
					        		locale={localeInfo} 
					        		onChange={this.handleChange} 
					        		itemRender={textItemRender}
					        		pageSize={this.state.rowsByPage} 
					        	/>
					        </div>
				   		):(
				   			<div></div>
				   		)
				   	}

				   <div className="legende">
				   		<span className="legend">Légende de l'état:</span>
				   		<p>
							<span href="#" className="attente"><i className="material-icons-outlined">payment</i> En attente règlement</span>
							<span href="#" className="impression"><i className="material-icons-outlined">print</i> Impression en cours</span>
							<span href="#" className="affranchissement"><i className="material-icons-outlined">access_time</i> Affranchissement en cours</span>
							<span href="#" className="courrier"><i className="material-icons-outlined">check_circle</i> Courrier remis en poste</span>
							<span href="#" className="annule"><i className="material-icons-outlined">cancel</i> Annulé</span>
				   		</p>
				   		</div>
			   </div>
			</div>
		)
	}
}


function mapStateToProps(state, ownProps) {
	let a_status = state.envoies.list_unitaire;
	let list_date //= state.envoies.list_by_date;
	// let update_archived  = state.envoies.update_unitiares;
	let _st;
 // console.log(a_status)
	if (a_status) {
		if (a_status.code === 200) {
		    _st = a_status.data
		} else {
			_st = ''
		}
	}

	if (list_date) {
		_st = list_date.data;
	}

	/*if (update_archived) {
		_st = update_archived.data;
	}*/

	return {
		initial: _st ? _st : '',
		listByDate: list_date ? list_date.data: '',
	}
}

export default connect(mapStateToProps, {fetchEnvoiesUnitaires,fetchByDate})(Historik);
