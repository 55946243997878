import React  from 'react';

const Verso = ({element}) => {
	if (element.recto_verso === 1) {
			return(
				<span>
					<i className="hideMob"><img alt="Recto Verso" border="0" width="16" height="18" src={require('../../../assets/images/icones/recto-verso.png')}/></i>
					<span className="mobVersion">Recto Verso</span>
				</span>					
			);
		} else {
			return(
				<span>
					<i className="hideMob"><img alt="Recto Verso" border="0" src={require('../../../assets/images/icones/rectobck.png')}/></i>
					<span className="mobVersion">Recto</span>
				</span>					
			);
	}
}

export default Verso;