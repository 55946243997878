import React  from 'react';
import 'rc-collapse/assets/index.css';
import Collapse, { Panel } from 'rc-collapse';
import {Link} from 'react-router-dom';
class Accordion extends React.Component {
  state = {
    accordion: true,
    activeKey: ['0'],
  }

  onChange = (activeKey) => {
    this.setState({
      activeKey,
    });
  }

  getItems() {
    const items = [];

    items.push(
      <Panel header={`Quels types d'affranchissements proposez-vous ?`} key="1">
      <p>
        <u>Pour la France métropolitaine et les DOM-TOM</u><br/><br/>
        - Ecopli (J+4)<br/>
        - Lettre recommandée simple (J+3)<br/>
        - Lettre recommandée avec accusé de réception (J+3)<br/>
        - Lettre Verte (J+3)<br/>
        - Lettre Verte Suivie (J+3)<br/>
        - Lettre Performance (J+2)<br/>
        - Lettre Performance Suivie (J+2)<br/>
        <br/>
        <u>Pour les destinations internationales</u><br/><br/>
        - Lettre recommandée internationale (avec suivi)<br/>
        - Lettre internationale (pas de suivi)<br/>
      </p>
      </Panel>
    );

    items.push(
      <Panel header={`Quelle est la différence entre une page et un feuillet ?`} key="2">
        <p>
          Un feuillet est constitué de <u>deux pages</u> (le côté recto et verso).<br/><br/>
          Par exemple, si votre document comporte deux pages :
          <li>&#8226; Si vous choisissez une impression <b>recto-verso</b>, votre courrier comportera un seul feuillet (imprimé des deux côtés)</li>
          <li>&#8226; Si vous choisissez une impression <b>recto</b>, votre courrier comportera deux feuillets (les côtés verso seront vierges)</li>
          <br/>
          Nous ne facturons pas à nos clients les feuilles de papier, <u>uniquement le nombre de pages à imprimer</u> (l'encre). Pour reprendre l'exemple ci-dessus,
          le prix de service sera identique, que l'impression soit recto ou recto-verso. Veuillez noter par contre qu'en fonction du nombre de feuilles présentes
          dans votre envoi, son poids pourra changer de tranche, ce qui aura une incidence sur le tarif d'affranchissement.<br/><br/>
          A noter : le nombre total de feuillets peut faire varier le poids total d'une enveloppe et donc influencer le coût de l'affranchissement; par conséquent,
          l'impression <b>recto-verso</b> est à favoriser si vous souhaitez limiter les coûts.
        </p>
      </Panel>
    );
    items.push(
      <Panel header={`Comment est calculé le prix d'un envoi ?`} key="3">
        <p>
          Le prix total d'un courrier est constitué de la somme des deux montants suivants :<br/>
          <li>&#8226; le prix de l'affranchissement (qui dépend du type de courrier que vous avez choisi et de son poids)</li>
          <li>&#8226; le prix du service (impression, apposition des bordereaux dans le cas des LR, LRAR, LRI, mise sous pli, dépôt en poste, etc.)</li>          
        </p>
        <p>Les réductions proposées dans nos abonnements s'appliquent sur la partie service, car nous ne faisons aucune marge sur l'affranchissement.</p>
      </Panel>
    );
    items.push(
      <Panel header={`Quels formats d'enveloppes proposez-vous ? Et combien de feuillets peut on insérer dans chaque format ?`} key="4">
      <p>
        Nous proposons les formats d'enveloppe suivants :<br/><br/>
        <li>&#8226; C6 : le courrier est plié en 3 et l'enveloppe peut contenir de 1 à 4 feuillets</li>
        <li>&#8226; C5 : le courrier est plié en 2 et l'enveloppe peut contenir de 1 à 8 feuillets</li>
        <li>&#8226; C4 : le courrier n'est pas plié et l'enveloppe peut contenir de 1 à 75 feuillets</li>
        <br/>
        Vous pouvez donc imprimer jusqu'à 150 pages recto-verso (c-à-d 75 feuillets)<br/>
      </p>
    </Panel>
    );
    items.push(
      <Panel header={`Quand mes courriers seront-ils remis à La Poste ?`} key="5">
        <p>
        <li>&#8226; Du lundi au vendredi : tous les courriers soumis avant 17h30 sont déposés le soir même à La Poste.</li>
        <li>&#8226; Le week-end : tous les courriers soumis après 17h30 le vendredi seront produits le lundi et déposés le lundi soir.</li>
        </p>
        <p>
          S'agissant des gros publipostages (plusieurs milliers de courriers), nous ferons partir les premiers lots de courriers le jour-même, mais en fonction des volumes il se peut que le reste
          du publipostage soit étalé le ou les jours suivants, en fonction des volumes de courriers.
        </p>
      </Panel>
    );
    items.push(
      <Panel header={`Faut-il payer un abonnement pour utiliser l'API Service Postal ?`} key="6">
        <p>
          Nous proposons trois formules d'abonnement; deux d'entre elles (<b>Entreprise Business</b> et <b>Entreprise Corporate</b>) sont payantes et donnent lieu à des réductions sur le prix de service, 
          quelle que soit la quantité de courriers commandée.
        </p>
        <p>L'abonnement <b>API Volume</b>, quant à lui, permet de découvrir notre API avec un <b><u>abonnement gratuit</u></b>, pour lequel des réductions s'appliquent par palliers en fonction de votre consommation mensuelle.</p>
        <p>
          Tous nos abonnements sont sans engagement, vous pouvez résilier à tout moment. Quel que soit votre choix, le processus est simple :<br/><br/>
          - Créez votre compte en vous <a href="/signup" title="inscription">inscrivant</a><br/>
          - Faites vos tests en toute autonomie sur notre sandbox<br/>
          - Choisissez la formule d'abonnement gratuit ou payant en remplissant les champs du <a href="/abonnement" title="formulaire dédié">formulaire dédié</a><br/>
          - Une fois vos coordonnées vérifiées et validées par nos équipes, vous pourrez commencer à envoyer vos courrier par notre API.<br/><br/>
          Toutes nos formules d'abonnement sont présentées sur notre page <a href="/tarifs" title="tarifs et formules d'abonnement">tarifs</a>.
        </p>
      </Panel>
      );
    items.push(
      <Panel header={`Comment calculer la formule d'abonnement la plus avantageuse, en fonction de ma consommation ?`} key="7">
        Pour déterminer l'abonnement le plus intéressant pour vous, il faut que vous puissiez estimer le nombre de courriers que vous comptez envoyer, et avec quelles options (affranchissement, nombre de pages, etc.).
        Afin de vous aider dans cette démarche, nous vous invitons à utiliser notre <a href="https://www.servicepostal.com/lettre-en-ligne/tarifs-calculer" title="inscription" target="_blank"  rel="noopener noreferrer"> calculatrice</a>
        &nbsp;Service Postal.<br/><br/>
        Sur cette page vous pouvez indiquer :<br/>
        - la formule d'abonnement (<b>Entreprise Business</b> ou <b>Entreprise Corporate</b>)<br/>
        - toutes les options courrier<br/><br/>
        La calculatrice vous indiquera le prix de ce courrier, une fois appliquées les réduction correspondant à l'abonnement. 
        Ainsi, vous pourrez estimer quelle formule favoriser pour dépasser le point mort (prix de l'abonnement couvert par le montant des 
        réductions obtenues) et obtenir les prix les plus avantageux en fonction de votre consommation.<br/><br/>
        L'abonnement <b>API Volume</b> n'est pas indiqué sur cette page; pour cet abonnement la règle est simple :<br/>
        - <b>5%</b> de réduction sur le prix de service si vous avez passé entre 100 et 1000 courriers dans le mois<br/>
        - <b>10%</b> de réduction sur le prix de service si vous avez passé plus de 1000 courriers dans le mois<br/>
        <br/><br/>
      </Panel>
    );
    items.push(
      <Panel header={`Comment régler mes factures ?`} key="8">
        <p>Le règlement de vos factures est effectué par prélèvement SEPA, à J+8 fin de mois, depuis le compte bancaire que vous aurez renseigné lors de l'activation de votre abonnement.</p>
        <p>Pour information, nous ne facturons aucun frais d'installation ou de support.</p>
      </Panel>
    );
    items.push(
      <Panel header={`Je compte intégrer l'envoi de courrier pour certains de mes clients. Comment puis-je refacturer chacun d'entre eux avec leur propre consommation ?`} key="9">
        <p>
          Rassurez-vous : l'API Service Postal intègre cette notion de facture analytique, inutile d'avoir besoin de gérer ces informations de votre côté.
          Vous avez le choix entre deux fonctionnements :
        </p>
        <p>
          - vous souhaitez disposer de sous-comptes, chacun ayant sa propre clef d'API. Dans ce cas vous pouvez soit opter pour notre abonnement <b>Entreprise Business</b> (2 comptes) 
          ou bien <b>Entreprise Corporate</b> (5 comptes). Si vous avez besoin de plus de sous-comptes n'hésitez pas à nous contacter pour un abonnement <u>sur mesure</u>.<br/><br/>
          - vous nous transmettez pour chaque appel de création de courrier <b>un nom d'entité</b>, voire <b>un nom d'entité et un nom de dossier</b>, et nous nous chargerons de "tagger" ces courriers
          avec les éléments transmis.<br/><br/>
          Ainsi, quelle que soit votre option retenue, vous recevrez sur votre facture mensuelle le détail analytique vous permettant de refacturer vos clients en toute sérénité.
        </p>
      </Panel>
    );
    items.push(
      <Panel header={`Y a t-il une fréquence d'appels à respecter pour ne pas surcharger l'API ?`} key="10">
        <p>Notre architecture actuelle est scalable et s'adaptera à toute montée en charge. Cependant, si vous souhaitez envoyer une quantité importante de courriers dont la forme est similaire,
          nous vous recommandons de passer par nos endpoints publipostage plutôt que de faire une boucle sur nos endpoints unitaires.</p>
      </Panel>
    );

    return items;
  }

render() {
    const accordion = this.state.accordion;
    const activeKey = this.state.activeKey;
    return (
    <div className="blocAccordion mx-800 m-auto">
      <div className="elt">
          <div className="center">
            <h2>Foire aux questions</h2>
            <p>Vous ne trouvez pas de réponse à vos questions ?&nbsp;
             <Link to="/contact">Contactez-nous.</Link>
            </p>
          </div> 
          <Collapse
            accordion={accordion}
            onChange={this.onChange}
            activeKey={activeKey}
          >
            {this.getItems()}
          </Collapse>
        </div>
      </div>
      );
  }
}

export default Accordion