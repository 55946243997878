import { AUTH,UN_AUTH} from './actionTypes';
import {API_URL} from '../config/config';
import Jwt from 'jsonwebtoken';
import axios from 'axios';

export function getUserConnected(obj,historyPush){
  return function(dispatch) {
    fetch(API_URL+'/login_check',
        {
          method:'POST',
          headers:{
            'Content-Type':'application/json'
          },
          body:JSON.stringify(obj)
        }
      ).then((resp) => {
        return resp.json();
      }).then((res) => {        
        dispatch({
            type: AUTH,
            res: res,
          });
          // console.log("code : ",res.code);
        if (res.code === 200) {
          localStorage.setItem('token', res.token);
          // localStorage.setItem('refresh_token', res.refresh_token);
          getAdminConnect(res.token,historyPush)
        } else {
          historyPush('/login');
        }
      });
      
  }
}

export function signoutUser() {
  document.cookie = 'User=;path=;domain=servicepostal.com;expires=Thu, 01 Jan 1970 00:00:01 GMT';
  localStorage.removeItem('token');
  return { type: UN_AUTH };
}

export function verifyJwt() {
  return function(dispatch) {
      fetch(`${API_URL}/api/verifyjwt`, 
        {
          method:'GET',
          headers: { 
            'Content-Type':'application/json',
            'Authorization': 'Bearer '+localStorage.getItem('token') 
        }
      }).then(resp => {
        return resp.json();
      }).then(res => {  
        //console.log('res', res)     
          dispatch({
              type: AUTH,
              res: res,
          });
      
      })
  }
}

const getAdminConnect = (token,historyPush) => {
  const decodedToken = Jwt.decode(token, {complete: true});
  const uri = decodedToken.payload.uri;
  if (uri) {
    axios.get(uri,{ withCredentials: true })
    .then((resp) => {
      historyPush('/tableau');
    }).catch(err => {
      // console.log(err)
      if (token) {
        historyPush('/tableau');
      }
      
    })
  }else{
    historyPush('/tableau');
  }
}
