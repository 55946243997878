import React from 'react';
import HeaderNoh1 from '../header/headerNoh1';
import Footer from '../footer/footer';
import Dashboard from './dashboard';
import '../../assets/css/header.css';
import Historik from '../utils/historik';

class Unitaire extends React.Component {
	render() {
		return (
			<div className="connected connectedPage">
				<HeaderNoh1 />
				<div className="content tabs">
					<div className="wrap">
						<Dashboard />
						<div className="bloc">
							{/*eslint-disable */}
							<div className="top">
								<h2 className="fl-l">Historique de mes envois unitaires</h2>
							</div>
							<Historik />
						</div>
					</div>
				</div>
				<Footer />
			</div>
		);
	}


}

export default Unitaire;
