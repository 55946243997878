import React  from 'react';
import { connect } from 'react-redux';
import HeaderNoh1 from '../header/headerNoh1';
import Footer from '../footer/footer';
import Dashboard from './dashboard';
import '../../assets/css/header.css';
import {subscritiptionFacture,clearSubscrFacture} from '../../actions/factureAction';

class TypeCompte extends React.Component {
	componentDidMount() {
		this.props.subscritiptionFacture();				
	}

	componentWillUnmount() {
		this.props.clearSubscrFacture();					
	}

  render() {
		let subscriptionType = this.props.subscriptionType;
	return(
			<div className="connected connectedPage">
	          	<HeaderNoh1/>
	          	<div className="content tabs">
	          		<div className="wrap">
	          			<Dashboard />
	    				<div className="bloc">
	    		        	{/*eslint-disable */}
							<div className="top">
                               <h2 className="fl-l">Bienvenue <strong>{this.props.initial}</strong></h2>
                               <p className="fl-r">ABONNEMENT {subscriptionType}</p>
							</div>
							<div className="blocArrondi margin">
                                <div className="elt typeCompte">
                                  <p className="title">TYPE DE COMPTE</p>
                                  <div className="childBloc">
                                     <span>1 utilisateur</span>
                                     <span>tarifs de base</span>
                                     <span>règlement comptant</span>
                                     <span>facture à chaque envoi</span>
                                  </div>
							    </div>
							    
							</div>
		        	</div>
		        </div>
			</div>
			<Footer/>
			</div>

      );
  }

}

function mapStateToProps(state, ownProps) {
	let a_status = state.login.connected;
	let _st = ''

	let subscriptionType = '';
	let sub = state.subinfo.sub_info;
	if (sub){
		subscriptionType = sub.subscription_type;
	}

	if (a_status) {
		if (a_status.code !== 200) {
			_st = a_status.message
		} else {
			_st = a_status.data.firstname ? a_status.data.firstname +' '+a_status.data.name : ''
		}
	}

	return {
		initial: _st,
		subscriptionType: subscriptionType,
	}
}

export default connect(mapStateToProps, {subscritiptionFacture,clearSubscrFacture})(TypeCompte);