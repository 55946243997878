import {ASK_CONTACT} from './actionTypes';
import {API_URL} from '../config/config';
import {reset} from 'redux-form';//call_form

export function contactSend(data){
  return function(dispatch) {

        fetch(API_URL+'/ask/contact',
        {
          method:'POST',
          headers:{
            'Content-Type':'application/json'
          },
          body:JSON.stringify(data)
        }
      ).then((resp) => {
        return resp.json();
      }).then((res) => {
        dispatch({
            type: ASK_CONTACT,
            res: res,
        });
        dispatch(reset('contact_form'))
        dispatch(reset('call_form'))
      });
    }
}


