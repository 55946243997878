
const RenderMonth = {
	list: function(el){
		/*eslint-disable */
		let monthSelect;

		if (el === 'Janv') {
			return monthSelect = 1;
		} else if (el === 'Fevrier') {
			return monthSelect = 2;
		} else if (el === 'Mars') {
			return monthSelect = 3;
		} else if (el === 'Avril') {
			return monthSelect = 4;
		} else if (el === 'Mai') {
			return monthSelect = 5;
		} else if (el === 'Juin') {
			return monthSelect = 6;
		} else if (el === 'Juillet') {
			return monthSelect = 7;
		} else if (el === 'Août') {
			return monthSelect = 8;
		} else if (el === 'Septembre') {
			return monthSelect = 9;
		} else if (el === 'Octobre') {
			return monthSelect = 10;
		} else if (el === 'Novembre') {
			return monthSelect = 11;
		} else if (el === 'Decembre') {
			return monthSelect = 12;
		}
	}	
}

export default RenderMonth;