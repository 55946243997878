import React  from 'react';

const Affrachissement = ({element}) => {
	if (element.type_courrier === 1) {
			return(
				<img alt="" border="0" width="40" src={require('../../../assets/images/lrarbck.png')}/>
			);
		} else if(element.type_courrier === 2) {
			return(
				<img alt="" border="0" width="40" src={require('../../../assets/images/icones/LR.png')} />
			);
		} else if(element.type_courrier === 3) {
			return(
				<img alt="" border="0" width="40" src={require('../../../assets/images/icones/prioritaire.png')}/>
			);
		} else if(element.type_courrier === 4) {
			return(
				<img alt="" border="0" width="40" src={require('../../../assets/images/icones/verte.png')}/>
			);
		} else if (element.type_courrier === 5) {
			return(
				<img alt="" border="0" width="40" src={require('../../../assets/images/icones/ecopli.png')}/>
			);
		}  else if(element.type_courrier === 6) {
			return(
				<img alt="" border="0" width="40" src={require('../../../assets/images/icones/suivi.png')}/>
			);
		}  else if(element.type_courrier === 7) {
			return(
				<img alt="" border="0" width="47" src={require('../../../assets/images/icones/lettre-performance.png')}/>
			);
		}  else if(element.type_courrier === 8) {
			return(
				<img alt="" border="0" width="47" src={require('../../../assets/images/icones/lettre-performance-suivi.png')}/>
			);
		}  else if(element.type_courrier === 9) {
			return(
				<img alt="" border="0" width="47" src={require('../../../assets/images/icones/verte-suivi.png')}/>
			);
		}
}

export default Affrachissement;

