import {
  ENVOIE_UNITAIRE,
  // ENVOIE_UNITAIRE_DATE, 
  ENVOIE_NOMBRES,
  GRAPH_DATA,
  CLEAR_DATA,
  // UPDATE_UNITAIRES,
  GET_FILES
} from './actionTypes';
import axios from 'axios';
import FileSaver from 'file-saver';
import {API_URL} from '../config/config';

const uri = `${API_URL}/api/get/file/send`;

export function fetchEnvoiesUnitaires() {
  return function(dispatch) {
    fetch(`${API_URL}/api/envoies/unitaires`, 
      {
        method:'POST',
        headers: { 
          'Content-Type':'application/json',
          'Authorization': 'Bearer '+localStorage.getItem('token') 
      },
    }).then(resp => {
      return resp.json();
    }).then(res => {      
        dispatch({
            type: ENVOIE_UNITAIRE,
            res: res,
        });
    })
  }
}

export function fetchByDate(obj) {
  return function(dispatch) {
    fetch(`${API_URL}/api/envoies/unitaires`, 
      {
        method:'POST',
        headers: { 
          'Content-Type':'application/json',
          'Authorization': 'Bearer '+localStorage.getItem('token') 
      },
      body:JSON.stringify(obj)
    }).then(resp => {
      return resp.json();
    }).then(res => {
      // console.log(res)      
        dispatch({
            type: ENVOIE_UNITAIRE, // ENVOIE_UNITAIRE_DATE,
            res: res,
        });
    })
  }
}

export function clearData(){
  return { type: CLEAR_DATA };
}

export function fetchEnvoiesNombres(obj) {
  return function(dispatch,getState) {
    fetch(`${API_URL}/api/envoies/nombres`, 
      {
        method:'POST',
        headers: { 
          'Content-Type':'application/json',
          'Authorization': 'Bearer '+localStorage.getItem('token') 
      },
      body:JSON.stringify(obj)
    }).then(resp => {
      return resp.json();
    }).then(res => {    
        dispatch({
            type: ENVOIE_NOMBRES,
            res: res,
        });
    })
  }
}

export function fetchPubByDate(obj){
  return function(dispatch) {
    //const state = getState().envoies.list_publipostage;
    fetch(`${API_URL}/api/envoies/nombres`, 
      {
        method:'POST',
        headers: { 
          'Content-Type':'application/json',
          'Authorization': 'Bearer '+localStorage.getItem('token') 
      },
      body:JSON.stringify(obj)
    }).then(resp => {
      return resp.json();
    }).then(res => {      
        dispatch({
            type: ENVOIE_NOMBRES,
            res: res,
        });
    })
  }
}

export function fetchDataGraph(obj) {
  return function(dispatch) {
    fetch(`${API_URL}/api/list/nombres/unitaires`, 
      {
        method:'POST',
        headers: { 
          'Content-Type':'application/json',
          'Authorization': 'Bearer '+localStorage.getItem('token') 
      },
      body:JSON.stringify(obj)
    }).then(resp => {
      return resp.json();
    }).then(res => {  
        dispatch({
            type: GRAPH_DATA,
            res: res,
        });
    })
  }
}

export function updateArchived(obj){
  return function(dispatch) {
    fetch(`${API_URL}/api/edit/unitaires`, 
      {
        method:'PUT',
        headers: { 
          'Content-Type':'application/json',
          'Authorization': 'Bearer '+localStorage.getItem('token') 
      },
      body:JSON.stringify(obj)
    }).then(resp => {
      return resp.json();
    }).then(res => {
      // console.log('res_init', res) 
        dispatch({
            type: ENVOIE_UNITAIRE, // UPDATE_UNITAIRES,
            res: res,
        });
    })
  }
}

export function fetchDocMain(id){
  return function(dispatch) {
      const headers = {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+localStorage.getItem('token')
      }
      let messagedata = '';
      axios.post(uri,
       id,
        {headers: headers})
      .then(function (response) {
        if (response.data.code === 200) {
          FileSaver.saveAs(response.data.data.content, response.data.data.name);
        }
        else {
          messagedata= 'Erreur lors de la récupération du fichier principal de la commande ' + id.id;
        }
        dispatch({
            type: GET_FILES,
            res: response.data,
            messagedata: messagedata
        });
    })
    .catch(function (error) {
      dispatch({
        type: GET_FILES,
        resp: error,
        messagedata: 'Erreur lors de la récupération du fichier principal de la commande ' + id.id
      });
    });

  }
}

export function fetchCsv(id){
  return function(dispatch) {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem('token')
      }
      let messagedata = '';
      axios.post(uri,
       id,
        {headers: headers})
      .then(function (response) {
        // console.log(response.data);
        if (response.data.code === 200) {
          FileSaver.saveAs(response.data.data.content, response.data.data.name);
        }
        else {
          messagedata= 'Erreur lors de la récupération du fichier csv de la commande ' + id.id;
        }
        dispatch({
            type: GET_FILES,
            res: response.data,
            messagedata: messagedata
        });
      })
      .catch(function (error) {
        dispatch({
          type: GET_FILES,
          resp: error,
          messagedata: 'Erreur lors de la récupération du fichier csv de la commande ' + id.id
        });
      });
    }
}

export function fecthAnnex(id){
  return function(dispatch) {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem('token')
      }
      let messagedata = '';
      axios.post(uri,
       id,
        {headers: headers})
      .then(function (response) {
        if (response.data.code === 200) {
          FileSaver.saveAs(response.data.data.content, response.data.data.name);
        }
        else {
          messagedata= 'Erreur lors de la récupération du fichier Annexe de la commande ' + id.id;
        }
        dispatch({
            type: GET_FILES,
            res: response.data,
            messagedata: messagedata
        });
      })
      .catch(function (error) {
        dispatch({
          type: GET_FILES,
          resp: error,
          messagedata: 'Erreur lors de la récupération du fichier Annexe de la commande ' + id.id
        });
      });
    }
}


