import React  from 'react';
import {Link} from 'react-router-dom';

import NavHeader from '../header/navHeader';
import '../../assets/css/header.css';
import Logo from '../../assets/images/logo.png';
import LogoM from '../../assets/images/logo_medium.png';
import LogoL from '../../assets/images/logo_large.png';
import LogoX from '../../assets/images/logo_xlarge.png';


// start part element fixed
const debounce = (func, wait) => {
  let timeout
  return (...args) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => func.apply(this, args), wait)
  }
}
// end part element fixed
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    	currentSrc: '',
    	addClass: false,
    	scrollPositionY: 0
    }
  }
// start part element load image
   onLoad = (event) => {
    this.setState({
      currentSrc: event.target.currentSrc
    });
  }
// end part element load image

    toggle() {
        this.setState({addClass: !this.state.addClass});
    }

  // start part element fixed
    componentDidMount() {
      return window.addEventListener('scroll', debounce(this.handleScroll, 32))
  }

  componentWillUnmount() {
    return window.removeEventListener('scroll', debounce(this.handleScroll, 32))
  }

  handleScroll = () => {
    const scrollPositionY = +window.scrollY
    return this.setState({ scrollPositionY })
  }


  // end part element fixed

	render() {

        const isscrolling = !!this.state.scrollPositionY  //fixed

    		let showMenu = ["fl-r"];
    		    if(this.state.addClass) {
    		      showMenu.push('show');
    		    }

		return (
				<div className ={(isscrolling) ? 'header isScrolling' : 'header'} >
        {/*eslint-disable */}
				    <div className="wrap">
                <div>
                  <h1>
                    <a href="/" className="Logo" title="Service Postal API">
                      <img alt="Service Postal API" border="0" src={Logo} srcSet={`${Logo} 300w, ${LogoM} 768w, ${LogoL} 1280w, ${LogoX} 3200w`} onLoad={this.onLoad} />
                        Service Postal
                        <span className="Logo2">API</span>
                    </a>
                  </h1>
                </div>
                <div id="mask" className={showMenu.join(' ')} onClick={this.toggle.bind(this)}></div>
				        <span id="togMenu" onClick={this.toggle.bind(this)} className={showMenu.join(' ')} href="#" title=""></span>
				        <div id="menu" className={showMenu.join(' ')}>
				            <NavHeader/>
					        <div className="btns">
					             <span>
					                   <Link to="/signup" className="bt btBlue inscription" title="Inscription">Inscription</Link>
					             </span>
					             <Link to="/login" className="bt login connexion" title="Connexion">Connexion</Link>
					        </div>
				        </div>
				    </div>
			    </div>
		)
	}
}


export default Header;
