import React,{Component} from 'react';
import {connect} from 'react-redux';
import {reduxForm,Field} from 'redux-form';
import HeaderNoh1 from '../header/headerNoh1';
import Footer from '../footer/footer';
import {requestPassword} from '../../actions/userAction';
import Loader from '../utils/loader';
import Constante from '../../constante/constante';

class Mdpoublie extends Component {
	constructor() {
      super();
      this.state = {
      	isLoader: false,
    	}
    }

    componentDidUpdate(prevProps) {
		if (this.props.res !== prevProps.res) {
			this.setState({isLoader: false})
		}
	}

	handleFormSubmit({email}){
    	const obj = {
		    'email': email
		  };

    	this.props.requestPassword(obj)
    	this.setState({isLoader: true})
    };

	renderInput = (field) => (
	    <div className="form-group">
	    <label htmlFor={field.label}>{field.label}</label>
	      <input
	        className="form-control"
	        {...field.input}
	        type={field.type}
	        placeholder={field.placeholder}
	        required={field.required? 'required' : ''}
	        disabled={field.disabled? 'disabled' : ''}
	      />
	    </div>
    );

	render () {
		const { handleSubmit} = this.props
		return(
			<div>
				<HeaderNoh1 />
				{/*eslint-disable */}
				<div className="content">
				    <div className="wrap connexion formulaire">
					    <div className="bloc noborder">
								<h2 className="text-center">Réinitialisation mot de passe</h2>
									{this.props.status ? (
									<div className={this.props.cl} role="alert">
	  									{this.props.status}
									</div>								
									):(
										<div></div>
									)}
								<form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
									<Field name="email" label="Email" component={this.renderInput} type="text" placeholder="Email" required={true} />
								    <div className="text-center bottom">
									    <button className="bt btBlue">
									    	Réinitialiser mon mot de passe
									    </button>
								    </div>
								</form>
								<Loader isLoader={this.state.isLoader} />					     					    	
				        </div>
					</div>
			    </div>
			    <Footer/>
		    </div>
	  	)
	}
}

Mdpoublie = reduxForm({
	form: 'email_form'
})(Mdpoublie)

function mapStateToProps(state) {
	let a_status = state.user.forgoted;

	let _st = ''
	let message = ''
	let status = ''

	if (a_status) {
		_st =  a_status.success ? Constante.messageSucc: Constante.messageErr
		message = a_status.message
		status = a_status.success ? Constante.classSuccess : Constante.classError
	}

	return {
		status: _st,
		res: message,
		cl: status
	}
}

export default connect(mapStateToProps,{requestPassword})(Mdpoublie);