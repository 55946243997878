import React  from 'react';

const Affiche = ({element}) => {
	let recto = '';
	let type = '';
	let color = '';
	let pli = '';
	let env = '';

	if (element.envelop < 3) {
			type = 'Fenêtre';
		} else {
			type = 'imprimée';
		}

		if (element.recto_verso === 1) {
			recto = 'Recto-Verso';
		} else {
			recto = 'Recto';
		}

		if (element.options_impression === 1) {
			color = 'Couleur';
		} else {
			color = 'N&B';
		}

		if (element.envelop === 0 || element.envelop === 6) {
			pli = 'plié en trois';
			env = 'C6'
		} else if (element.envelop === 1 || element.envelop === 5) {
			pli = 'plié en deux';
			env = 'C5'
		}  else if (element.envelop === 2 || element.envelop === 7) { 
			pli = '';
			env = 'C4';
	}

	return(
		<div>Enveloppe {type} {env} - A4 {pli} - {color} - {recto}</div>
	);
}

export default Affiche;
